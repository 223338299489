import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Login from './pages/login/Login';
import ProjectsPage from './pages/projects';
import Upload from './pages/upload';
import SharedProjectsPage from './pages/shared';
import Configure from './pages/configure';
import AddComparisons from './pages/compare';
import { AuthProvider, useAuth } from './auth/AuthContext';
import Account from './pages/account';
import LogsPage from './pages/ProgressLog';
import { ProfileProvider } from './contexts/ProfileContext';
import { ClipLoader } from 'react-spinners';
import Report from './pages/report';
import ContactUs from './pages/contact';
import Booking from './pages/Book_Session';
import { ProjectRefreshProvider } from './contexts/ProjectRefreshContext'; // Import ProjectRefreshProvider
import { EnvProvider } from './utils/EnvContext';
import ViewAnalysisData from './pages/ViewAnalysis';
import Vnex2 from './pages/vnex2';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ProfileProvider>
        <ProjectRefreshProvider>
          {/* Wrap your routes with ProjectRefreshProvider */}
          <QueryClientProvider client={queryClient}>
            <Router>
              <EnvProvider>
                <Routes>
                  <Route path="/login" element={<LoginRedirect />} />
                  <Route element={<AuthenticatedRoutes />}>
                    <Route element={<Layout />}>
                      <Route path="/" element={<ProjectsPage />} />
                      <Route path="/upload" element={<Upload />} />
                      <Route
                        path="/ProgressLog/:projectname"
                        element={<LogsPage />}
                      />
                      <Route
                        path="/configure/:projectname"
                        element={<Configure />}
                      />
                      <Route
                        path="/compare/:projectname"
                        element={<AddComparisons />}
                      />
                      <Route path="/shared" element={<SharedProjectsPage />} />
                      <Route path="/account" element={<Account />} />
                      <Route path="/report" element={<Report />} />
                      <Route path="/contact" element={<ContactUs />} />
                      <Route path="/book-session" element={<Booking />} />
                      <Route
                        path="/view-analysis/:projectname"
                        element={<ViewAnalysisData />}
                      />
                      <Route path="/vnex2" element={<Vnex2 />} />
                    </Route>
                  </Route>
                </Routes>
              </EnvProvider>
            </Router>
          </QueryClientProvider>
        </ProjectRefreshProvider>
      </ProfileProvider>
    </AuthProvider>
  );
};

// Component to handle authenticated routes
const AuthenticatedRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated === undefined) {
    return (
      <div className="flex justify-center h-screen absolute w-full top-1/2">
        <ClipLoader size={40} color={'#217867'} />
      </div>
    );
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

// Component to handle login page redirection
const LoginRedirect: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectTo = location.state?.from?.pathname || '/';
      navigate(redirectTo, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  if (isAuthenticated === undefined) {
    return <div>Loading...</div>; // or some loading indicator
  }

  return <Login />;
};

export default App;
