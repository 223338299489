import Swal from 'sweetalert2';
import { resumeSftpDownload } from '../../services/projectApi';
import { FtpDetails } from '../../types/upload';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface ResumeDownloadProps {
  projectName: string;
  ftpDetails: FtpDetails[];
  resumeFilesList: string[];
  setShowResumeButton: (showResumeButton: boolean) => void;
  UploadFtpDetails: (ftpDetails: FtpDetails[]) => void;
  ResumeUploadPromise: (updatedFtpDetails: FtpDetails[]) => void;
}

const ResumeDownload: React.FC<ResumeDownloadProps> = ({
  projectName,
  ftpDetails,
  resumeFilesList,
  setShowResumeButton,
  UploadFtpDetails,
  ResumeUploadPromise,
}) => {
  let resumeDownload = false;

  const ResumeDownloadFunc = async () => {
    let allResumedSuccessfull = true;
    await Promise.all(
      resumeFilesList.map((file) =>
        resumeSftpDownload(file)
          .then((response) => {
            const { message } = response.data;
            setShowResumeButton(false);
            resumeDownload = false;
          })
          .catch((error) => {
            allResumedSuccessfull = false;
            console.error('Error resuming download:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error occurred during the download resumption process',
              width: '500px',
              padding: '1.5em',
            });
          }),
      ),
    );

    if (allResumedSuccessfull) {
      await Swal.fire({
        icon: 'info',
        title: 'Download Resumed',
        width: '500px',
        padding: '1.5em',
        timer: 3000,
        timerProgressBar: true,
      });
      if (ftpDetails.length > 0) {
        //logic to update the failed files in the UI
        const updatedFtpDetails = ftpDetails.filter((file) => {
          if (
            resumeFilesList?.includes(
              `${projectName}_${file.hostname}_${file.file_name}`,
            )
          ) {
            return true;
          }
          return false;
        });

        ResumeUploadPromise(updatedFtpDetails);
      }
    }
  };
  return (
    <button
      type="button"
      className="w-full px-4 py-2 mt-4 text-white bg-black rounded flex items-center justify-center"
      onClick={() => ResumeDownloadFunc()}
    >
      <PlayCircleOutlineIcon className="mr-2" />
      Resume Download
    </button>
  );
};

export default ResumeDownload;
