import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import Logo from '../../assets/onex_logo_dark.png';
import { useAuth } from '../../auth/AuthContext';
import { useProfile } from '../../contexts/ProfileContext';
import { ClipLoader } from 'react-spinners';
import { GetUserSubscription } from '../../services/subscriptionApi';
import Swal from 'sweetalert2';
import {
  FiCalendar,
  FiPhoneCall,
  FiLogOut,
  FiChevronDown,
  FiInfo,
} from 'react-icons/fi';
import { MdBugReport } from 'react-icons/md';
import { useEnvironment } from '../../utils/EnvContext';

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
}

const Layout: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [subscription, setSubscription] = useState<UserSubscription | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true); // State to manage loading state
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { profile } = useProfile();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { environmentName } = useEnvironment();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await GetUserSubscription();
        setSubscription(response.data); // Assuming the API response structure
        console.log('subscription', subscription);
      } catch (err) {
        console.error('Error fetching subscription:', err);
      } finally {
        setIsLoading(false); // Set loading to false once the subscription is fetched
      }
    };

    environmentName && environmentName !== 'local'
      ? fetchSubscription()
      : setIsLoading(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleSharedLinkClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (isLoading) {
      event.preventDefault(); // Prevent navigation while loading
      return;
    }

    if (
      environmentName &&
      environmentName !== 'local' &&
      (!subscription || subscription.item_price_name === 'Free')
    ) {
      event.preventDefault();
      Swal.fire({
        title: 'Upgrade Required',
        text: 'You need to have a subscription to access shared projects. Would you like to upgrade?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upgrade',
        cancelButtonText: 'No, cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
    } else {
      navigate('/shared');
    }
  };

  const getDisplayName = () => {
    if (profile) {
      const { first_name, last_name, email } = profile;
      if (first_name && last_name) {
        return `${first_name} ${last_name}`;
      } else if (first_name) {
        return first_name;
      } else if (last_name) {
        return last_name;
      } else {
        return email;
      }
    }
    return '';
  };

  return (
        <div 
      className="flex flex-col min-h-screen" 
      style={{
        background: 'linear-gradient(135deg, #39F2AE 0%, #6E04FE 100%)'
      }}
    >
      <header className="bg-onex-black-100 border-b border-onex-black-80">
        <div className="container flex items-center justify-between px-4 py-2 mx-auto">
          <div className="flex items-center">
            <img src={Logo} alt="OneX Logo" className="h-12" />
          </div>
          <div className="flex items-center py-4 space-x-8">
            <Link
              to="/"
              className={`hover:text-onex-turquoise-100 ${window.location.pathname === '/'
                  ? 'text-onex-purple-50 font-semibold'
                  : 'text-onex-white-100'
                }`}
            >
              My Projects
            </Link>            <Link
              to="/shared"
              className={`hover:text-onex-turquoise-100 ${window.location.pathname === '/shared' ? 'text-onex-purple-50 font-semibold' : 'text-onex-white-100'}`}
              onClick={handleSharedLinkClick}
              style={{ cursor: isLoading ? 'wait' : 'pointer' }} // Set cursor style based on loading state
            >
              Shared with me
            </Link>
            <Link
              to="/account"
              className={`hover:text-onex-turquoise-100 ${window.location.pathname === '/account' ? 'text-onex-purple-50 font-semibold' : 'text-onex-white-100 '}`}
            >
              Account Settings
            </Link>
          </div>
          <div className="relative flex items-center space-x-4">
            <div className="relative" ref={dropdownRef}>
              {profile ? (
                <>
                  <img
                  src={profile.pfp}
                  alt="Profile"
                  className="w-10 h-10 rounded-full cursor-pointer outline-none hover:outline hover:outline-2 hover:outline-onex-turquoise-100"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  />
                  <div
                  className={`absolute  -left-3 -mt-1.5 z-10 bg-white rounded-lg shadow-md w-44 transition-transform transform ${dropdownOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'} duration-300 ease-out`}
                  style={{ top: '60px' }} // Adjust 'top' to control vertical positioning
                  >
                  {dropdownOpen && (
                    <ul className="py-2">
                    <li className="px-4 py-2 font-semibold text-gray-800 border-b border-gray-300 hover:bg-gray-100">
                      <button onClick={() => navigate('/account')}>
                      My Account
                      </button>
                    </li>
                    <li className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100">
                      <MdBugReport className="mr-2" />
                      <button onClick={() => navigate('/report')}>
                      Report Bug
                      </button>
                    </li>
                    <li className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100">
                      <FiPhoneCall className="mr-2" />
                      <button onClick={() => navigate('/contact')}>
                      Contact Us
                      </button>
                    </li>
                    <li className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100">
                      <FiCalendar className="mr-2" />
                      <button onClick={() => navigate('/book-session')}>
                      Book a Session
                      </button>
                    </li>

                    <li className="flex items-center px-4 py-2 text-gray-800 border-t border-gray-300 hover:bg-gray-100">
                      <FiLogOut className="mr-2" />
                      <button onClick={handleLogout}>Logout</button>
                    </li>
                    </ul>
                  )}
                  </div>
                </>
              ) : (
                <ClipLoader size={40} color={'#217867'} loading={!profile} />
              )}
            </div>
            <p
              className="ml-4 text-onex-white-100 cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {getDisplayName()}
            </p>
            <FiChevronDown
              className="ml-2 text-2xl cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
          </div>
        </div>
      </header>
      <main className="container flex-grow py-4 mx-auto">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
