import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faHdd,
  faTachometerAlt,
  faFileAlt,
  faEuroSign,
  faDollarSign,
  faPoundSign,
  faYenSign,
} from '@fortawesome/free-solid-svg-icons';
import { BuySubscription, CancelSubscription } from '../../../services/subscriptionApi';
import Swal from 'sweetalert2';
import Details from './Details';
import pricingPlanDetails from './DetailsData';

interface Subscription {
  id: string;
  name: string;
  price: string;
  currency_code: string;
  features: { [key: string]: string };
}

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
}

interface PricingProps {
  subscriptions: Subscription[];
  userSubscription: UserSubscription | null;
  isFreeTrial: boolean;
}

const Pricing: React.FC<PricingProps> = ({ subscriptions, userSubscription, isFreeTrial }) => {
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null); // State for tracking which plan is loading
  const [hovered, setHovered] = useState<string | null>(null); // State to track hovered button
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getIcon = (feature: string) => {
    if (feature.includes('Turnover')) {
      return faClock;
    } else if (feature.includes('data') || feature.includes('GB')) {
      return faHdd;
    } else if (feature.includes('Computing') || feature.includes('Speed')) {
      return faTachometerAlt;
    } else {
      return faFileAlt;
    }
  };

  const getCurrencyIcon = (currency_code: string) => {
    switch (currency_code) {
      case 'CHF':
      case 'EUR':
        return faEuroSign;
      case 'USD':
        return faDollarSign;
      case 'GBP':
        return faPoundSign;
      case 'JPY':
        return faYenSign;
      default:
        return faDollarSign; // default icon if currency is not matched
    }
  };

  const getTitleColor = (name: string) => {
    switch (name) {
      case 'Free':
        return 'text-gray-400';
      case 'Pro':
        return 'text-gray-700';
      case 'Pro+':
        return 'text-teal-500';
      case 'Premium':
        return 'text-orange-500';
      default:
        return 'text-gray-700';
    }
  };

  const handleSubscriptionChange = async (planId: string) => {
    setLoadingPlanId(planId); // Set the clicked plan ID to the loading state
    try {
      const response = await BuySubscription(planId);
      window.location.href = response.data; // Redirect to the hosted page URL
    } catch (error) {
      setLoadingPlanId(null); // Reset loading state if there's an error
      console.error('Error purchasing subscription:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an issue processing your subscription. Please try again later.',
      });
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "Do you really want to cancel your current subscription? This action cannot be undone.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No, keep it',
      });

      if (result.isConfirmed) {
        setLoadingPlanId('cancel'); // Set loading state for cancellation
        await CancelSubscription();
        window.location.reload(); // Reload the page to reflect the subscription status
      }
    } catch (error) {
      setLoadingPlanId(null); // Reset loading state if there's an error
      console.error('Error cancelling subscription:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an issue cancelling your subscription. Please try again later.',
      });
    }
  };

  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">Subscription Plan</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
      {subscriptions.map((plan) => {
          const isCurrentPlan = (userSubscription && userSubscription.item_price_name === plan.name) || (!userSubscription && plan.name === 'Free');
          const showTrialButton = isFreeTrial && plan.name === 'Pro'; 
          const featuresOrder = ["Monthly projects", "Monthly data", "Computing speed", "Output", "Turnover time"];
          const isPlanLoading = loadingPlanId === plan.id; // Check if this plan is the one being loaded
          return (
            <div key={plan.id} className="flex flex-col p-8 border border-gray-300 rounded-lg min-h-[380px]">
              <div className="flex-grow">
                <h3 className={`mb-4 text-xl font-semibold ${getTitleColor(plan.name)}`}>{plan.name}</h3>
                <p className="mb-4 text-4xl font-bold text-gray-700">
                  <FontAwesomeIcon icon={getCurrencyIcon(plan.currency_code)} className="mr-2" />
                  {plan.price}{' '}
                  <span className="text-lg font-normal text-gray-500">/ month</span>
                </p>
                <ul className="mb-6 space-y-4 text-lg font-normal text-gray-700">
                  {featuresOrder.map((feature, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={getIcon(feature)} className="mr-2" /> {feature}: <span className="font-semibold">{plan.features[feature]}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <button 
                  className="w-full px-6 py-3 mb-3 font-semibold text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
                  onClick={() => setIsModalOpen(true)}
                >
                  View Details
                </button>
                <button
                  className={`w-full px-6 py-3 font-semibold ${isCurrentPlan ? 'text-white bg-teal-700 hover:bg-teal-800 rounded' : 'text-teal-700 bg-teal-700 rounded bg-opacity-10 hover:bg-opacity-20'}`}
                  onMouseEnter={() => isCurrentPlan && setHovered(plan.id)}
                  onMouseLeave={() => setHovered(null)}
                  onClick={() => isCurrentPlan ? handleCancelSubscription() : handleSubscriptionChange(plan.id)}
                  disabled={isPlanLoading}
                >
                  {isPlanLoading
                    ? 'Loading...'
                    : isCurrentPlan && hovered === plan.id
                    ? 'Cancel Current Plan?'
                    : isCurrentPlan
                    ? 'Current Plan'
                    : showTrialButton
                    ? 'Try 1 Month Free' // Show trial button when eligible
                    : `Switch to ${plan.name}`}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Details
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        services={pricingPlanDetails.services}
      />
    </div>
  );
};

export default Pricing;
