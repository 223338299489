import React, { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2';

interface FtpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (ftpDetails: {
    hostname: string;
    sftp_username: string;
    sftp_password: string;
    sftp_remote_path: string;
  }) => void;
  hostname: string; // Add hostname as a prop
}

const FtpModal: React.FC<FtpModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  hostname,
}) => {
  const [sftp_username, setSftpUsername] = useState<string>('');
  const [sftp_password, setSftpPassword] = useState<string>('');
  const [sftp_remote_path, setSftpRemotePath] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      const usernameInput = document.getElementById('sftp-username');
      if (usernameInput) {
        usernameInput.focus(); // Explicitly focus the username input field when the modal opens
      }
    }

    if (!isOpen) {
      // Reset state when modal is closed
      setSftpUsername('');
      setSftpPassword('');
      setSftpRemotePath('');
    }
  }, [isOpen]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission on Enter key
    }
  };

  const handleSubmit = () => {
    if (hostname && sftp_username && sftp_password && sftp_remote_path) {
      onSubmit({ hostname, sftp_username, sftp_password, sftp_remote_path });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Information',
        text: 'Please fill out all the fields before submitting.',
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">FTP Details</h2>
          <ClearIcon className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Hostname</label>
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-md"
            value={hostname}
            readOnly // Make this field read-only
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Username</label>
          <input
            id="sftp-username" // Add id for explicit focusing
            type="text"
            className="w-full px-4 py-2 border rounded-md"
            value={sftp_username}
            onChange={(e) => setSftpUsername(e.target.value)}
            placeholder="Enter Username"
            onKeyPress={handleKeyPress} // Prevent default form submission
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-700">Password</label>
          <input
            type="password"
            className="w-full px-4 py-2 border rounded-md"
            value={sftp_password}
            onChange={(e) => setSftpPassword(e.target.value)}
            placeholder="Enter Password"
            onKeyPress={handleKeyPress} // Prevent default form submission
            autoComplete="off" // Disable password autofill
            name="ftp-password" // Add a unique name to prevent autofill issues
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-700">Remote Directory</label>
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-md"
            value={sftp_remote_path}
            onChange={(e) => setSftpRemotePath(e.target.value)}
            placeholder="Enter Remote Directory"
            onKeyPress={handleKeyPress} // Prevent default form submission
          />
        </div>
        <p className="mb-4 text-sm text-gray-500">
          Ensure your FTP details are correct before submitting.
        </p>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 font-semibold text-white bg-teal-600 rounded-md hover:bg-teal-700"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FtpModal;
