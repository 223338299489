import React from 'react';

interface ConfigureTutorialModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfigureTutorialModal: React.FC<ConfigureTutorialModalProps> = ({ isModalOpen, setIsModalOpen }) => {

  const handleSkipTutorial = () => {
    localStorage.setItem('showTutorial', 'false');
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative w-11/12 p-8 bg-white rounded-lg shadow-lg h-[65%] md:w-5/6 lg:w-7/12 max-h-[80vh] overflow-y-auto">
            <button
              className="absolute text-2xl text-gray-700 top-3 right-5"
              onClick={() => setIsModalOpen(false)}
            >
              &#10005;
            </button>
            <h2 className="mb-6 text-3xl font-bold text-left text-gray-700">Configure Project</h2>
            <div className="text-gray-700">
              <p>There are the 3 steps to configure your project:</p><br />
              <ol className="mb-4 text-left list-decimal list-inside">
                <li>Name your samples: Fill in the column "Sample name" in the table.</li><br />
                <li>Define your groups of interest: Click on your samples using the column "File names". Enter a "group name" and the color you'd like to use for the group. Click on the "Create group" button. When all your groups are created, you can save them by clicking on "Validate groups".</li><br />
                <li>Define comparison(s) between groups of interest: Select which groups you want to compare, the first group being the interest (or intervention) and the second group being the reference (control).</li><br />
              </ol>
            </div>
            <button
              className="absolute px-4 py-3 font-semibold text-white bg-onex-purple-80 rounded bottom-6 right-6"
              onClick={handleSkipTutorial}
            >
              Done, Don't Show Again
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigureTutorialModal;
