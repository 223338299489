import React, { useState, useEffect } from 'react';
import SharedProjectTable from './SharedProjectTable';
import { Project } from '../../interface/project';
import Select from 'react-select';
import { getDateRange, customStyles } from '../../utils/projects';
import { GetShareProjectapi } from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import {
  GetLocalSubscription,
  GetUserSubscription,
} from '../../services/subscriptionApi';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useEnvironment } from '../../utils/EnvContext';
import NotAuthorized from '../../components/NotAuthorized';
import withAuthorization from '../../components/WithAuthorization';

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
}

const SharedProjectsPage: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [dateFilter, setDateFilter] = useState<string | null>(null);
  const [experimentFilter, setExperimentFilter] = useState<string | null>(null);
  const [keyword, setKeyword] = useState('');
  const [sharedProjects, setSharedProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<UserSubscription | null>(
    null,
  );
  const [hasValidSubscription, setHasValidSubscription] =
    useState<boolean>(false);
  const { environmentName } = useEnvironment();
  const [isLocalLisenceValid, setIsLocalLisenceValid] = useState<boolean>();

  const navigate = useNavigate();

  useEffect(() => {
    if (environmentName) {
      const fetchSubscription = async () => {
        try {
          const response =
            environmentName === 'local'
              ? await GetLocalSubscription()
              : await GetUserSubscription();

          if (environmentName === 'local') {
            setHasValidSubscription(true);

            response.data.message == 'License is valid.'
              ? setIsLocalLisenceValid(true)
              : setIsLocalLisenceValid(false);
          } else {
            setSubscription(response.data);
            if (response.data && response.data.item_price_name !== 'Free') {
              setHasValidSubscription(true);
            } else {
              Swal.fire({
                title: 'Upgrade Required',
                text: 'You need to have a subscription to access shared projects. Would you like to upgrade?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, upgrade',
                cancelButtonText: 'No, cancel',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/account');
                } else {
                  navigate('/'); // Redirect to home or another appropriate page
                }
              });
            }
          }
        } catch (err) {
          console.error('Error fetching subscription:', err);
          if (environmentName === 'local') {
            setIsLocalLisenceValid(false);
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Unable to verify subscription. Please try again later.',
              icon: 'error',
            }).then(() => {
              navigate('/'); // Redirect to home or another appropriate page
            });
          }
          setIsLoading(false);
        }
      };

      fetchSubscription();
    }
  }, [navigate, environmentName]);

  useEffect(() => {
    if (hasValidSubscription) {
      const fetchProjects = async () => {
        try {
          const projectsData = await GetShareProjectapi();
          setSharedProjects(
            Array.isArray(projectsData.data) ? projectsData.data : [],
          );
        } catch (error) {
          console.error('Error fetching shared projects:', error);
          setSharedProjects([]);
        } finally {
          setIsLoading(false);
        }
      };

      fetchProjects();
    }
  }, [hasValidSubscription]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-64">
        <ClipLoader size={50} color={'#123abc'} loading={isLoading} />
      </div>
    );
  }

  const { startDate, endDate } = getDateRange(dateFilter || '');

  const filteredProjects = sharedProjects.filter((project) => {
    const projectDate = new Date(project.dateAdded);
    return (
      (statusFilter === null || project.status === statusFilter) &&
      (dateFilter === null ||
        (projectDate >= startDate && projectDate <= endDate)) &&
      (experimentFilter === null || project.type === experimentFilter) &&
      (keyword === '' ||
        project.name.toLowerCase().includes(keyword.toLowerCase()))
    );
  });

  const statusOptions = [
    { value: 'Ready', label: 'View' },
    { value: 'Configured', label: 'In Progress' },
    { value: 'Uploaded', label: 'Configure' },
    { value: 'Not Uploaded', label: 'Uploading' },
  ];

  const dateOptions = [
    { value: 'Today', label: 'Today' },
    { value: 'Past 7 Days', label: 'Past 7 Days' },
    { value: 'Past Month', label: 'Past Month' },
    { value: 'Past Year', label: 'Past Year' },
  ];

  const experimentOptions = [
    { value: 'RNAseq', label: 'RNAseq' },
    { value: 'DNAseq', label: 'DNAseq' },
  ];

  return (
    <div className="container mx-auto">
      <div className="p-4 bg-white rounded-lg shadow-lg shadow-gray-500/50">
        <div className="flex items-center justify-between mb-6">
          <h3 className="ml-3 text-xl font-semibold">Shared Projects</h3>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center ml-2 space-x-4">
            <input
              type="text"
              placeholder="Type Keyword here"
              className="px-5 py-3 text-gray-600 border border-gray-300 rounded-md"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className="flex space-x-4">
            <div className="w-46">
              <Select
                options={statusOptions}
                value={statusOptions.find(
                  (option) => option.value === statusFilter,
                )}
                onChange={(selectedOption) =>
                  setStatusFilter(selectedOption ? selectedOption.value : null)
                }
                placeholder="Status"
                isClearable
                styles={customStyles}
              />
            </div>
            <div className="w-46">
              <Select
                options={dateOptions}
                value={dateOptions.find(
                  (option) => option.value === dateFilter,
                )}
                onChange={(selectedOption) =>
                  setDateFilter(selectedOption ? selectedOption.value : null)
                }
                placeholder="Date Added"
                isClearable
                styles={customStyles}
              />
            </div>
            <div className="w-46">
              <Select
                options={experimentOptions}
                value={experimentOptions.find(
                  (option) => option.value === experimentFilter,
                )}
                onChange={(selectedOption) =>
                  setExperimentFilter(
                    selectedOption ? selectedOption.value : null,
                  )
                }
                placeholder="Experiment"
                isClearable
                styles={customStyles}
              />
            </div>
          </div>
        </div>
        {filteredProjects.length > 0 ? (
          <SharedProjectTable projects={filteredProjects} />
        ) : (
          <p>No projects have been shared with you</p>
        )}
      </div>
    </div>
  );
};

export default withAuthorization(SharedProjectsPage);
