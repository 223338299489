import React from 'react';
import { startPasswordlessLogin } from '../../services/loginApi';

interface EmailFormProps {
  email: string;
  loading: boolean;
  setEmail: (email: string) => void;
  setLoading: (loading: boolean) => void;
  handleEmailSubmit: () => void;
  setMessage: (message: string | null) => void;
  setMessageType: (type: 'error' | 'success' | null) => void;
  error?: {
    response: {
      data: {
        detail: string;
      };
    };
  };
}

const EmailForm: React.FC<EmailFormProps> = ({
  email,
  loading,
  setEmail,
  setLoading,
  handleEmailSubmit,
  setMessage,
  setMessageType,
}) => {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await startPasswordlessLogin(email);
      setLoading(false);
      setMessage('OTP sent to your email.');
      setMessageType('success');
      handleEmailSubmit();
    } catch (error: any) { // Ensure error is typed correctly
      setLoading(false);
      if (error.response && error.response.data && error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else {
        setMessage('An unexpected error occurred.');
      }
      setMessageType('error');
    }
  };
  return (
    <div>
      <div className="flex flex-col items-center mt-6">
        <h2 className="text-3xl font-bold text-onex-white-100">
          Welcome to ONex
        </h2>
        <p className="mt-2 text-lg text-neutral-400">
          Your -omics analysis platform
        </p>
      </div>
      <form className="mt-8" onSubmit={handleSubmit}>
        <div className="relative w-full mt-4">
          <input
            type="email"
            id="email"
            className="w-full px-5 py-3 my-2 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:onex-turquoise-100 peer"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder=" "
          />
          <label
            htmlFor="email"
            className="absolute text-lg text-gray-500 transition-all origin-top-left transform scale-75 -translate-y-8 left-3 top-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 peer-focus:text-onex-turquoise-80"
          >
            Enter your email to continue
          </label>
        </div>
        <button
          type="submit"
          className="relative w-full px-5 py-3 mt-4 text-lg text-white bg-onex-purple-100 g-teal-800 focus:outline-none focus:bg-teal-800"
          disabled={loading}
        >
          {loading ? (
            <svg
              className="w-5 h-5 mx-auto text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          ) : (
            'Send OTP'
          )}
        </button>
      </form>
    </div>
  );
};

export default EmailForm;
