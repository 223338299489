import { Checkbox, Select, Divider, Typography, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line
import Plot from 'react-plotly.js';
import { useProfile } from '../../contexts/ProfileContext';
import { Fullscreen } from 'lucide-react';
import {
  Clustring,
  ProjectGroups,
  SharedWith,
} from '../../services/vnexPlotApi';
import { toast, ToastContainer } from 'react-toastify';
import Plotly, { downloadImage } from 'plotly.js-basic-dist';
import { BarLoader, ClipLoader } from 'react-spinners';
const { Option } = Select;
const { Title } = Typography;
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal';
import {
  DownloadImage,
  DownloadImage3D,
  DownloadBuiltImage,
} from '../../utils/download';
import { commonConfig } from '../../utils/plotConfig';
import { useQuery } from '@tanstack/react-query';
import { ClusteringStaticData } from '../../data/CONSTANT';

interface ClusteringProps {
  projectName: string;
}

export interface IfilterData {
  plot: string;
  dimensions: string;
  num_genes: string;
  row_metric: string;
  col_metric: string;
  selectedGroups: string[];
}

const Clustering: React.FC<ClusteringProps> = ({ projectName }) => {
  const [groups, setGroups] = useState<string[]>([]);
  const { profile } = useProfile();
  // const email = 'm.ahmadsultan@gmail.com';
  const plotRef = useRef<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialFilterData = {
    plot: 'PCA',
    dimensions: '3',
    num_genes: '250',
    row_metric: 'correlation',
    col_metric: 'euclidean',
    selectedGroups: groups,
  };
  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith'],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? ''; // Ensure email is always a string
  const [filterData, setFilterData] = useState<IfilterData>(initialFilterData);

  const handleChange = (value: string | string[], name: string) => {
    if ((typeof value === 'string' || typeof value === 'object') && name) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Fetching groups
  const { data: dataProjectsGroup } = useQuery({
    queryKey: ['ProjectGroups'],
    queryFn: () => ProjectGroups(projectName, email),
  });

  useEffect(() => {
    if (dataProjectsGroup?.data) {
      setFilterData((prev) => ({
        ...prev,
        selectedGroups: dataProjectsGroup?.data,
      }));
    }
  }, [dataProjectsGroup]);

  // Fetching clustering data
  const { data: dataClustring, isLoading: clusteringLoading } = useQuery({
    queryKey: [
      'Clustring',
      filterData.row_metric,
      filterData.col_metric,
      filterData.selectedGroups,
      filterData.num_genes,
    ],
    queryFn: () => Clustring(filterData, email, projectName),
    enabled: filterData.selectedGroups?.length > 0,
  });

  const renderPlot = () => {
    if (filterData.plot === 'PCA') {
      const pcaData = dataClustring?.data[1]?.PCA || [];

      // Group the PCA points by group
      const groupedData = pcaData.reduce(
        (
          acc: Record<
            string,
            { x: number[]; y: number[]; z: number[]; color: string }
          >,
          item: any,
        ) => {
          const group = item.group || 'Other';
          if (!acc[group]) {
            acc[group] = {
              x: [],
              y: [],
              z: [],
              color: item.group_color || '#000000',
            };
          }
          acc[group].x.push(item.PC1);
          acc[group].y.push(item.PC2);
          acc[group].z.push(item.PC3);
          return acc;
        },
        {},
      );

      // Build traces
      const pcaTraces = (
        Object.entries(groupedData) as [
          string,
          { x: number[]; y: number[]; z: number[]; color: string },
        ][]
      ).map(([group, values]) => {
        if (filterData.dimensions === '3') {
          return {
            x: values.x,
            y: values.y,
            z: values.z,
            type: 'scatter3d' as const,
            mode: 'markers' as const,
            marker: { color: values.color, size: 10 },
            name: group,
            showlegend: true,
          };
        } else {
          return {
            x: values.x,
            y: values.y,
            type: 'scatter' as const,
            mode: 'markers' as const,
            marker: { color: values.color, size: 15 },
            name: group,
          };
        }
      });

      // 3D PCA
      if (filterData.dimensions === '3') {
        return (
          <div ref={plotRef}>
            <Plot
              data={pcaTraces}
              layout={{
                width: 900,
                height: 700,
                showlegend: true,
                margin: { l: 0, r: 50, t: 50, b: 50 },
                legend: {
                  x: 1,
                  xanchor: 'left',
                  y: 1,
                  itemsizing: 'trace',
                },
                scene: {
                  domain: { x: [0, 0.9], y: [0, 1] },
                  xaxis: { title: 'PC1' },
                  yaxis: { title: 'PC2' },
                  zaxis: { title: 'PC3' },
                },
              }}
              config={{
                responsive: true,
                autosizable: true,
                modeBarButtonsToRemove: [
                  'zoom3d',
                  'pan3d',
                  'zoom2d',
                  'pan2d',
                  'select2d',
                  'lasso2d',
                  'zoomIn2d',
                  'zoomOut2d',
                  'autoScale2d',
                  'resetScale2d',
                  'hoverClosestCartesian',
                  'hoverCompareCartesian',
                  'toggleSpikelines',
                  'resetCameraDefault3d',
                  'resetCameraLastSave3d',
                  'hoverClosest3d',
                  'orbitRotation',
                  'tableRotation',
                ],
                displaylogo: false,
                showTips: false,
                toImageButtonOptions: {
                  filename: 'clustering_PCA_3D',
                  format: 'png',
                },
              }}
            />
          </div>
        );
      }
      // 2D PCA
      else if (filterData.dimensions === '2') {
        return (
          <div ref={plotRef}>
            <Plot
              data={pcaTraces}
              layout={{
                title: '',
                width: 700,
                height: 700,
                showlegend: true,
                xaxis: { title: 'PC1' },
                yaxis: { title: 'PC2' },
              }}
              config={commonConfig}
            />
          </div>
        );
      }
    }
    // Heatmap
    else if (filterData.plot === 'Heatmap') {
      const base64Png = dataClustring?.data[1]?.heatmap_png;

      if (!base64Png) {
        return <div>No heatmap image available</div>;
      }

      return (
        <div ref={plotRef} className="heatMapBuiltInImage">
          <img
            src={`data:image/png;base64,${base64Png}`}
            alt="Heatmap"
            style={{ width: '100%' }}
          />
        </div>
      );
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };

  return (
    <div className="flex justify-between">
      <div
        className="border-r-2 border-dashed w-1/4"
        style={{ padding: '16px' }}
      >
        {/* Filter Section */}
        <Title level={5}>Filter</Title>
        <Form.Item label="Select groups (at least two):">
          {clusteringLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ClipLoader color="#6E04FE" size={50} />
            </div>
          ) : (
            <Checkbox.Group
              value={filterData.selectedGroups}
              onChange={(checkedValues) =>
                handleChange(checkedValues, 'selectedGroups')
              }
            >
              {dataProjectsGroup?.data.map((group: any) => (
                <Checkbox key={group} value={group}>
                  {group}
                </Checkbox>
              ))}
            </Checkbox.Group>
          )}
        </Form.Item>

        <Divider />

        {/* Figure Section */}
        <Title level={5}>Figure</Title>
        <Form.Item label="Plot" colon={false}>
          <Select
            defaultValue={filterData.plot}
            value={filterData.plot}
            style={{ width: '100%' }}
            onChange={(value) => handleChange(value, 'plot')}
          >
            <Option value="PCA">PCA</Option>
            <Option value="Heatmap">Heatmap</Option>
          </Select>
        </Form.Item>

        <Divider />

        {/* Parameters Section */}
        <Title level={5}>Parameters</Title>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {filterData.plot === 'PCA' ? (
            <div>
              <Title level={5}>PCA</Title>
              <Form.Item
                label="Number of dimensions"
                colon={false}
                name="dimensions"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue={filterData.dimensions}
                  value={filterData.dimensions}
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'dimensions')}
                >
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                </Select>
              </Form.Item>
            </div>
          ) : (
            <div>
              <Title level={5}>Heatmap</Title>
              <Form.Item
                label="Number of genes"
                colon={false}
                name="num_genes"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue={filterData.num_genes}
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'num_genes')}
                >
                  <Option value="250">250</Option>
                  <Option value="500">500</Option>
                  <Option value="1000">1000</Option>
                  <Option value="2000">2000</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Distance metric for rows"
                colon={false}
                name="rowMetric"
                style={{ marginBottom: 0, gap: '20px' }}
              >
                <Select
                  defaultValue="correlation"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'row_metric')}
                >
                  <Option value="euclidean">euclidean</Option>
                  <Option value="correlation">correlation</Option>
                  <Option value="cityblock">manhattan</Option>
                  <Option value="cosine">cosine</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Distance metric for columns"
                colon={false}
                name="colMetric"
                style={{ marginBottom: 0 }}
              >
                <Select
                  defaultValue="euclidean"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'col_metric')}
                >
                  <Option value="euclidean">euclidean</Option>
                  <Option value="correlation">correlation</Option>
                  <Option value="cityblock">manhattan</Option>
                  <Option value="cosine">cosine</Option>
                </Select>
              </Form.Item>
            </div>
          )}
        </div>
      </div>

      {/* MAIN PLOT AREA */}
      <div className="w-3/4 pl-20">
        <div className="flex justify-between ">
          <h1 className="text-2xl font-semibold mb-5"></h1>
          <div className="flex mr-8 relative">
            <button
              className="text-onex-purple-80 mr-2"
              onClick={() =>
                filterData.plot === 'PCA' && filterData.dimensions === '3'
                  ? DownloadImage3D(plotRef)
                  : filterData.plot === 'Heatmap'
                    ? DownloadBuiltImage(plotRef)
                    : DownloadImage(plotRef, `clustering_${filterData.plot}`)
              }
            >
              {clusteringLoading ? (
                <div>
                  <BarLoader width={50} color="#6E04FE" />
                </div>
              ) : (
                'Download'
              )}
            </button>
            <button onClick={() => setIsModalOpen(true)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                  stroke="#374548"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <Modal
              open={isModalOpen}
              title={
                filterData.plot === 'PCA'
                  ? ClusteringStaticData.PCA.title
                  : ClusteringStaticData.HeatMap.title
              }
              description={`This is the ${
                filterData.plot === 'PCA'
                  ? ClusteringStaticData.PCA.title
                  : ClusteringStaticData.HeatMap.title
              } description.`}
              onClose={() => setIsModalOpen(false)}
            />
            <div id="modal-root"></div>
          </div>
        </div>
        <ToastContainer />
        {clusteringLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <ClipLoader color= '#6E04FE' size={50} />
          </div>
        ) : (
          <div className="mt-6 mr-10 text-center">{renderPlot()}</div>
        )}
      </div>
    </div>
  );
};

export default Clustering;
