import React, { useState } from 'react';
import SharedProjectRow from './SharedProjectRow';
import { Project } from '../../interface/project';
import { Checkbox, Pagination } from '@mui/material';

interface SharedProjectTableProps {
  projects: Project[];
}

const SharedProjectTable: React.FC<SharedProjectTableProps> = ({ projects }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  // Calculate pagination
  const indexOfLastProject = currentPage * rowsPerPage;
  const indexOfFirstProject = indexOfLastProject - rowsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(projects.length / rowsPerPage);

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-6 table-auto">
        <thead className="border-b border-black">
          <tr className="text-gray-700">
            <th className="w-1/5 py-3 text-left">
              <Checkbox />
              <span className="ml-2">Project Name</span>
            </th>
            <th className="w-1/6 px-6 py-3 text-left">Experiment</th>
            <th className="w-1/6 px-6 py-3 text-left">Genome</th>
            <th className="w-1/6 px-6 py-3 text-left">Status</th>
            <th className="w-1/6 px-6 py-3 text-left">Date Added</th>
            <th className="w-1/4 px-6 py-3 text-left">Owner</th>
            <th className="w-1/12 px-6 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project) => (
            <SharedProjectRow key={project.name} project={project} />
          ))}
        </tbody>
      </table>
      <div className="flex justify-end">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="primary"
          variant="outlined"
          shape="rounded"
          size="large"
        />
      </div>
    </div>
  );
};

export default SharedProjectTable;
