import React from 'react';
import ReactDOM from 'react-dom';
interface IExplanationMark {
  open: boolean;
  title: string;
  description: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<IExplanationMark> = ({
  open,
  title,
  description,
  onClose,
}) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-10 flex justify-center items-center z-50 "
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 max-w-2xl w-full transform transition-transform duration-300 scale-100 relative"
        style={{ animation: 'fadeIn 0.3s ease-out' }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h2 className="text-xl font-semibold mb-4">{title}</h2>
          <button
            className="text-gray-500 absolute top-3 right-3"
            onClick={onClose}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#374548"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#374548"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <p style={{ whiteSpace: 'pre-line' }} className="text-gray-600 mb-6">
            {description}
          </p>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') as HTMLElement,
  );
};

export default Modal;
