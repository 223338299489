import { Checkbox, Select, Divider, Typography, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import {
  ListOverlapAPI,
  Comparison,
  SharedWith,
} from '../../../services/vnexPlotApi';
import { toast, ToastContainer } from 'react-toastify';
import { BarLoader, ClipLoader } from 'react-spinners';
const { Option } = Select;
const { Title } = Typography;
import 'react-toastify/dist/ReactToastify.css';
import Plotly from 'plotly.js-basic-dist';
import Modal from '../Modal';
import { DownloadImage } from '../../../utils/download';
import { commonConfig } from '../../../utils/plotConfig';
import { useQuery } from '@tanstack/react-query';

interface ListOverlapProps {
  projectName: string;
}

export interface IfilterDataListOverlap {
  fold_change_threshold: number | null;
  statistical_significance_threshold: number | null;
  use: string;
  selectedGroups: string[];
}

const ListOverlap: React.FC<ListOverlapProps> = ({ projectName }) => {
  const [groups, setGroups] = useState<string[]>([]);
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const plotRef = useRef<any>();

  const initialFilterData = {
    plot: 'PCA',
    fold_change_threshold: 2.0,
    statistical_significance_threshold: 0.05,
    use: 'Pvalue',
    selectedGroups: groups,
  };
  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? '';
  const [filterData, setFilterData] =
    useState<IfilterDataListOverlap>(initialFilterData);
  const [data, setData] = useState<any>();

  const handleChange = (value: string | string[] | number, name: string) => {
    // This condition is a bit redundant but left intact from your original code.
    if (
      typeof (value === 'string' || value === 'object' || value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { data: dataComparison, isLoading: comparisonLoading } = useQuery({
    queryKey: ['Comparison'],
    queryFn: () => Comparison(projectName, email),
  });

  useEffect(() => {
    if (dataComparison?.data) {
      const fetchedData = dataComparison?.data || [];
      setGroups(fetchedData);
      setFilterData((prev) => ({
        ...prev,
        ['selectedGroups']: dataComparison?.data,
      }));
    }
  }, [dataComparison]);

  const {
    data: dataListOverLap,
    isLoading: ListOverLapLoading,
    isError: ErrorMessage,
  } = useQuery({
    queryKey: ['ListOverLap', filterData],
    queryFn: () => ListOverlapAPI(filterData, email, projectName),
    enabled: filterData.selectedGroups?.length > 0,
  });

  const binary_matrix = dataListOverLap?.data?.binary_matrix;

  const UpSetPlot: React.FC = () => {
    useEffect(() => {
      // Generic combinations function with explicit types.
      const combinations = <T,>(array: T[], size: number): T[][] => {
        if (size === 0) return [[]];
        if (array.length === 0) return [];
        const [first, ...rest] = array;
        const combsWithoutFirst = combinations(rest, size);
        const combsWithFirst = combinations(rest, size - 1).map((comb) => [
          first,
          ...comb,
        ]);
        return [...combsWithFirst, ...combsWithoutFirst];
      };

      // Ensure binaryDataAPI exists before using it.
      const binaryDataAPI = binary_matrix;
      const columns =
        binaryDataAPI && binaryDataAPI.length > 0
          ? Object.keys(binaryDataAPI[0])
          : [];
      if (columns.length === 0) {
        console.error('No columns found in the dataset.');
        return;
      }
      const d = columns.length;

      // Explicitly type subsets as an array of string arrays.
      let subsets: string[][] = [];
      for (let i = 1; i <= d; i++) {
        subsets = subsets.concat(combinations(columns, i));
      }

      const subsetSizes = subsets.map(
        (subset) =>
          binaryDataAPI.filter((row: any) =>
            columns.every((col: string) =>
              subset.includes(col) ? row[col] : !row[col],
            ),
          ).length,
      );

      const sortedData = subsets
        .map((subset, i) => ({
          subset,
          size: subsetSizes[i],
        }))
        .sort((a, b) => b.size - a.size);

      const sortedSubsets = sortedData.map((d) => d.subset);
      const sortedSizes = sortedData.map((d) => d.size);
      const maxY = Math.max(...sortedSizes) * 1.1;

      const yTickPositions: number[] = columns
        .map((_, j) => -j * (maxY / d) - 0.1 * maxY)
        .reverse();
      const tickText: string[] = columns.slice().reverse();

      // yMapping with an explicit index signature.
      const yMapping: { [key: string]: number } = {};
      columns.forEach((col, index) => {
        yMapping[col] = yTickPositions[columns.length - 1 - index];
      });

      // Explicitly type the arrays.
      const scatterX: number[] = [];
      const scatterY: number[] = [];
      const verticalLines: Partial<Plotly.PlotData>[] = [];

      sortedSubsets.forEach((subset, i) => {
        const yCoords = subset.map((group) => yMapping[group]);

        if (yCoords.length > 1) {
          yCoords.sort((a, b) => a - b); // Ensure correct order for vertical line

          verticalLines.push({
            x: [i, i],
            y: [yCoords[0], yCoords[yCoords.length - 1]],
            mode: 'lines',
            line: { color: '#39F2AE', width: 2 },
            hoverinfo: 'skip',
          });
        }

        subset.forEach((group) => {
          scatterX.push(i);
          scatterY.push(yMapping[group]);
        });
      });

      const trace1: Partial<Plotly.PlotData> = {
        x: Array.from({ length: sortedSizes.length }, (_, i) => i),
        y: sortedSizes,
        type: 'bar',
        name: 'Subset Sizes',
        marker: { color: '#6E04FE' },
        hoverinfo: 'y',
        text: sortedSizes,
        textposition: 'outside',
        textfont: { family: 'Arial, sans-serif', size: 12, color: '#000' },
      };

      const trace2: Partial<Plotly.PlotData> = {
        x: scatterX,
        y: scatterY,
        mode: 'markers',
        name: 'Intersections',
        marker: { color: '#39F2AE', size: 15 },
        hoverinfo: 'skip',
      };

      // Cast tickmode as the literal "array" to match the expected type.
      const layout: Partial<Plotly.Layout> = {
        margin: {
          l: Math.max(
            150,
            tickText.reduce((max, label) => Math.max(max, label.length), 0) *
              10,
          ),
          r: 50,
          t: 50,
          b: 50,
        },
        title: '',
        xaxis: { showticklabels: false, tickvals: [], ticktext: [] },
        yaxis: {
          tickmode: 'array' as 'array',
          tickvals: yTickPositions,
          ticktext: tickText,
          showgrid: false,
        },
        showlegend: false,
      };

      Plotly.newPlot(
        'upset-plot',
        [trace1, trace2, ...verticalLines],
        layout,
        commonConfig,
      );
    }, []);

    return (
      <>
        {comparisonLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader color="#39F2AE" size={50} />
          </div>
        ) : (
          <div id="upset-plot"></div>
        )}
      </>
    );
  };

  return (
    <div className="flex justify-between">
      <div
        className="border-r-2 border-dashed w-1/4"
        style={{ padding: '16px' }}
      >
        <Title level={5}>Filter</Title>
        {comparisonLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader color="#39F2AE" size={50} />
          </div>
        ) : (
          <Form.Item label="Select comparisons (at least two):">
            <Checkbox.Group
              value={filterData.selectedGroups}
              onChange={(checkedValues) =>
                handleChange(checkedValues, 'selectedGroups')
              }
            >
              {groups.map((group) => (
                <Checkbox key={group} value={group}>
                  {group}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
        <Title level={5}>DE Criteria</Title>

        <Form.Item label="Fold change threshold" colon={false}>
          <Select
            defaultValue={filterData.fold_change_threshold}
            value={filterData.fold_change_threshold}
            style={{ width: '100%' }}
            onChange={(value) => handleChange(value, 'fold_change_threshold')}
          >
            <Option value={1.5}>1.5</Option>
            <Option value={2.0}>2.0</Option>
            <Option value={4.0}>4.0</Option>
            <Option value={null}>None</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Statistical significance threshold" colon={false}>
          <Select
            defaultValue={filterData.statistical_significance_threshold}
            value={filterData.statistical_significance_threshold}
            style={{ width: '100%' }}
            onChange={(value) =>
              handleChange(value, 'statistical_significance_threshold')
            }
          >
            <Option value={0.1}>0.1</Option>
            <Option value={0.05}>0.05</Option>
            <Option value={0.01}>0.01</Option>
            <Option value={null}>None</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Use" colon={false}>
          <Select
            value={filterData.use}
            defaultValue={filterData.use}
            style={{ width: '100%' }}
            onChange={(value) => handleChange(value, 'use')}
          >
            <Option value="Pvalue">Pvalue</Option>
            <Option value="FDR">FDR</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="w-3/4 pl-20">
        <div className="flex justify-between ">
          <h1 className="text-2xl font-semibold mb-5"></h1>
          <div className="flex mr-8 relative">
            <button
              className="text-onex-purple-80 mr-2"
              onClick={() => DownloadImage(plotRef, 'ListOverlap')}
            >
              {ListOverLapLoading ? (
                <div>
                  <BarLoader width={50} color="#39F2AE" />
                </div>
              ) : (
                'Download'
              )}
            </button>
            <button onClick={() => setIsModalOpen(true)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                  stroke="#374548"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <Modal
              open={isModalOpen}
              title="Help"
              description={
                <>
                  Upset plot showing the overlap between the lists of
                  differentially expressed genes.
                  <br />
                  <br />
                  <strong>Upper barplot:</strong> Number of genes in each of the
                  overlaps.
                  <br />
                  <strong>Bottom dots:</strong> The dots in the bottom highlight
                  the different overlaps between lists.
                  <br />
                  <br />
                  <strong>Explanation:</strong> An Upset Plot is a data
                  visualization designed to show intersections between multiple
                  sets in a clear and scalable way, offering an alternative to
                  Venn diagrams. It uses a matrix layout where rows represent
                  the sets and columns represent intersections, with bar charts
                  above and to the side displaying the sizes of intersections
                  and sets.
                </>
              }
              onClose={() => setIsModalOpen(false)}
            />
            {/* Ensure that the div with id="modal-root" exists in your HTML */}
            <div id="modal-root"></div>
          </div>
        </div>
        <ToastContainer />
        {ErrorMessage ? (
          <h2>Not enough comparisons to compute an Upset plot</h2>
        ) : ListOverLapLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader color="#39F2AE" size={50} />
          </div>
        ) : (
          <div className="mt-6" ref={plotRef}>
            <div>
              <div className="mt-6">
                <UpSetPlot />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListOverlap;
