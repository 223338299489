import React, { useState, useEffect } from 'react';

interface UploadProgressProps {
  uploading: boolean;
  progress: number;
  fileType: 'url' | 'file' | 'ftp' | 'geocode'; // Added geocode to fileType prop
}

const UploadProgress: React.FC<UploadProgressProps> = ({ uploading, progress, fileType }) => {

  return (
    <div>
      {uploading && (
        <div className="relative pt-1">
          <div className="flex items-center justify-between mb-2">
            <div>
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-blue-600 rounded-full">
                {fileType === 'url'
                  ? 'URL Uploading'
                  : fileType === 'ftp'
                  ? 'FTP Uploading'
                  : fileType === 'geocode'
                  ? 'GeoCode Uploading'
                  : 'File Uploading'}
              </span>
            </div>
            <div className="mt-1">
              <span className="inline-block text-xs font-semibold text-blue-600">
                {progress}%
              </span>
            </div>
          </div>

          <div className="flex h-2 mb-4 overflow-hidden text-xs bg-blue-100 rounded">
            <div
              style={{ width: `${progress}%` }}
              className={`flex flex-col justify-center text-center text-white bg-blue-700 shadow-none whitespace-nowrap`}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadProgress;
