import { apiClient } from "./apiClient";
import { AxiosResponse } from 'axios';

export const GetConfig = (projectName: string): Promise<AxiosResponse<string[]>> => {
  return apiClient.get(`/config/${projectName}`);
};
export const SubmitGroups = (projectName: string, payload: any): Promise<AxiosResponse<any>> => {
  return apiClient.post(`/config/${projectName}/submit_groups`, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const SubmitComparisons = (projectName: string, payload: any): Promise<AxiosResponse<any>> => {
  return apiClient.post(`/config/${projectName}/submit_comparisons`, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
export const RunScript = (projectName: string): Promise<AxiosResponse<string[]>> => {
  return apiClient.post(`/config/${projectName}/run_script`);
};
