import React, { useRef } from 'react';
import { uploadImageApi, deleteProfilePictureApi } from '../../services/ProfileApi';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import default_image from '../../assets/profile_pic.jpg';
import { useProfile } from '../../contexts/ProfileContext';

const MySwal = withReactContent(Swal);

const ProfilePicture: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { profile, refetchProfile } = useProfile(); // Access profile and refetchProfile from context

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        // Upload the image
        await uploadImageApi(file);

        // Refetch the profile data
        refetchProfile();

        // Reset the file input to allow the same file to be uploaded again
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        MySwal.fire({
          title: 'Error!',
          text: 'There was an error uploading the profile picture.',
          icon: 'error',
        });
      }
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      // Delete the profile picture
      await deleteProfilePictureApi();

      // Refetch the profile data
      refetchProfile();

      // Reset the file input to allow the same file to be uploaded again
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error deleting the profile picture.',
        icon: 'error',
      });
    }
  };

  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">
        Update Profile Picture
      </h2>
      <div className="flex items-center justify-between p-4 border border-gray-300 rounded-lg">
        <div className="flex items-center">
          <img
            src={profile?.pfp || default_image} // Use the profile image from context or default image
            alt="Profile"
            className="object-cover w-16 h-16 rounded-full"
          />
          <span className="ml-4 font-semibold text-gray-700">
            Profile Image
          </span>
        </div>
        <div>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*"
            ref={fileInputRef} // Use ref to control the input element
          />
          <label
            htmlFor="fileInput"
            className="px-4 py-2 font-semibold text-white bg-teal-700 rounded cursor-pointer"
          >
            Change
          </label>
          {profile?.pfp && profile.pfp !== default_image && (
            <button
              onClick={handleDeleteProfilePicture}
              className="px-4 py-2 ml-2 font-semibold text-white bg-orange-500 rounded"
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
