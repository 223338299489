import axios from 'axios';
import Cookies from 'js-cookie';

// Change this to whatever your backend API is
const API_BASE_URL = '/onex';

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Ensure cookies are sent with the request
});

// Request interceptor to add the access token and refresh token from the cookies to a custom header
apiClient.interceptors.request.use((config) => {
  const accessToken = Cookies.get('access_token');
  const refreshToken = Cookies.get('refresh_token');

  if (accessToken && refreshToken) {
    config.headers['X-Custom-Cookie'] = `access_token=${accessToken}; refresh_token=${refreshToken}`;
  } else if (accessToken) {
    config.headers['X-Custom-Cookie'] = `access_token=${accessToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor to handle errors
apiClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  // Handle 401 - Unauthorized
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      

      // Assuming the new tokens are set in cookies by the backend
      return apiClient(originalRequest);
    } catch (refreshError) {
      console.error('Refresh token expired, redirecting to login...');
      if (window.location.pathname !== '/login') {  // Check if not already on the login page
        window.location.href = 'http://localhost:3000/login';  // Redirect to login page
      }
      return Promise.reject(refreshError);
    }
  }

  // Handle 307 - Temporary Redirect
  if (error.response && error.response.status === 307) {
    const redirectUrl = error.response.headers.location || 'http://localhost:3000/login';
    if (window.location.pathname !== '/login') {  // Check if not already on the login page
      window.location.href = redirectUrl;  // Redirect to the specified location
    }
    return;
  }

  // Handle JSON-based redirect response (401 with redirect_url in the response body)
  if (error.response && error.response.status === 401 && error.response.data.redirect_url) {
    const redirectUrl = error.response.data.redirect_url;
    if (window.location.pathname !== '/login') {  // Check if not already on the login page
      window.location.href = redirectUrl;  // Redirect to the specified location
    }
    return;
  }

  return Promise.reject(error);
});
