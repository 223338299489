import { useState, useEffect, useRef } from 'react';

type ProjectData = {
    project: string;
    bytes_downloaded: number;
};
export function useWebSocket(url: string, shouldConnect: boolean) {
// export function useWebSocket(url: string, shouldConnect: boolean) {
  const [data, setData] = useState<ProjectData | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const latestMessage = useRef<string | null>(null); // Use ref to store the latest message
  const ws = useRef<WebSocket | null>(null);

 
  useEffect(() => {
    if (!shouldConnect) {
      // Close the connection if shouldConnect is false
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.close();
        setIsConnected(false);
      }
      return;
    }

    // Initialize WebSocket connection only when shouldConnect is true
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      setIsConnected(true);
    };

    ws.current.onmessage = (event: MessageEvent) => {
      // Store the latest message in the ref
      latestMessage.current = event.data;
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
    };

    ws.current.onerror = (error: Event) => {
      console.error('WebSocket error occurred:', error);
    };

    // Cleanup WebSocket connection when the component using this hook unmounts
    return () => {
      if (ws.current) {
        ws.current.close();
        setIsConnected(false);
      }
    };
  }, [url, shouldConnect]); // Depend on both the URL and shouldConnect

  // Set an interval to update `data` every 3 seconds with the latest message
  useEffect(() => {
    const interval = setInterval(() => {
      if (latestMessage.current) {
        setData(JSON.parse(latestMessage.current)); // Parse and set the latest message
      }
    }, 100); // Trigger every few seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  return { data, isConnected };
}
