import React from 'react';
import { Profile } from '../../types/profile';
import { getSubscriptionLimits } from '../../utils/subscriptionLimits'; // Adjust path as needed

interface UserSubscription {
  id: string;
  status: string;
  item_price_id: string;
  item_price_name: string;
  item_price_amount: number;
  next_billing_at: number;
}

interface MonthlyUsageProps {
  profile: Profile;
  userSubscription: UserSubscription | null;
}

const MonthlyUsage: React.FC<MonthlyUsageProps> = ({ profile, userSubscription }) => {
  const totalDataGB = profile.total_data / 1024;
  
  // Get subscription limits from external file
  const { maxDataGB, maxProjects } = getSubscriptionLimits(userSubscription?.item_price_name || 'Free');

  const getGradientColor = (percentage: number) => {
    const green = [34, 197, 94];  // RGB for green
    const orange = [251, 146, 60]; // RGB for orange
    const red = [239, 68, 68];  // RGB for red

    let color;
    if (percentage < 0.5) {
      const factor = percentage / 0.5;
      color = [
        Math.round(green[0] + (orange[0] - green[0]) * factor),
        Math.round(green[1] + (orange[1] - green[1]) * factor),
        Math.round(green[2] + (orange[2] - green[2]) * factor),
      ];
    } else {
      const factor = (percentage - 0.5) / 0.5;
      color = [
        Math.round(orange[0] + (red[0] - orange[0]) * factor),
        Math.round(orange[1] + (red[1] - orange[1]) * factor),
        Math.round(orange[2] + (red[2] - orange[2]) * factor),
      ];
    }

    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  };

  const dataUsagePercentage = totalDataGB / maxDataGB;
  const projectUsagePercentage = profile.num_projects / maxProjects;

  const handleUpgradeClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    window.scrollBy({ top: 550, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">Monthly Usage</h2>
      
      {/* Data Usage */}
      <div className="mb-4">
        <div className="flex justify-between mb-3 text-sm font-semibold text-gray-700">
          <span>{totalDataGB.toFixed(2)}GB used of {maxDataGB}GB this month</span>
          <a href="#" className="text-teal-700" onClick={handleUpgradeClick}>
            Upgrade plan to get more storage
          </a>
        </div>
        <div className="w-full h-2 bg-gray-200 rounded">
          <div
            className="h-full rounded"
            style={{
              width: `${Math.min(dataUsagePercentage * 100, 100)}%`,
              backgroundColor: getGradientColor(dataUsagePercentage),
            }}
          ></div>
        </div>
      </div>

      {/* Project Usage */}
      <div className="mb-4">
        <div className="flex justify-between mb-3 text-sm font-semibold text-gray-700">
          <span>{profile.num_projects} projects used of {maxProjects} this month</span>
          <a href="#" className="text-teal-700" onClick={handleUpgradeClick}>
            Upgrade plan to create more projects
          </a>
        </div>
        <div className="w-full h-2 bg-gray-200 rounded">
          <div
            className="h-full rounded"
            style={{
              width: `${Math.min(projectUsagePercentage * 100, 100)}%`,
              backgroundColor: getGradientColor(projectUsagePercentage),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyUsage;
