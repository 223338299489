import { Form, Select, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { PlotData } from 'plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  GetGeneData,
  GetGeneList,
  SharedWith,
} from '../../../services/vnexPlotApi';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import { GeneData } from '../../../data/CONSTANT';
import Modal from '../Modal';
import { DownloadImage } from '../../../utils/download';
import { commonConfig } from '../../../utils/plotConfig';
import { BarLoader, ClipLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

interface GeneListProps {
  projectName: string;
}
export interface IfilterGeneList {
  genes: string;
  plot: string;
  scale: string;
}

const GeneList: React.FC<GeneListProps> = ({ projectName }) => {
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const plotRef = useRef<any>();
  const initialFilterData = {
    genes: '',
    plot: 'dotplot',
    scale: 'log2',
  };
  const [filterData, setFilterData] =
    useState<IfilterGeneList>(initialFilterData);

  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? '';

  const handleChange = (
    value: string | number | string[] | null,
    name: string,
  ) => {
    if (
      (typeof value === 'string' ||
        Array.isArray(value) ||
        typeof value === 'number' ||
        value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { data: dataGeneList, isLoading: GeneListLoading } = useQuery({
    queryKey: ['GeneList', filterData.genes],
    queryFn: () => GetGeneList(projectName, email),
  });
  useEffect(() => {
    if (dataGeneList?.data) {
      const fetchedData = dataGeneList?.data || [];
      setFilterData((prev) => ({
        ...prev,
        genes: prev.genes || (fetchedData.length ? fetchedData[0] : ''),
      }));
    }
  }, [dataGeneList]);
  const genes = dataGeneList?.data || [];

  const { data: dataGene, isLoading: GeneLoading } = useQuery({
    queryKey: ['dataGene', filterData],
    queryFn: () => GetGeneData(filterData, email, projectName),
    enabled: dataGeneList?.data?.length > 0,
  });
  const table_data = dataGene?.data?.table_data || [];
  const plot_data = dataGene?.data?.plot_data || [];

  const colors = plot_data?.map((item: any) => item?.group_color);
  const dotplotGroups = plot_data?.map((item: any) => item?.group);

  useEffect(() => {
    if (table_data && table_data.length > 0) {
      setSearchResults(table_data); // Sync search results with table data
    }
  }, [table_data]);

  const extractComparisons = (comparisonString: any) => {
    const [firstValue, secondValue] = comparisonString.split('_vs_');
    return { firstValue, secondValue };
  };

  // Applying it to the first object in the array
  if (!table_data.length) return null; // Wait until data is available

  const { firstValue, secondValue } = extractComparisons(
    table_data[0].Comparison,
  );
  // const firstValue = 'firstValue';
  // const secondValue = 'secondValue';


  const columns: ColumnsType<any> = [
    {
      title: 'Comparison',
      dataIndex: 'Comparison',
      key: 'Comparison',
    },
    {
      title: `Mean Group 1`,
      dataIndex: 'MeanFirst',
      key: 'MeanFirst',
    },
    {
      title: `Mean Group 2`,
      dataIndex: 'MeanSecond',
      key: 'MeanSecond',
    },
    {
      title: 'logFC',
      dataIndex: 'logFC',
      key: 'logFC',
    },
    {
      title: 'Pval',
      dataIndex: 'Pval',
      key: 'Pval',
    },
    {
      title: 'FDR',
      dataIndex: 'FDR',
      key: 'FDR',
    },
  ];

  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      setSearchResults(table_data);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filtered = table_data.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerCaseTerm),
        ),
      );

      setSearchResults(filtered);
    }
  };

  const standardizedData = searchResults.map(row => {
    // Extract group names from each row's comparison
    const { firstValue: rowFirst, secondValue: rowSecond } = extractComparisons(row.Comparison);
    
    return {
      Comparison: row.Comparison,
      MeanFirst: parseFloat(row[`Mean ${rowFirst}`]).toFixed(4), 
      MeanSecond: parseFloat(row[`Mean ${rowSecond}`]).toFixed(4),
      logFC: row.logFC,
      Pval: row.Pval,
      FDR: row.FDR
    };
  });

  const downloadTableData = (data: any, format: string) => {
    if (format === 'csv') {
      const csvContent = [
        [
          'Comparison',
          [`Mean ${firstValue}`],
          [`Mean ${secondValue}`],
          'logFC',
          'Pval',
        ],
        ...data.map((row: any) => [
          row.Comparison,
          row[`Mean ${firstValue}`],
          row[`Mean ${secondValue}`],
          row['logFC'],
          row['Pval'],
        ]),
      ]
        .map((e) => e.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `Gene_${filterData.plot}.csv`);
      link.click();
    } else if (format === 'json') {
    }
  };
  const GenePlots = () => {
    if (filterData.plot === 'dotplot') {
      // Get unique categories to correctly determine the range
      const uniqueCategories = Array.from(new Set(dotplotGroups));
      // Adjust these values to control spacing on the left and right sides
      const leftPadding = 0.5; // Increase to add more space on the left
      const rightPadding = 0.5; // Increase to add more space on the right

      return (
        <>
          <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            {/* <h3 className="mb-3 font-semibold text-black">
              {GeneData.Dotplot.title}
            </h3> */}
            {/* <p className="w-5/6">{GeneData.Dotplot.description}</p> */}
          </div>
          <div ref={plotRef}>
            <Plot
              data={[
                {
                  y: plot_data.map((item: any) => item.expression),
                  x: dotplotGroups,
                  type: 'scatter',
                  mode: 'markers',
                  marker: {
                    color: plot_data.map((item: any) => item.group_color), // Use colors directly
                    size: 15, // Marker size
                  },
                  text: plot_data.map((item: any) => item.sample_name), // Tooltip text for markers
                  name: '', // Legend label (empty)
                },
              ]}
              layout={{
                width: 1000,
                height: 700,
                showlegend: false,
                xaxis: {
                  // For categorical data, Plotly assigns positions 0, 1, 2, ... to each category.
                  // The first category is at 0 and the last at (uniqueCategories.length - 1).
                  // This range adds extra space by subtracting leftPadding from 0 and adding rightPadding after the last category.
                  range: [
                    -leftPadding,
                    uniqueCategories.length - 1 + rightPadding,
                  ],
                  type: 'category', // Ensure values are treated as categories
                },
                yaxis: {
                  title: 'Expression',
                },
              }}
              config={commonConfig}
            />
          </div>
        </>
      );
    } else if (filterData.plot === 'boxplot') {
      // Group the data by the "group" property
      const groups: string[] = Array.from(
        new Set(plot_data.map((item: any) => item.group)),
      );

      // Create a trace for each group
      const traces = groups.map((group: string) => {
        const groupData = plot_data.filter((item: any) => item.group === group);
        const groupColor = groupData[0].group_color;

        return {
          // For the x-axis, use the group name for every data point in that group
          x: groupData.map(() => group),
          y: groupData.map((item: any) => item.expression),
          type: 'box' as const,
          name: group,
          marker: { color: groupColor, size: 15 },
          fillcolor: 'rgba(0,0,0,0)', // transparent fill
          line: { color: groupColor, width: 4 },
          boxpoints: 'all' as const,
          jitter: 0.5,
          pointpos: 0,
          offsetgroup: group,
        };
      });

      return (
        <>
          <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            {/* <h3 className="mb-3 font-semibold text-black">
              {GeneData.Boxplot.title}
            </h3> */}
            {/* <p className="w-5/6">{GeneData.Boxplot.description}</p> */}
          </div>
          <div ref={plotRef}>
            <Plot
              data={traces}
              layout={{
                width: 1000,
                height: 700,
                showlegend: false,
                xaxis: {
                  type: 'category',
                  tickmode: 'array',
                  tickvals: groups,
                  ticktext: groups,
                },
                yaxis: {
                  title: 'Expression',
                },
                boxmode: 'overlay', // using overlay to center each box on its category tick
              }}
              config={commonConfig}
            />
          </div>
        </>
      );
    } else if (filterData.plot === 'violin') {
      // Group the data by the "group" property
      const groups: string[] = Array.from(
        new Set(plot_data.map((item: any) => item.group)),
      );

      // Create a trace for each group
      const traces = groups.map((group: string) => {
        const groupData = plot_data.filter((item: any) => item.group === group);
        const groupColor = groupData[0].group_color;

        return {
          // For the x-axis, use the group name for every data point in that group
          x: groupData.map(() => group),
          y: groupData.map((item: any) => item.expression),
          type: 'violin' as const,
          name: group,
          marker: { color: groupColor, size: 15 },
          fillcolor: 'rgba(0,0,0,0)', // transparent fill
          line: { color: groupColor, width: 4 },
          points: 'all' as const, // show all points
          boxpoints: 'all' as const,
          jitter: 0.5,
          pointpos: 0,
          offsetgroup: group,
          // Optionally, you can also try adding alignmentgroup if needed:
          // alignmentgroup: group,
        };
      });

      return (
        <>
          <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            {/* <h3 className="mb-3 font-semibold text-black">
                  {GeneData.Violin.title}
                </h3> */}
            {/* <p className="w-5/6">{GeneData.Boxplot.description}</p> */}
          </div>
          <div ref={plotRef}>
            <Plot
              data={traces}
              layout={{
                width: 1000,
                height: 700,
                showlegend: false,
                xaxis: {
                  type: 'category',
                  tickmode: 'array',
                  tickvals: groups,
                  ticktext: groups,
                },
                yaxis: {
                  title: 'Expression',
                },
                boxmode: 'overlay', // using overlay to center each box on its category tick
              }}
              config={commonConfig}
            />
          </div>
        </>
      );
    } else if (filterData.plot === 'barplot') {
      // Extract unique group names
      const groups: string[] = Array.from(
        new Set(plot_data.map((item: any) => item.group)),
      );

      // Group the data by the "group" property and compute the average expression and standard deviation per group
      const groupAverages = groups.map((group: string) => {
        const groupData = plot_data.filter((item: any) => item.group === group);


        // Compute the average expression
        const avgExpression =
          groupData.length > 0
            ? groupData.reduce(
                (sum: number, item: any) => sum + Number(item.expression),
                0,
              ) / groupData.length
            : 0;

        // Calculate the variance (using Bessel's correction for sample variance)
        const variance =
          groupData.length > 1
            ? groupData.reduce(
                (sum: number, item: any) =>
                  sum + Math.pow(Number(item.expression) - avgExpression, 2),
                0,
              ) /
              (groupData.length - 1)
            : 0;

        // Standard deviation is the square root of the variance
        const stdDev = Math.sqrt(variance);

        // Use the group's color, or a default if not provided
        const groupColor = groupData[0]?.group_color || '#000';

        return { group, avgExpression, groupColor, stdDev };
      });

      // Create a separate trace for each group so that each can have its own error bar settings
      const traces: Partial<PlotData>[] = groupAverages.map(
        ({ group, avgExpression, groupColor, stdDev }) => ({
          x: [group],
          y: [avgExpression],
          type: 'bar',
          marker: { color: groupColor },
          error_y: {
            type: 'data',
            array: [stdDev],
            visible: true,
            color: groupColor, // Set error bar color to match the bar
            thickness: 5, // Increase the thickness of the error bars
            width: 30, // Increase the width (the cross-bar size) of the error bars
          },
        }),
      );

      return (
        <>
          <div className="text-sm text-gray-500 mb-6 text-left block w-full">
            {/* <h3 className="mb-3 font-semibold text-black">
              {GeneData.Barplot.title}
            </h3> */}
          </div>
          <div ref={plotRef}>
            <Plot
              data={traces}
              layout={{
                width: 1000,
                height: 700,
                showlegend: false,
                barmode: 'group', // ensures the bars are treated as grouped categories
                xaxis: {
                  type: 'category',
                  tickmode: 'array',
                  tickvals: groups,
                  ticktext: groups,
                  title: 'Groups',
                },
                yaxis: {
                  title: 'Average Expression',
                },
              }}
              config={commonConfig}
            />
          </div>
        </>
      );
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          {GeneListLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} /> {/* Loader component */}
            </div>
          ) : (
            <Form.Item label="Select Gene" colon={false}>
              <Select
                showSearch
                value={filterData.genes} // Directly use the string value
                style={{ width: '100%' }}
                onChange={(value) => handleChange(value, 'genes')} // Pass selected value to the handler
                placeholder="Select an option"
              >
                {genes?.map((item: any, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Plot" colon={false}>
            <Select
              value={filterData.plot}
              defaultValue={filterData.plot}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'plot')}
            >
              <Option value="dotplot">Dotplot</Option>
              <Option value="boxplot">Boxplot</Option>
              <Option value="violin">Violin</Option>
              <Option value="barplot">Barplot</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Scale" colon={false}>
            <Select
              defaultValue={filterData.scale}
              value={filterData.scale}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'scale')}
            >
              <Option value="log2">log2</Option>
              <Option value="linear">linear</Option>
            </Select>
          </Form.Item>
        </div>

        {/* Plot Section */}
        <div className="flex-1 flex flex-col items-center  p-4">
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-2xl font-semibold mb-5"></h1>
            <div className="flex mr-8 relative">
              <button
                className="text-onex-purple-80 mr-2"
                onClick={() =>
                  DownloadImage(plotRef, `Gene_${filterData.plot}`)
                }
              >
                {GeneLoading ? (
                  <div>
                    <BarLoader width={50} color="#39F2AE" />
                    {/* Loader component */}
                  </div>
                ) : (
                  'Download'
                )}
              </button>
              <button onClick={() => setIsModalOpen(true)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                    stroke="#374548"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <Modal
                open={isModalOpen}
                title="Gene"
                description="This is the Gene description."
                onClose={() => setIsModalOpen(false)}
              />
              {/* Add a div with id="modal-root" to your HTML */}
              <div id="modal-root"></div>
            </div>
          </div>

          {GeneLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} /> {/* Loader component */}
            </div>
          ) : (
            <div className="w-full text-center">{GenePlots()}</div>
          )}
        </div>
      </div>
      <ToastContainer />
      <DataTable
        columns={columns}
        data={standardizedData}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default GeneList;
