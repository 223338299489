import React, { createContext, useContext, useState } from 'react';

const ProjectRefreshContext = createContext(() => {});

export const useProjectRefresh = () => useContext(ProjectRefreshContext);

export const ProjectRefreshProvider = ({ children }: { children: React.ReactNode }) => {
  const [refreshFlag, setRefreshFlag] = useState(false);

  const triggerRefresh = () => setRefreshFlag((prev) => !prev);

  return (
    <ProjectRefreshContext.Provider value={triggerRefresh}>
      {children}
    </ProjectRefreshContext.Provider>
  );
};
