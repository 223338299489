import React from 'react';
import { FiX } from 'react-icons/fi';

interface Group {
  groupName: string;
  color: string;
}

interface ComparisonListProps {
  comparisons: string[];
  groups: Group[];
  removeComparison: (index: number) => void;
}

const ComparisonList: React.FC<ComparisonListProps> = ({ comparisons, groups, removeComparison }) => {
  return (
    <div className="grid grid-cols-1 gap-8 mb-8 sm:grid-cols-2 lg:grid-cols-3">
      {comparisons.map((comparison, index) => {
        const [interestName, referenceName] = comparison.split(' vs ');
        const interestGroup = groups.find(group => group.groupName === interestName);
        const referenceGroup = groups.find(group => group.groupName === referenceName);

        return (
          <div 
            key={index} 
            className="relative flex items-center max-w-full p-4 mb-8 space-x-4 bg-white rounded-md shadow-md"
          >
            <div 
              className="absolute text-gray-700 bg-white rounded-full shadow-md cursor-pointer -top-1 -right-1"
              onClick={() => removeComparison(index)}
            >
              <FiX size={21} />
            </div>

            <div className="flex items-center flex-grow p-2 border border-gray-300 rounded-lg max-w-[150px]">
              <div className="relative flex-grow group">
                <span className="block max-w-full overflow-hidden text-ellipsis whitespace-nowrap" title={interestName}>
                  {interestName.length > 11 ? `${interestName.slice(0, 10)}...` : interestName}
                </span>   
                <div className="absolute z-10 hidden p-2 mt-2 text-white transform -translate-x-1/2 bg-gray-800 rounded-md shadow-lg top-full left-1/2 group-hover:block whitespace-nowrap">
                  {interestName}
                </div>
              </div>
              <div className="flex-shrink-0 w-6 h-6 ml-2 rounded-md" style={{ backgroundColor: interestGroup?.color }}></div>
            </div>

            <span className="text-xl">vs</span>

            <div className="flex items-center flex-grow p-2 border border-gray-300 rounded-lg max-w-[150px]">
              <div className="relative flex-grow group">
                <span className="block max-w-full overflow-hidden text-ellipsis whitespace-nowrap" title={referenceName}>
                  {referenceName.length > 11 ? `${referenceName.slice(0, 10)}...` : referenceName}
                </span>
                <div className="absolute z-10 hidden p-2 mt-2 text-white transform -translate-x-1/2 bg-gray-800 rounded-md shadow-lg top-full left-1/2 group-hover:block whitespace-nowrap">
                  {referenceName}
                </div>
              </div>
              <div className="flex-shrink-0 w-6 h-6 ml-2 rounded-md" style={{ backgroundColor: referenceGroup?.color }}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ComparisonList;
