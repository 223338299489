import { Form, Select, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  GetGseaData,
  Comparison,
  SharedWith,
} from '../../../services/vnexPlotApi';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import { GeneData } from '../../../data/CONSTANT';
import Modal from '../Modal';
import { DownloadImage } from '../../../utils/download';
import { commonConfig } from '../../../utils/plotConfig';
import { BarLoader, ClipLoader } from 'react-spinners';
import { IGseaData } from '../../../interface/vnexInterfaces';
import { useQuery } from '@tanstack/react-query';

interface GeneGseaProps {
  projectName: string;
}

export interface IfilterGsea {
  comparison: string;
  geneset: string;
  plot: string;
  nset: number;
  direction: string;
}

// Initial filter data (make sure comparison gets set later)
const initialFilterData: IfilterGsea = {
  comparison: '',
  geneset: 'Gene Ontology',
  plot: 'Dotplot',
  nset: 20,
  direction: 'All',
};

const GSEA: React.FC<GeneGseaProps> = ({ projectName }) => {
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const plotRef = useRef<any>();
  const [filterData, setFilterData] = useState<IfilterGsea>(initialFilterData);

  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? '';

  // Helper function to interpolate between two hex colors based on a normalized value (0 to 1)
  const interpolateColor = (
    value: number,
    color1: string,
    color2: string,
  ): string => {
    const c1 = {
      r: parseInt(color1.slice(1, 3), 16),
      g: parseInt(color1.slice(3, 5), 16),
      b: parseInt(color1.slice(5, 7), 16),
    };
    const c2 = {
      r: parseInt(color2.slice(1, 3), 16),
      g: parseInt(color2.slice(3, 5), 16),
      b: parseInt(color2.slice(5, 7), 16),
    };
    const r = Math.round((1 - value) * c1.r + value * c2.r);
    const g = Math.round((1 - value) * c1.g + value * c2.g);
    const b = Math.round((1 - value) * c1.b + value * c2.b);
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  const handleChange = (
    value: string | number | string[] | null,
    name: string,
  ) => {
    if (
      (typeof value === 'string' ||
        Array.isArray(value) ||
        typeof value === 'number' ||
        value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { data: dataComparison, isLoading: comparisonLoading } = useQuery({
    queryKey: ['Comparison', filterData.comparison],
    queryFn: () => Comparison(projectName, email),
  });

  // Fetch GSEA data when filter parameters change.
  useEffect(() => {
    if (dataComparison?.data) {
      const fetchedData = dataComparison?.data || [];
      setFilterData((prev) => ({
        ...prev,
        comparison:
          prev.comparison || (fetchedData.length ? fetchedData[0] : ''),
      }));
      console.log('GSEA data:', dataComparison);
    }
  }, [dataComparison]);

  const { data: dataGSEA, isLoading: GSEALoading } = useQuery({
    queryKey: ['dataGSEA', filterData],
    queryFn: () => GetGseaData(projectName, email, filterData),
  });
  interface IGseaDataTABLE {
    ID: string[];
    Description: string[];
    setSize: number[];
    core_enrichment: string[];
    enrichmentScore: number[];
    NES: number[];
    pvalue: number[];
    ['p.adjust']: number[];
    qvalue: number[];
    rank: number[];
  }
  const gseaData: IGseaDataTABLE | null = dataGSEA?.data?.gsea_data;
  const table_data = gseaData?.ID.map((id, index) => ({
    key: index,
    ID: id,
    Description: gseaData?.Description[index],
    setSize: gseaData?.setSize[index],
    core_enrichment: gseaData?.core_enrichment[index],
    enrichmentScore: gseaData?.enrichmentScore[index],
    NES: gseaData?.NES[index],
    ['p.adjust']: gseaData?.['p.adjust'][index],
    pvalue: gseaData?.pvalue[index],
    qvalue: gseaData?.qvalue[index],
    rank: gseaData?.rank[index],
  }));

  const columns: ColumnsType<IfilterGsea> = [
    // {
    //   title: 'Sr. No.',
    //   key: 'key',
    //   // render: (_, __, index) => {
    //   //   const indexPlusOne = index + 1;
    //   //   const asas = DifferentialExperienceFilteredData.slice(
    //   //     (index - 1) * pageSize,
    //   //   );
    //   //   const finalIndex = indexPlusOne + asas;

    //   //   return <span>{finalIndex}</span>;
    //   // },

    //   sorter: (a, b) => a.key - b.key,
    //   width: '10%',
    // },
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      // sorter: (a, b) => a.genes.localeCompare(b.genes),
    },
    {
      title: 'NES',
      dataIndex: 'NES',
      key: 'NES',
      // sorter: (a, b) => a.log2.Mean.Second.Group - b.log2.Mean.Second.Group,
    },

    {
      title: 'enrichmentScore',
      dataIndex: 'enrichmentScore',
      key: 'enrichmentScore',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },

    {
      title: 'pvalue',
      dataIndex: 'pvalue',
      key: 'pvalue',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },

    {
      title: 'p.adjust',
      dataIndex: 'p.adjust',
      key: 'p.adjust',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },

    {
      title: 'qvalue',
      dataIndex: 'qvalue',
      key: 'qvalue',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },

    {
      title: 'rank',
      dataIndex: 'rank',
      key: 'rank',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },

    {
      title: 'setSize',
      dataIndex: 'setSize',
      key: 'setSize',
      // sorter: (a, b) => a.log2.Mean.First.Group - b.log2.Mean.First.Group,
    },
  ];

useEffect(() => {
  if (table_data && table_data.length > 0) {
    setSearchResults(table_data); // Always update when table_data changes
  }
}, [table_data, filterData.geneset]); // Remove searchResults dependency

  const handleSearch = (searchTerm: string) => {
    if (table_data) {
      if (!searchTerm) {
        setSearchResults(table_data);
      } else {
        const lowerCaseTerm = searchTerm.toLowerCase();
        const filtered = table_data?.filter((item: any) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(lowerCaseTerm),
          ),
        );

        setSearchResults(filtered);
      }
    }
  };

  const downloadTableData = (data: IfilterGsea[], format: string) => {
    // return alert('Hello Download');
    if (format === 'csv') {
      const csvContent = [
        [
          'ID',
          'NES',
          'enrichmentScore',
          'pvalue',
          'p.adjust',
          'qvalue',
          'rank',
          'setSize',
          // 'core_enrichment',
          // 'Description',
        ],
        ...data.map((row: any) => [
          row.ID,
          row.NES,
          row.enrichmentScore,
          row.pvalue,
          row[`p.adjust`],
          row.qvalue,
          row.rank,
          row.setSize,
          // row.Description,
          // row.core_enrichment,
        ]),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `GSEA_${filterData.plot}.csv`);
      link.click();
    } else if (format === 'json') {
    }
  };

  const GseaPlot = () => {
    if (gseaData) {
      // Destructure including the new NES field
      const { Description, setSize, core_enrichment, pvalue, NES } = gseaData;
      // Combine arrays into an array of objects with computed count, geneRatio, and include NES
      const combined = Description.map((desc, i) => {
        const count = String(core_enrichment[i]).split('/').length;
        return {
          Description: desc,
          setSize: setSize[i],
          core_enrichment: core_enrichment[i],
          pvalue: pvalue[i],
          NES: NES[i],
          count,
          geneRatio: count / setSize[i],
        };
      });

      // Filter based on the "Direction" selection
      let filteredCombined = combined;
      if (filterData.direction === 'Activated') {
        filteredCombined = combined.filter((item) => item.NES > 0);
      } else if (filterData.direction === 'Supressed') {
        filteredCombined = combined.filter((item) => item.NES < 0);
      }

      // First, sort filtered data by pvalue (ascending) to select the most significant sets
      const sortedByPvalue = [...filteredCombined].sort(
        (a, b) => a.pvalue - b.pvalue,
      );
      const n = Number(filterData.nset);
      const topDataByPvalue = sortedByPvalue.slice(0, n);

      // Then, sort the selected top data by geneRatio (ascending)
      // With a reversed y-axis, the highest gene ratio will appear at the top.
      const topData = [...topDataByPvalue].sort(
        (a, b) => b.geneRatio - a.geneRatio,
      );

      // Common arrays for both plots
      const plotDescriptions = topData.map((item) => item.Description);
      const geneRatios = topData.map((item) => item.geneRatio);
      const pValues = topData.map((item) => item.pvalue);
      const minP = Math.min(...pValues);
      const maxP = Math.max(...pValues);
      const markerColors = topData.map((item) => {
        const norm = maxP === minP ? 0 : (item.pvalue - minP) / (maxP - minP);
        return interpolateColor(norm, '#39F2AE', '#6E04FE');
      });

      // Define height mapping for consistency.
      const heightMapping: Record<number, number> = {
        10: 400,
        20: 700,
        50: 1000,
      };
      const height = heightMapping[n] || 400;

      if (filterData.plot === 'Dotplot') {
        // For the dotplot, use scatter markers.
        const markerSizes = topData.map((item) => 5 + item.count * 0.8);
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Plot Container */}
            <div ref={plotRef}>
              <Plot
                data={[
                  {
                    x: geneRatios,
                    y: plotDescriptions,
                    type: 'scatter',
                    mode: 'markers',
                    marker: { size: markerSizes, color: markerColors },
                    name: 'Gene Ratio',
                  },
                ]}
                layout={{
                  width: 1000,
                  height,
                  showlegend: false,
                  xaxis: { title: 'Gene Ratio' },
                  yaxis: { title: '', autorange: 'reversed' },
                  margin: { l: 600, r: 50, t: 50, b: 50 },
                }}
                config={commonConfig}
              />
            </div>
            {/* Custom Legends Container */}
            <div style={{ marginLeft: '20px', alignSelf: 'center' }}>
              <h3>Counts Legend</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    width:
                      5 + Math.min(...topData.map((item) => item.count)) * 0.8,
                    height:
                      5 + Math.min(...topData.map((item) => item.count)) * 0.8,
                    borderRadius: '50%',
                    backgroundColor: 'black',
                    marginRight: '5px',
                  }}
                />
                <span>{Math.min(...topData.map((item) => item.count))}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    width:
                      5 +
                      Math.round(
                        (Math.min(...topData.map((item) => item.count)) +
                          Math.max(...topData.map((item) => item.count))) /
                          2,
                      ) *
                        0.8,
                    height:
                      5 +
                      Math.round(
                        (Math.min(...topData.map((item) => item.count)) +
                          Math.max(...topData.map((item) => item.count))) /
                          2,
                      ) *
                        0.8,
                    borderRadius: '50%',
                    backgroundColor: 'black',
                    marginRight: '5px',
                  }}
                />
                <span>
                  {Math.round(
                    (Math.min(...topData.map((item) => item.count)) +
                      Math.max(...topData.map((item) => item.count))) /
                      2,
                  )}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    width:
                      5 + Math.max(...topData.map((item) => item.count)) * 0.8,
                    height:
                      5 + Math.max(...topData.map((item) => item.count)) * 0.8,
                    borderRadius: '50%',
                    backgroundColor: 'black',
                    marginRight: '5px',
                  }}
                />
                <span>{Math.max(...topData.map((item) => item.count))}</span>
              </div>
              <h3>p‑value Legend</h3>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>
                  {minP.toPrecision(3)}
                </span>
                <div
                  style={{
                    width: '100px',
                    height: '20px',
                    background: `linear-gradient(to right, #39F2AE, #6E04FE)`,
                  }}
                />
                <span style={{ marginLeft: '5px' }}>{maxP.toPrecision(3)}</span>
              </div>
            </div>
          </div>
        );
      } else if (filterData.plot === 'Barplot') {
        // For the barplot, use horizontal bars with gene sets on the y-axis.
        // We add a legend below the plot to explain the color coding.
        return (
          <div>
            <div ref={plotRef}>
              <Plot
                data={[
                  {
                    x: geneRatios, // bar lengths on x-axis
                    y: plotDescriptions, // gene sets on y-axis
                    type: 'bar',
                    orientation: 'h', // horizontal bars
                    marker: {
                      color: markerColors, // same color mapping based on p‑values
                    },
                    name: 'Gene Ratio',
                  },
                ]}
                layout={{
                  width: 1000,
                  height,
                  showlegend: false,
                  xaxis: { title: 'Gene Ratio' },
                  yaxis: { title: '', autorange: 'reversed' },
                  margin: { l: 600, r: 50, t: 50, b: 50 },
                }}
                config={commonConfig}
              />
            </div>
            {/* Legend placed below the barplot */}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <h3>p‑value Legend</h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {minP.toPrecision(3)}
                </span>
                <div
                  style={{
                    width: '100px',
                    height: '20px',
                    background: `linear-gradient(to right, #6E04FE, #217867)`,
                  }}
                />
                <span style={{ marginLeft: '5px' }}>{maxP.toPrecision(3)}</span>
              </div>
            </div>
          </div>
        );
      } else {
        return <h1>Plot type not recognised</h1>;
      }
    }
    return null;
  };
  console.log(searchResults)
  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          {GSEALoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} />
            </div>
          ) : (
            <Form.Item label="Select comparison" colon={false}>
              <Select
                value={filterData.comparison}
                style={{ width: '100%' }}
                onChange={(value) => handleChange(value, 'comparison')}
                placeholder="Select an option"
              >
                {dataComparison?.data.map((item: any, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Title level={5}>Plot parameters</Title>
          <Form.Item label="Plot" colon={false}>
            <Select
              value={filterData.plot}
              defaultValue={filterData.plot}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'plot')}
            >
              <Option value="Dotplot">Dotplot</Option>
              <Option value="Barplot">Barplot</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Geneset" colon={false}>
            <Select
              defaultValue={filterData.geneset}
              value={filterData.geneset}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'geneset')}
            >
              <Option value="Gene Ontology">Gene Ontology</Option>
              <Option value="Biocarta">Biocarta</Option>
              <Option value="Hallmarks">Hallmarks</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Direction" colon={false}>
            <Select
              defaultValue={filterData.direction}
              value={filterData.direction}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'direction')}
            >
              <Option value="All">All</Option>
              <Option value="Activated">Activated</Option>
              <Option value="Supressed">Supressed</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Number of gene sets to plot" colon={false}>
            <Select
              defaultValue={filterData.nset}
              value={filterData.nset}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'nset')}
            >
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
            </Select>
          </Form.Item>
        </div>
        {/* Plot Section */}
        <div className="flex-1 flex flex-col items-center p-4">
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-2xl font-semibold mb-5"></h1>
            <div className="flex mr-8 relative">
              <button
                className="text-onex-purple-80 mr-2"
                onClick={() =>
                  DownloadImage(plotRef, `GSEA_${filterData.plot}`)
                }
              >
                {GSEALoading ? (
                  <div>
                    <BarLoader width={50} color="#39F2AE" />
                  </div>
                ) : (
                  'Download'
                )}
              </button>
              <button onClick={() => setIsModalOpen(true)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                    stroke="#374548"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <Modal
                open={isModalOpen}
                title="GSEA"
                description="This is the GSEA description."
                onClose={() => setIsModalOpen(false)}
              />
              <div id="modal-root"></div>
            </div>
          </div>
          {GSEALoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} />
            </div>
          ) : (
            <div className="w-full text-center">{GseaPlot()}</div>
          )}
        </div>
      </div>
      <ToastContainer />
      <DataTable
        columns={columns}
        data={searchResults}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default GSEA;
