import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { GetConfig } from '../../services/ConfigApi';
import useLocalStorage from '../../hooks/useLocalStorage';
import EditableSampleName from './EditSampleName';
import { ClipLoader } from 'react-spinners';
import { FiTrash2 } from 'react-icons/fi';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';

interface Params extends Record<string, string | undefined> {
  projectName?: string;
}

interface Sample {
  fileName: string;
  sampleName: string;
  groupName?: string;
  color?: string;
  associatedFiles?: string[]; // Only for FASTQ data
}

interface SamplesToBeConfiguredProps {
  selectedSamples: Sample[];
  setSelectedSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  samples: Sample[];
  setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  configuredSamples: Sample[];
  setConfiguredSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
  handleMoveToExistingGroup: (samples: Sample[]) => void;
}

const SamplesToBeConfigured: React.FC<SamplesToBeConfiguredProps> = ({
  selectedSamples,
  setSelectedSamples,
  samples,
  setSamples,
  configuredSamples,
  setConfiguredSamples,
  handleMoveToExistingGroup,
}) => {
  const { projectname } = useParams<Params>();
  const [storedSamples, setStoredSamples] = useLocalStorage<Sample[]>(`${projectname}-samples`, []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // Error state for duplicate sample name

 const nonConfiguredSamples = useMemo(() => {
   const configuredFileNames = new Set(
     configuredSamples.map((sample) => sample.fileName),
   );
   return samples.filter((sample) => !configuredFileNames.has(sample.fileName));
 }, [samples,configuredSamples]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        if (projectname) {
          setLoading(true); // Start loading
          const response = await GetConfig(projectname); // Ensure projectname is defined
          const projectsData = response.data;
          console.log(projectsData);

          const updatedSamples: Sample[] = [];

          if (typeof projectsData === 'object' && !Array.isArray(projectsData)) {
            Object.keys(projectsData).forEach((sampleName, index) => {
              const associatedFiles = projectsData[sampleName];
              updatedSamples.push({
                fileName: sampleName,
                sampleName: `S ${index + 1}`,
                associatedFiles,
              });
            });
          } else {
            projectsData.forEach((fileName: string, index: number) => {
              updatedSamples.push({
                fileName,
                sampleName: `S ${index + 1}`,
              });
            });
          }

          setSamples(updatedSamples);
          localStorage.setItem(`${projectname}-samples`, JSON.stringify(updatedSamples));
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (projectname) {
      const storedSamples = localStorage.getItem(`${projectname}-samples`);
      const configuredSamples = localStorage.getItem(`${projectname}-configured-samples`);
      if ((!storedSamples || storedSamples === '[]') && (!configuredSamples || configuredSamples === '[]')) {
        fetchConfig();
      } else {
        setSamples(JSON.parse(storedSamples || '[]'));
        setLoading(false); // Stop loading if data is already in localStorage
      }
    }
  }, [projectname, setSamples]);

  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedSamples(samples);
    } else {
      setSelectedSamples([]);
    }
  };

  const handleSelectSample = (sample: Sample, index: number, shiftKey: boolean) => {
    let newSelectedSamples = [...selectedSamples];
    const isSelected = newSelectedSamples.some(s => s.fileName === sample.fileName);

    if (shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const range = samples.slice(start, end + 1);

      range.forEach(r => {
        const rangeIndex = newSelectedSamples.findIndex(s => s.fileName === r.fileName);
        if (isSelected) {
          if (rangeIndex !== -1) {
            newSelectedSamples = newSelectedSamples.filter(s => s.fileName !== r.fileName);
          }
        } else {
          if (rangeIndex === -1) {
            newSelectedSamples.push(r);
          }
        }
      });
    } else {
      if (isSelected) {
        newSelectedSamples = newSelectedSamples.filter(s => s.fileName !== sample.fileName);
      } else {
        newSelectedSamples.push(sample);
      }
      setLastSelectedIndex(index);
    }

    setSelectedSamples(newSelectedSamples);
  };

  const isSelected = (sample: Sample) => selectedSamples.some(s => s.fileName === sample.fileName);

  const handleEditSampleName = (index: number, newSampleName: string) => {
    const isDuplicate = samples.some((sample, i) => i !== index && sample.sampleName === newSampleName);

    if (isDuplicate) {
      setError(`Sample name "${newSampleName}" is already taken. Please choose a different name.`);
      return; // Prevent the update
    } else {
      setError(null); // Clear error
    }

    const updatedSamples = samples.map((sample, i) =>
      i === index ? { ...sample, sampleName: newSampleName } : sample
    );
    setSamples(updatedSamples);
    setStoredSamples(updatedSamples);
  };

  const handleDeleteSample = (fileName: string) => {
    const updatedSamples = samples.filter(sample => sample.fileName !== fileName);
    setSamples(updatedSamples);
    localStorage.setItem(`${projectname}-samples`, JSON.stringify(updatedSamples));
  };


  return (
    <div className="mb-4">
      <h2 className="mb-2 font-semibold">Samples to be Configured</h2>
      <Paper className="p-2">
        <TableContainer style={{ maxHeight: '570px' }}>
          {loading ? (
            <div className="flex items-center justify-center min-h-screen">
              <ClipLoader size={100} color={'#123abc'} loading={loading} />
            </div>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ borderBottom: '1px solid black' }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedSamples.length === samples.length}
                      onChange={handleSelectAll}
                    />
                    <span className="font-semibold text-gray-700">
                      File Name
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="font-semibold text-gray-700">
                      Sample Name
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="font-semibold text-gray-700">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonConfiguredSamples.length > 0 ? (
                  nonConfiguredSamples.map((sample, index) => (
                    <TableRow key={index} className="hover:bg-gray-100">
                      <TableCell padding="checkbox">
                        <Box
                          display="flex"
                          alignItems="center"
                          className="text-neutral-500"
                        >
                          <Checkbox
                            checked={isSelected(sample)}
                            onChange={(event) =>
                              handleSelectSample(
                                sample,
                                index,
                                (event.nativeEvent as MouseEvent).shiftKey,
                              )
                            }
                          />
                          <span>{sample.fileName}</span>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <EditableSampleName
                          index={index}
                          sampleName={sample.sampleName}
                          onEditSampleName={handleEditSampleName}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <IconButton
                            onClick={() => handleMoveToExistingGroup([sample])}
                            sx={{
                              color: 'white',
                              backgroundColor: 'blue',
                              fontSize: '1.5rem',
                              padding: 0,
                            }}
                          >
                            <SwapHorizontalCircleIcon
                              fontSize="inherit"
                              titleAccess="Move to Existing Group"
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => handleDeleteSample(sample.fileName)}
                            sx={{ color: 'red', fontSize: '1.5rem' }}
                          >
                            <FiTrash2 size={16} title="Delete Sample" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className="text-center text-gray-500"
                    >
                      No samples to configure
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {error && (
          <Box mt={2} className="text-sm text-red-500">
            {error}
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default SamplesToBeConfigured;
