import { useMemo, useState } from 'react';
import { TabButtons } from '../../../pages/ViewAnalysis/TabButtons';
import CellTypeDeconvolution from './CellTypeDeconvolution';
import { useParams } from 'react-router-dom';
import Survival from './survival';
import MultiOmics from './MultiOmics';

const advancedAnalysisTabs = [
  'CellType deconvolution',
  'Survival',
  'Multi-omics',
];
type TabName = (typeof advancedAnalysisTabs)[number];

const AdvancedAnalysis = () => {
  const { projectname } = useParams<{ projectname: string }>();

  const [selectedTab, setSelectedTab] = useState<TabName>(
    'CellType deconvolution',
  );
  const componentMap: Record<TabName, JSX.Element> = useMemo(
    () => ({
      'CellType deconvolution': <CellTypeDeconvolution />,
      Survival: <Survival />,
      'Multi-omics': <MultiOmics />,
    }),
    [],
  );

  return (
    <div>
      <TabButtons
        analysisTabs={advancedAnalysisTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isChild={true}
      />

      <div className="mt-10">{componentMap[selectedTab]}</div>
    </div>
  );
};

export default AdvancedAnalysis;
