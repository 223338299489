import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Checkbox, FormControlLabel, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Swal from 'sweetalert2';
import { SendBooking } from "../../services/emailing";
import { useProfile } from "../../contexts/ProfileContext";
import CircularProgress from '@mui/material/CircularProgress';

function Booking(): JSX.Element {
  const navigate = useNavigate();
  const { profile } = useProfile();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setName(`${profile.first_name || ""} ${profile.last_name || ""}`.trim());
      setEmail(profile.email || "");
      setPhone(profile.phone_number || "");
    }
  }, [profile]);

  const validateForm = (): boolean => {
    if (!name || !email || !date || !message) {
      Swal.fire({
        title: "Error!",
        text: "Please fill out all required fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }

    const emailPattern = /\S+@\S+\.\S+/;
    if (!emailPattern.test(email)) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }

    const phonePattern = /^[0-9]*$/;
    if (!phonePattern.test(phone)) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid phone number containing only digits.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("date", date);
    formData.append("message", message);
    formData.append("acknowledgement", agreedToTerms.toString());


    try {
      await SendBooking(formData);
      navigate('/');
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to book the session. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    setName("");
    setEmail("");
    setPhone("");
    setDate("");
    setMessage("");
  };

  return (
    <div className="relative w-11/12 p-8 mx-auto bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-4">
        <Button
          startIcon={<ArrowBackIcon sx={{ mt: '-2px' }} />}
          onClick={() => navigate('/')}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            color: '#424242',
            fontSize: '20px',
            mt: '-4px',
            cursor: 'pointer',
            '&:hover': {
              color: '#424242',
              backgroundColor: 'transparent',
            },
          }}
        >
          Book a Session
        </Button>
        <button 
  className="px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg hover:bg-blue-50" 
  onClick={handleDiscard}
>
  Reset
</button>

      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            fullWidth
            required
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            fullWidth
            required
          />
          <TextField
            label="Phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="92 123 456 7890"
            fullWidth
            inputProps={{ maxLength: 15 }} // Optional: set max length
          />
          <TextField
            label="Date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </div>

        <div className="mb-6">
          <TextField
            label="Describe a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Describe a message"
            multiline
            rows={4}
            fullWidth
            required
          />
        </div>
        <div className="mb-6">
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
                color="primary"
                required
              />
            }
            label={
              <span>
                I agree to receive communications from Nexco and to the{" "}
                <Link href="https://nexco.ch/privacy" target="_blank" rel="noopener">
                  Privacy Policy
                </Link>
                .
              </span>
            }
          />
        </div>
        <div className="flex items-center justify-end mt-8">
          <button
            type="submit"
            disabled={loading}
            className={`text-white font-bold py-2 px-4 rounded ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-teal-700 hover:bg-teal-800"
            }`}
          >
            {loading ? (
              <div className="flex items-center">
                <CircularProgress size={20} color="inherit" />
                <span className="ml-2">Booking...</span>
              </div>
            ) : (
              "Book a Session"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Booking;
