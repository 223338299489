import { apiClient } from './apiClient';

export const uploadImageApi = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return apiClient.post('/upload/profile-picture', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
export const deleteProfilePictureApi = () => {
    return apiClient.delete('/profile-picture');
  };
export const getProfilePictureApi = () => {
    return apiClient.get('/profile-picture', {
        responseType: 'blob',
    });
};
export const deleteAccountApi = () => {
    return apiClient.delete('/delete-account');
  };

export const updateProfileApi = (profileData: any) => {
    return apiClient.post('/update-profile', profileData);
};
export const getProfileApi = () => {
    return apiClient.get('/profile');
};
export const getProfileByEmailApi = (email: string) => {
    return apiClient.get(`/email_profile`, {
        params: { email },
        responseType: 'blob' // Ensuring that the image data is correctly handled
    });
};
