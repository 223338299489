import React, { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2';
import {
  getUrlHeaderApi,
  getFtpMetadata,
  getFolderMetadata,
} from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from '@mui/material';
import { SiAmazons3 } from 'react-icons/si';
import { FaCloudUploadAlt, FaFolder } from 'react-icons/fa';
import FtpModal from './FtpModal';
import { GetUserSubscription } from '../../services/subscriptionApi';
import './scrollbar.css';
import { useEnvironment } from '../../utils/EnvContext';
import { GeoCode } from '../../types/upload';
type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
  isUploadFailed?: boolean;
};

type FtpMetadataRequest = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
};

type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
  isUploadFailed?: boolean;
};

type SelectedFile = {
  file: File;
  isUploadFailed?: boolean;
};
interface AdditionalInputsProps {
  projectName: string;
  experimentType: string;
  uploading: boolean;
  fileUploadComplete: boolean;
  geoCode: GeoCode;
  setGeoCode: (geoCode: GeoCode) => void;
  geoCodes: GeoCode[];
  setGeoCodes: (geoCodes: (prevGeoCodes: GeoCode[]) => GeoCode[]) => void;
  fileUrl: string;
  setFileUrl: (fileUrl: string) => void;
  selectedFiles: SelectedFile[];
  setSelectedFolderPath: (folderPath: string) => void;
  setSelectedFiles: (files: SelectedFile[]) => void;
  SelectedURLfile: SelectedURLfile[];
  setSelectedURLfile: React.Dispatch<React.SetStateAction<SelectedURLfile[]>>;
  ftpDetails: FtpDetails[];
  setFtpDetails: React.Dispatch<React.SetStateAction<FtpDetails[]>>;
  clearError: (field: string) => void;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const serviceOptions = [
  {
    name: 'Folder',
    icon: <FaFolder size={24} />,
    color: 'text-green-600',
    tooltip: 'Upload a folder containing the files.',
  },
  {
    name: 'FTP',
    icon: <FaCloudUploadAlt size={24} />,
    color: 'text-green-600',
    tooltip: 'Ensure your FTP server link is accessible.',
  },
  {
    name: 'Amazon',
    icon: <SiAmazons3 size={24} />,
    color: 'text-green-600',
    tooltip: 'Make sure the file is public on Amazon S3.',
  },
];

const AdditionalInputs: React.FC<AdditionalInputsProps> = ({
  projectName,
  experimentType,
  uploading,
  fileUploadComplete,
  geoCode,
  setGeoCode,
  geoCodes,
  setGeoCodes,
  fileUrl,
  setFileUrl,
  selectedFiles,
  setSelectedFolderPath,
  setSelectedFiles,
  SelectedURLfile,
  ftpDetails,
  setFtpDetails,
  setSelectedURLfile,
  clearError,
  setErrors,
}) => {
  const { environmentName } = useEnvironment();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<string>(
    environmentName === 'local' ? 'Folder' : 'FTP',
  );
  const [isFtpModalOpen, setIsFtpModalOpen] = useState<boolean>(false);
  const [hostname, setHostname] = useState<string>('');
  const [subscriptionType, setSubscriptionType] = useState<string | null>(null);

  useEffect(() => {
    // Fetch the user's subscription status
    const fetchSubscription = async () => {
      const response = await GetUserSubscription();
      const subscription = response?.data?.item_price_name || 'Free'; // Default to 'Free' if undefined
      setSubscriptionType(subscription);
    };

    environmentName && environmentName !== 'local' && fetchSubscription();
  }, []);
  const handleGeoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeoCode({ geocode: event.target.value, isUploadFailed: false });
  };

  const handleFileUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileUrl(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission or other default behavior
      if (event.currentTarget.name === 'geoCode') {
        handleGeoCodeSubmit(); // Submit the geo code
      } else {
        handleFileUrlSubmit(); // Submit the file URL
      }
    }
  };

  const handleServiceChange = (service: string) => {
    setSelectedService(service);
  };
  const removeGeoCode = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation(); // Stop the event from propagating to other handlers
    setGeoCodes((prevGeoCodes) => prevGeoCodes.filter((_, i) => i !== index));
  };

  const removeFtpDetail = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setFtpDetails((prevDetails) => prevDetails.filter((_, i) => i !== index));
  };

  const removeFile = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const removeURLfile = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = [...SelectedURLfile];
    newFiles.splice(index, 1);
    setSelectedURLfile(newFiles);
  };

  const handleGeoCodeSubmit = () => {
    if (
      selectedFiles.length > 0 ||
      SelectedURLfile.length > 0 ||
      ftpDetails.length > 0
    ) {
      setErrors({
        selectedFilesOrURLs:
          'You can only upload one type: File, GEO code, AWS file url, or FTP.',
      });
      return; // Prevent submission
    }

    if (geoCode) {
      setGeoCodes((prevGeoCodes: GeoCode[]) => [...prevGeoCodes, geoCode]);
      setGeoCode({ geocode: '', isUploadFailed: false });
      clearError('selectedFilesOrURLs');
    }
  };

  const handleFileUrlSubmit = async () => {
    //called when fetching files and metadata from URL or a folder...
    if (
      selectedFiles.length > 0 ||
      geoCodes.length > 0 ||
      ftpDetails.length > 0
    ) {
      setErrors({
        selectedFilesOrURLs:
          environmentName === 'local'
            ? 'You can only upload one type: File, Folder, URL, or GEO code.'
            : 'You can only upload one type: File, URL, GEO code, or FTP.',
      });
      return; // Prevent submission
    }

    if (loading) return;

    setLoading(true);

    try {
      let response;
      if (isURL(fileUrl)) {
        response = await getUrlHeaderApi(fileUrl);
      } else {
        setSelectedURLfile([]); // Clear the selected URL files
        setSelectedFolderPath(fileUrl);
        response = await getFolderMetadata(fileUrl);
      }

      console.log(response);
      if (response.data) {
        if (response.data.hostname) {
          setHostname(response.data.hostname); // Store hostname in the state
          setIsFtpModalOpen(true); // Open the FTP modal
        } else {
          const { filename, file_size } = response.data;

          const newFile: SelectedURLfile = {
            filename,
            filesize: parseFloat((file_size / (1024 * 1024)).toFixed(2)), // Convert bytes to MB and format to 2 decimal places
            url: fileUrl,
          };

          setSelectedURLfile((prevFiles: SelectedURLfile[]) => [
            ...prevFiles,
            newFile,
          ]);
          setFileUrl('');
        }
      } else if (response.files) {
        const newFiles = response.files.map(
          (file: { file_name: string; file_size: number }) => ({
            filename: file.file_name,
            filesize: parseFloat((file.file_size / (1024 * 1024)).toFixed(2)), // Convert bytes to MB and format to 2 decimal places
            url: fileUrl,
          }),
        );

        setSelectedURLfile((prevFiles: SelectedURLfile[]) => [
          ...prevFiles,
          ...newFiles,
        ]);
        setFileUrl('');
      }
    } catch (error) {
      console.error('Error fetching file metadata:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch file metadata. Please check the URL and try again.',
      });
    } finally {
      setLoading(false);
      clearError('selectedFilesOrURLs');
    }
  };

  const isURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/|sftp:\\/\\/)', 'i');
    return pattern.test(str);
  };
  const handleFtpSubmit = async (ftpDetails: FtpMetadataRequest) => {
    if (
      selectedFiles.length > 0 ||
      SelectedURLfile.length > 0 ||
      geoCodes.length > 0
    ) {
      setErrors({
        selectedFilesOrURLs:
          environmentName === 'local'
            ? 'You can only upload one type: File, Folder, URL, or GEO code.'
            : 'You can only upload one type: File, URL, GEO code, or FTP.',
      });
      return; // Prevent submission
    }

    try {
      const metadata = await getFtpMetadata(ftpDetails);

      let newFtpDetails: FtpDetails[] = [];

      if (Array.isArray(metadata.file_data)) {
        // Handle multiple files
        newFtpDetails = metadata.file_data.map((file: any) => ({
          hostname: ftpDetails.hostname,
          sftp_username: ftpDetails.sftp_username,
          sftp_password: ftpDetails.sftp_password,
          sftp_remote_path: `${ftpDetails.sftp_remote_path}/${file.file_name}`,
          file_name: file.file_name,
          file_size: parseFloat(file.file_size.toFixed(2)), // Convert bytes to MB and format to 2 decimal places
        }));
      } else {
        // Handle a single file
        newFtpDetails = [
          {
            hostname: ftpDetails.hostname,
            sftp_username: ftpDetails.sftp_username,
            sftp_password: ftpDetails.sftp_password,
            sftp_remote_path: ftpDetails.sftp_remote_path,
            file_name: metadata.file_name,
            file_size: parseFloat(metadata.file_size.toFixed(2)), // Convert bytes to MB and format to 2 decimal places
          },
        ];
      }

      // Update the FTP details in the main form without triggering the error validation
      setFtpDetails((prevDetails) => [...prevDetails, ...newFtpDetails]);

      setIsFtpModalOpen(false); // Close the modal
      clearError('selectedFilesOrURLs'); // Clear error after adding FTP details
    } catch (error) {
      console.error('Error fetching FTP metadata:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to retrieve FTP metadata. Please check your details and try again.',
      });
    }
  };

  return (
    <div>
      {(experimentType == 'RNAseq' || experimentType == '') && (
        <div>
          <p className="pb-4 mb-5 text-center text-gray-500">OR</p>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="relative">
              {environmentName !== 'local' &&
              (subscriptionType === 'Free' || !subscriptionType) ? (
                <input
                  type="text"
                  placeholder="Upgrade your subscription to enable file download using Geocode."
                  className="w-full px-4 py-3 text-gray-400 bg-gray-100 border border-gray-300 rounded cursor-not-allowed focus:outline-none"
                  disabled
                />
              ) : (
                <input
                  type="text"
                  name="geoCode"
                  placeholder="Enter GEO code e.g. GSE182465"
                  className="w-full px-4 py-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
                  value={geoCode.geocode}
                  onChange={handleGeoCodeChange}
                  onKeyPress={handleKeyPress}
                  disabled={uploading}
                />
              )}
              {experimentType == 'RNAseq' && geoCode && (
                <CheckCircleIcon
                  onClick={handleGeoCodeSubmit}
                  className="absolute text-green-500 transform -translate-y-1/2 cursor-pointer right-2 top-1/2"
                />
              )}
            </div>
            <div className="relative">
              <input
                type="text"
                name="fileUrl"
                placeholder={
                  environmentName === 'local'
                    ? 'Insert Folder path, FTP Link, or Amazon S3 bucket URL'
                    : 'Insert FTP Link, or Amazon S3 bucket URL'
                }
                className="w-full px-4 py-3 border border-gray-300 rounded pr-36 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
                value={fileUrl}
                onChange={handleFileUrlChange}
                onKeyPress={handleKeyPress}
                disabled={loading || uploading}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-3">
                {serviceOptions.map(
                  ({ name, icon, color, tooltip }) =>
                    !(environmentName !== 'local' && name === 'Folder') && (
                      <Tooltip title={tooltip} arrow key={name}>
                        <div
                          onClick={() => handleServiceChange(name)}
                          className={`cursor-pointer ${selectedService === name ? color : 'text-gray-400'}`}
                        >
                          {icon}
                        </div>
                      </Tooltip>
                    ),
                )}
              </div>
              {loading && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <ClipLoader size={20} color={'#123abc'} loading={loading} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {(selectedFiles.length > 0 ||
        SelectedURLfile.length > 0 ||
        geoCodes.length > 0 ||
        ftpDetails.length > 0) && (
        <div className="px-2 pt-2 mt-4 space-y-2 overflow-y-auto border-2 border-gray-300 rounded-xl max-h-56">
          <ul className="list-none ">
            {geoCodes.map((code, index) => (
              <li
                key={index}
                className={`flex items-center justify-between p-2 mb-2 border-none rounded-lg ${fileUploadComplete ? (code.isUploadFailed ? 'bg-red-200' : 'bg-gray-100') : 'bg-gray-100'}`}
              >
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div>
                      <span className="font-semibold text-gray-800">
                        {code.geocode}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={(event) => removeGeoCode(index, event)}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}

            {selectedFiles.map((file, index) => (
              <li
                key={index}
                className={`flex items-center justify-between p-2 mb-2 border-none rounded-lg ${fileUploadComplete ? (file.isUploadFailed ? 'bg-red-200' : 'bg-gray-100') : 'bg-gray-100'}`}
              >
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div>
                      <span className="font-semibold text-gray-800">
                        {file.file.name}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-800">
                        {(file.file.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                    </div>
                  </div>
                </div>

                <button onClick={(event) => removeFile(index, event)}>
                  <ClearIcon
                    style={{ fontSize: '1.3rem' }}
                    className={`p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700 `}
                  />
                </button>
              </li>
            ))}

            {SelectedURLfile.map((file, index) => (
              <li
                key={index}
                className={`flex items-center justify-between p-2 mb-2 border-none rounded-lg ${fileUploadComplete ? (file.isUploadFailed ? 'bg-red-200' : 'bg-gray-100') : 'bg-gray-100'}`}
              >
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div>
                      <span className={`font-semibold text-gray-800`}>
                        {file.filename}
                      </span>
                    </div>
                    <div>
                      <span className={`text-gray-800`}>
                        {file.filesize.toFixed(2)} MB
                      </span>
                    </div>
                  </div>
                </div>

                <button onClick={(event) => removeURLfile(index, event)}>
                  <ClearIcon
                    style={{ fontSize: '1.3rem' }}
                    className={`p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700 `}
                  />
                </button>
              </li>
            ))}

            {ftpDetails.map((ftp, index) => (
              <li
                key={index}
                className={`flex items-center justify-between p-2 mb-2 border-none rounded-lg ${fileUploadComplete ? (ftp.isUploadFailed ? 'bg-red-200' : 'bg-gray-100') : 'bg-gray-100'}`}
              >
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div>
                      <span className="font-semibold text-gray-800">
                        {ftp.file_name}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-800">{ftp.file_size} MB</span>
                    </div>
                  </div>
                </div>

                <button onClick={(event) => removeFtpDetail(index, event)}>
                  <ClearIcon
                    style={{ fontSize: '1.3rem' }}
                    className={`p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700 `}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {experimentType == 'BRBseq' ? (
        <p className="mb-6 text-gray-500">
          Allowed file extensions: txt. Use a fast and stable internet
          connection.{' '}
          <a
            href="/umi.counts.sampleIDs.txt"
            download
            className="text-teal-700"
          >
            Download Count Table Example.
          </a>
        </p>
      ) : (
        <p className="mb-6 text-gray-500">
          Allowed file extensions: fastq.gz, fq.gz, csv. Use a fast and stable
          internet connection.{' '}
          <a href="/raw_counts.csv" download className="text-teal-700">
            Download Count Table Example.
          </a>
        </p>
      )}

      <FtpModal
        isOpen={isFtpModalOpen}
        onClose={() => setIsFtpModalOpen(false)}
        onSubmit={handleFtpSubmit}
        hostname={hostname} // Pass hostname to the modal
      />
    </div>
  );
};

export default AdditionalInputs;
