import React from 'react';
import Plot from 'react-plotly.js';
import { commonConfig } from '../../../../utils/plotConfig';
import {
  ICompData,
  IfilterDataDifferential,
} from '../../../../interface/vnexInterfaces';

interface MAPlotProps {
  maData: ICompData;
  filterData: IfilterDataDifferential;
  title?: string;
}

const MAPlot: React.FC<MAPlotProps> = ({ maData, filterData, title }) => {
  const { avg, lfc, hgnc_symbol, Pval, FDR } = maData;
  // Choose the appropriate p-value measure
  const selectedPval = filterData.use === 'FDR' ? FDR : (Pval ?? 'Pval');
  const foldChangeThreshold = filterData.fold_change_threshold ?? 2.0;
  const pvalThreshold = filterData.statistical_significance_threshold ?? 0.05;

  // Prepare groups for up-regulated, down-regulated, and the rest
  const upRegulated = {
    a: [] as number[],
    fc: [] as number[],
    text: [] as string[],
  };
  const downRegulated = {
    a: [] as number[],
    fc: [] as number[],
    text: [] as string[],
  };
  const rest = { a: [] as number[], fc: [] as number[], text: [] as string[] };

  avg.forEach((a, index) => {
    const fc = lfc[index];
    const p = selectedPval[index];
    // Only consider points that meet the significance threshold for color highlighting
    if (p < pvalThreshold) {
      if (fc > foldChangeThreshold) {
        upRegulated.a.push(a);
        upRegulated.fc.push(fc);
        upRegulated.text.push(hgnc_symbol[index]);
      } else if (fc < -foldChangeThreshold) {
        downRegulated.a.push(a);
        downRegulated.fc.push(fc);
        downRegulated.text.push(hgnc_symbol[index]);
      } else {
        rest.a.push(a);
        rest.fc.push(fc);
        rest.text.push(hgnc_symbol[index]);
      }
    } else {
      rest.a.push(a);
      rest.fc.push(fc);
      rest.text.push(hgnc_symbol[index]);
    }
  });

  return (
    <Plot
      data={[
        {
          x: upRegulated.a,
          y: upRegulated.fc,
          mode: 'markers',
          marker: {
            size: 8,
            color: '#39F2AE',
          },
          text: upRegulated.text,
          hoverinfo: 'text',
          type: 'scatter',
          name: 'Up regulated',
        },
        {
          x: downRegulated.a,
          y: downRegulated.fc,
          mode: 'markers',
          marker: {
            size: 8,
            color: '#6E04FE',
          },
          text: downRegulated.text,
          hoverinfo: 'text',
          type: 'scatter',
          name: 'Down regulated',
        },
        {
          x: rest.a,
          y: rest.fc,
          mode: 'markers',
          marker: {
            size: 5,
            color: 'lightgrey',
          },
          text: rest.text,
          hoverinfo: 'text',
          type: 'scatter',
          name: 'Rest',
        },
      ]}
      layout={{
        width: 1000,
        height: 700,
        title: title || '',
        xaxis: {
          title: 'A (log2 average)',
        },
        yaxis: {
          title: 'M (log2 foldChange)',
        },
      }}
      config={commonConfig}
    />
  );
};

export default MAPlot;
