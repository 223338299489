import Swal from 'sweetalert2';
import { AxiosProgressEvent } from 'axios';
import {
  checkProjectNameApi,
  SubmitFtp,
  uploadFilesApi,
  uploadUrlsApi,
} from '../../services/projectApi';
import { SelectChangeEvent } from '@mui/material/Select';
import exp from 'constants';
import { GeoCode } from '../../types/upload';


interface FtpMetadataRequest {
  hostname: string
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
}

export const handleFileSelect = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>,
) => {
  if (event.target.files) {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles: File[]) => [...prevFiles, ...files]);
  }
};

export const handleExperimentChange = (
  event: SelectChangeEvent,
  setExperiment: React.Dispatch<React.SetStateAction<string>>,
) => {
  setExperiment(event.target.value as string);
};

export const handleGenomeChange = (
  event: SelectChangeEvent,
  setGenome: React.Dispatch<React.SetStateAction<string>>,
) => {
  setGenome(event.target.value as string);
};

export const handleProjectNameChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setProjectName: React.Dispatch<React.SetStateAction<string>>,
) => {
  setProjectName(event.target.value);
};

export const checkName = async (
  experiment: string,
  genome: string,
  projectName: string,
  setProjectName?: React.Dispatch<React.SetStateAction<string>>,
): Promise<boolean> => {
  try {
    const response = await checkProjectNameApi({
      pipeline: experiment,
      genome: genome,
      project_name: projectName,
    });

    if (response.data.project_name) {
      if (setProjectName) {
        setProjectName(response.data.project_name);
      }
      return true;
    } else {
      if (response.data.message.includes('already taken')) {
        Swal.fire({
          icon: 'error',
          title: 'Project Name Already Taken',
          text: 'The project name is already taken. Please choose a different name or use the existing project.',
        });
        return false;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
        });
        return false;
      }
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Error checking project name',
    });
    return false;
  }
};


export const uploadFiles = async (
  projectName: string,
  selectedFiles: File[],
  experiment: string,
  retry: boolean,
  setUploading: React.Dispatch<React.SetStateAction<boolean>>,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
): Promise<{
  success: boolean;
  message: string;
  failedFiles?: any[];
  successFiles?: any[];
  allFiles?: any[];
}> => {
  if (selectedFiles.length === 0) {
    return { success: false, message: 'No files selected for upload.' };
  }

  setUploading(true);
  setProgress(0);

  // Perform total file size calculation inline
  const totalFileSizeMB = Math.round(
    selectedFiles.reduce((acc, file) => acc + ('file' in file ? file.size : file.size), 0) / (1024 * 1024), // Convert to MB
  );

  try {
    const response = await uploadFilesApi(
      projectName,
      selectedFiles,
      totalFileSizeMB,
      experiment,
      retry,
      (progressEvent: AxiosProgressEvent) => {
        const total = progressEvent.total ?? 1;
        const loaded = progressEvent.loaded;
        setProgress(Math.round((loaded / total) * 100));
      },
    );
    if (response.data) {
      if (
        !response.data.failed_files ||
        response.data.failed_files.length === 0
      ) {
        return { success: true, message: 'URLs uploaded successfully.' };
      } else {
        return {
          success: false,
          message: response.data.message || 'Failed to upload URLs.',
          failedFiles: response.data.failed_files,
          successFiles: response.data.success_files,
          allFiles: response.data.success_files,
        };
      }
    } else {
      return {
        success: false,
        message: response.data.message || 'Failed to upload URLs.',
      };
    }
  } catch (error) {
    const response = (error as any).response;
    if (response && response.status === 422) {
      return {
        success: false,
        message:
          response.data?.detail ||
          'Unsuccessful upload due to server error (422).',
      };
    }
    return { success: false, message: 'Error uploading files.' };
  } finally {
    setUploading(false);
  }
};

export const uploadUrls = async (
  projectName: string,
  experiment: string,
  selectedUrls: { url: string; filesize: number }[],
): Promise<{
  success: boolean;
  message: string;
  failedFiles?: any[];
  allFiles?: any[];
}> => {
  if (selectedUrls.length === 0) {
    return { success: false, message: 'No URLs selected for upload.' };
  }

  // Perform total URL file size calculation inline
  const totalUrlSizeMB = Math.round(
    selectedUrls.reduce((acc, file) => acc + file.filesize, 0),
  );

  try {
    const urlArray = selectedUrls.map((file) => file.url);

    const response = await uploadUrlsApi(urlArray, totalUrlSizeMB, projectName, experiment);

    if (response.data) {
      if (
        !response.data.failed_files ||
        response.data.failed_files.length === 0
      ) {
        return { success: true, message: 'URLs uploaded successfully.' };
      } else {
        return {
          success: false,
          message: response.data.message || 'Failed to upload URLs.',
          failedFiles: response.data.failed_files,
          allFiles: response.data.successful_files,
        };
      }
    } else {
      return {
        success: false,
        message: response.data.message || 'Failed to upload URLs.',
      };
    }
  } catch (error) {
    const response = (error as any).response;
    if (response && response.status === 422) {
      return {
        success: false,
        message: 'Unsuccessful upload due to server error (422).',
      };
    }
    return { success: false, message: 'Error uploading URLs.' };
  }
};

export const uploadFtpFiles = async (sftpDetails: FtpMetadataRequest[], projectName: string, combinedFileSize: number, experiment: string, retry:boolean): Promise<{
  success: boolean;
  message: string;
  failedFiles?: any[];
  allFiles?: any[];
}> => {
  try {
    const response = await SubmitFtp(sftpDetails, projectName, combinedFileSize, experiment, retry);
    if (response.data) {
      if (
        !response.data.failed_files ||
        response.data.failed_files.length === 0
      ) {
        return { success: true, message: 'FTP files uploaded successfully.' };
      } else {
        return {
          success: false,
          message: response.data.message || 'Failed to upload FTP files.',
          failedFiles: response.data.failed_files,
          allFiles: response.data.success_files,
        };
      }
    } else {
      return {
        success: false,
        message: response.data.message || 'Failed to upload FTP files.',
      };
    }
  } catch (error) {
    const response = (error as any).response;
    if (response && response.status === 422) {
      return {
        success: false,
        message: 'Unsuccessful upload due to server error (422).',
      };
    }
    return { success: false, message: 'Error uploading FTP files.' };
  }

}



export const handleFileDrop = (
  event: DragEvent,
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>,
) => {
  event.preventDefault();
  const files = Array.from(event.dataTransfer?.files || []);
  setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
};

export const handleDiscard = (
  setExperiment: React.Dispatch<React.SetStateAction<string>>,
  setGenome: React.Dispatch<React.SetStateAction<string>>,
  setProjectName: React.Dispatch<React.SetStateAction<string>>,
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>,
  setProgress: React.Dispatch<React.SetStateAction<number>>,
  setGeoCodes: React.Dispatch<React.SetStateAction<GeoCode[]>>,
  setFileUrl: React.Dispatch<React.SetStateAction<string>>,
) => {
  setExperiment('');
  setGenome('');
  setProjectName('');
  setSelectedFiles([]);
  setProgress(0);
  setGeoCodes([]);
  setFileUrl('');
};
