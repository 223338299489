import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import {
  ICompData,
  IfilterDataDifferential,
} from '../../../../interface/vnexInterfaces';
import { commonConfig } from '../../../../utils/plotConfig';

interface VolcanoPlotProps {
  compData: ICompData;
  filterData: IfilterDataDifferential;
  title?: string;
}

const VolcanoPlot: React.FC<VolcanoPlotProps> = ({
  compData,
  filterData,
  title = 'Volcano Plot',
}) => {
  // Destructure necessary data from compData
  const { foldChange, lfc, Pval, lPval, FDR, lFDR, hgnc_symbol } = compData;

  // Determine which p-value to use based on filterData.use
  const selectedPval = filterData.use === 'FDR' ? FDR : Pval;

  // Define thresholds, providing default values if null
  const foldChangeThreshold = filterData.fold_change_threshold ?? 2.0;
  const pvalThreshold = filterData.statistical_significance_threshold ?? 0.05;

  const selectedLogPval = filterData.use === 'FDR' ? lFDR : lPval;

  // Memoize the computation for performance optimization
  const plotData = useMemo(() => {
    const upRegulated = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      hoverinfo: 'text',
      mode: 'markers' as const,
      marker: { size: 8, color: '#39F2AE' },
      type: 'scatter' as const,
      name: 'Up regulated',
    };

    const downRegulated = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      hoverinfo: 'text',
      mode: 'markers' as const,
      marker: { size: 8, color: '#6E04FE'},
      type: 'scatter' as const,
      name: 'Down regulated',
    };

    const notSignificant = {
      x: [] as number[],
      y: [] as number[],
      text: [] as string[],
      hoverinfo: 'text',
      mode: 'markers' as const,
      marker: { size: 5, color: 'lightgrey' },
      type: 'scatter' as const,
      name: 'Rest',
    };

    lfc.forEach((logfc, index) => {
      const pval = selectedPval[index];
      const geneSymbol = hgnc_symbol[index] || 'N/A';
      const fc = foldChange[index];

      if (isNaN(pval)) return;

      const logPval = selectedLogPval[index] || 0;

      if (Math.abs(fc) >= foldChangeThreshold && pval <= pvalThreshold) {
        if (fc > 0) {
          // Upregulated
          upRegulated.x!.push(logfc);
          upRegulated.y!.push(logPval);
          upRegulated.text!.push(geneSymbol);
        } else if (fc < 0) {
          // Downregulated
          downRegulated.x!.push(logfc);
          downRegulated.y!.push(logPval);
          downRegulated.text!.push(geneSymbol);
        }
      } else {
        // Not significant
        notSignificant.x!.push(logfc);
        notSignificant.y!.push(logPval);
        notSignificant.text!.push(geneSymbol);
      }
    });

    return [upRegulated, downRegulated, notSignificant] as Plotly.Data[];
  }, [lfc, selectedLogPval, hgnc_symbol, foldChangeThreshold, pvalThreshold]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot
        data={plotData}
        layout={{
          width: 1000,
          height: 700,
          title: title,
          showlegend: true,
          xaxis: {
            title: 'Fold Change (log2)',
          },
          yaxis: {
            title: 'P-value (-Log10)',
          },
          hovermode: 'closest',
        }}
        config={commonConfig}
      />
    </div>
  );
};

export default VolcanoPlot;
