import React, { useEffect, useState, useRef } from 'react';
interface EditableSampleNameProps {
    index: number;
    sampleName: string;
    onEditSampleName: (index: number, newSampleName: string) => void;
  }
  
  const EditableSampleName: React.FC<EditableSampleNameProps> = ({ index, sampleName, onEditSampleName }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(sampleName);
    const inputRef = useRef<HTMLInputElement>(null);
  
    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isEditing]);
  
    const handleDoubleClick = () => {
      setIsEditing(true);
    };
  
    const handleBlur = () => {
      setIsEditing(false);
      onEditSampleName(index, value);
    };
  
    return isEditing ? (
      <input
    ref={inputRef}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    onBlur={handleBlur}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        handleBlur();
      }
    }}
    className="p-1 border border-gray-500 rounded"
    style={{ width: '90px', height: '21px', borderWidth: '0.5px' }}
  />
  
  
    ) : (
      <span onDoubleClick={handleDoubleClick} className="text-neutral-500">
        {sampleName}
      </span>
    );
  };

export default EditableSampleName