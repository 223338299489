import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import UploadForm from './UploadForm';
import FileUploadSection from './FileUploadSection';
import AdditionalInputs from './AdditionalInputs';
import Swal from 'sweetalert2';
import {
  getFromGeoApi,
  resumeURLDownload,
  resumeSftpDownload,
  SubmitFtp,
  copyLocalFolder,
} from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import {
  handleExperimentChange,
  handleGenomeChange,
  handleProjectNameChange,
  checkName,
  uploadFiles,
  handleFileDrop,
  handleDiscard,
  uploadUrls,
  uploadFtpFiles,
} from './uploadFunctions';
import {
  GetLocalSubscription,
  GetUserSubscription,
} from '../../services/subscriptionApi';
import { getProfileApi } from '../../services/ProfileApi';
import { useNavigate } from 'react-router-dom';
import UploadProgress from './UploadProgress';
import { useProjectRefresh } from '../../contexts/ProjectRefreshContext';
import { getSubscriptionLimits } from '../../utils/subscriptionLimits'; // Import the function to get limits
import { rejects } from 'assert';
import { useWebSocket } from '../../hooks/useWebSocket';
import { useEnvironment } from '../../utils/EnvContext';
import { env } from 'process';
import NotAuthorized from '../../components/NotAuthorized';
import withAuthorization from '../../components/WithAuthorization';

type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
  isUploadFailed?: boolean;
};
type SelectedFile = {
  file: File;
  isUploadFailed?: boolean;
};
type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
  isUploadFailed?: boolean;
};

const Upload: React.FC = () => {
  const [experiment, setExperiment] = useState<string>('');
  const [genome, setGenome] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [geoCode, setGeoCode] = useState<string>('');
  const [geoCodes, setGeoCodes] = useState<string[]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploadComplete, setFileUploadComplete] = useState<boolean>(false);
  const [combinedFileSizeMBs, setCombinedFileSizeMBs] = useState<number>(0);
  const [resumeFilesList, setResumeFilesList] = useState<string[]>([]);
  const [selectedFolderPath, setSelectedFolderPath] = useState<string>('');
  const { environmentName } = useEnvironment();
  const [isLocalLisenceValid, setIsLocalLisenceValid] = useState<boolean>();

  const [allFilesUploadFailed, setAllFilesUploadFailed] =
    useState<boolean>(false);

  const [resumeDownload, setResumeDownload] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    projectName?: string;
    experiment?: string;
    genome?: string;
    selectedFilesOrURLs?: string;
  }>({});
  const [SelectedURLfile, setSelectedURLfile] = useState<SelectedURLfile[]>([]);
  const [ftpDetails, setFtpDetails] = useState<FtpDetails[]>([]); // New state for SFTP details
  const navigate = useNavigate();
  const [urlUploading, setUrlUploading] = useState<boolean>(false);
  const { data, isConnected } = useWebSocket(
    'ws://localhost:8000/ws/status',
    uploading || urlUploading || ftpDetails.length > 0,
  );
  const uploadPromises: any[] = [];

  let uploadFailedFiles: any[] | undefined;
  const [buttonText, setButtonText] = useState<string>('Create');
  const triggerRefresh = useProjectRefresh();
  const clearError = (field: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  useEffect(() => {
    const checkProjectLimit = async () => {
      setLoading(true);

      const [subscriptionResponse, profileResponse] = await Promise.all([
        GetUserSubscription(),
        getProfileApi(),
      ]);
      const subscription = subscriptionResponse.data;
      const profile = profileResponse.data;

      const limits = getSubscriptionLimits(
        subscription?.item_price_name || 'Free',
      ); // Fetch limits from the utility

      if (profile.num_projects >= limits.maxProjects) {
        Swal.fire({
          title: 'Project Limit Reached',
          text: 'You have reached your monthly project limit. Would you like to upgrade your plan?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Upgrade',
          cancelButtonText: 'No, Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/account');
          } else {
            navigate('/');
          }
        });
        return;
      }

      setLoading(false);
    };

    const checkIsLicenseValid = async () => {
      if (environmentName === 'local') {
        try {
          const response = await GetLocalSubscription();
          if (response.data.message === 'License is valid.') {
            setIsLocalLisenceValid(true);
          } else {
            setIsLocalLisenceValid(false);
          }
        } catch (err) {
          setIsLocalLisenceValid(false);
        }
      }
    };
    environmentName && environmentName !== 'local'
      ? checkProjectLimit()
      : checkIsLicenseValid();
  }, [navigate, environmentName]);

  const checkStorageLimit = async (
    selectedFiles: SelectedFile[],
    SelectedURLfile: SelectedURLfile[],
    ftpDetails: FtpDetails[],
  ): Promise<boolean> => {
    if (environmentName && environmentName !== 'local') {
      setLoading(true);
      const totalSelectedFileSizeMB = Math.round(
        selectedFiles.reduce((acc, file) => acc + file.file.size, 0) /
          (1024 * 1024), // Converting to MB
      );
      const totalFileSizeMB = totalSelectedFileSizeMB;
      const totalURLFileSizeMB = SelectedURLfile.reduce(
        (acc, file) => acc + file.filesize,
        0,
      );
      const totalFtpFileSizeMB = ftpDetails.reduce(
        (acc, ftp) => acc + ftp.file_size,
        0,
      );
      const combinedTotalFileSizeMB =
        totalFileSizeMB + totalURLFileSizeMB + totalFtpFileSizeMB;

      const [subscriptionResponse, profileResponse] = await Promise.all([
        GetUserSubscription(),
        getProfileApi(),
      ]);
      const subscription = subscriptionResponse.data;
      const profile = profileResponse.data;

      const limits = getSubscriptionLimits(
        subscription?.item_price_name || 'Free',
      ); // Fetch limits from the utility
      const maxStorageMB = limits.maxDataGB * 1024; // Convert GB to MB

      const totalDataMB = profile.total_data;

      if (totalDataMB + combinedTotalFileSizeMB > maxStorageMB) {
        const exceededAmountMB =
          totalDataMB + combinedTotalFileSizeMB - maxStorageMB;

        const exceededAmountGB = (exceededAmountMB / 1024).toFixed(2);

        Swal.fire({
          title: 'Storage Limit Exceeded',
          text: `You have exceeded your data usage by ${exceededAmountGB} GB. Please upgrade your plan to proceed.`,
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Upgrade Plan',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/account');
          }
        });
        setLoading(false);
        return false; // Exceeded storage
      }
      setLoading(false);
      return true; // Within storage limit
    } else {
      return true;
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      SelectedURLfile.length > 0 ||
      geoCodes.length > 0 ||
      ftpDetails.length > 0
    ) {
      setErrors({
        selectedFilesOrURLs:
          'You can only upload one type: File, URL, GEO code, or FTP.',
      });
      return; // Prevent submission
    }

    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => ({
        file,
        isUploadFailed: false,
      }));

      const pattern = /^[a-zA-Z0-9_.-]+$/;

      const invalidFiles = newFiles.filter(
        (file) => !pattern.test(file.file.name),
      );
      if (invalidFiles.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid File Names',
          text: `The following files have invalid names: ${invalidFiles.map((file) => file.file.name).join(', ')}. Only alphanumerical characters, '-', '.' and '_' are allowed.`,
          width: '350px',
          padding: '1em',
          position: 'top-end',
        });
        return;
      }

      const newSelectedFiles = [
        ...selectedFiles,
        ...newFiles.map((file) => ({ ...file, isUploadFailed: false })),
      ];
      if (environmentName && environmentName !== 'local') {
        const withinLimit = await checkStorageLimit(
          selectedFiles,
          SelectedURLfile,
          ftpDetails,
        );
        if (!withinLimit) {
          return;
        }
      }
      setSelectedFiles(newSelectedFiles);
      clearError('selectedFilesOrURLs');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: {
      projectName?: string;
      experiment?: string;
      genome?: string;
      selectedFilesOrURLs?: string;
    } = {};
    if (!projectName) newErrors.projectName = 'Project name is required';
    if (!experiment) newErrors.experiment = 'Experiment is required';
    if (!genome) newErrors.genome = 'Genome is required';
    if (
      selectedFiles.length === 0 &&
      SelectedURLfile.length === 0 &&
      geoCodes.length === 0 &&
      ftpDetails.length === 0
    ) {
      newErrors.selectedFilesOrURLs =
        'Either rawdata file, GEO code or Link must be uploaded before proceeding';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (environmentName && environmentName !== 'local') {
      const withinLimit = await checkStorageLimit(
        selectedFiles,
        SelectedURLfile,
        ftpDetails,
      );

      if (!withinLimit) {
        return; // Exit if storage limit is exceeded
      }
    }

    try {
      setLoading(true);
      setButtonText('Uploading');
      setUploading(true);

      const isProjectCreated = await checkName(
        experiment,
        genome,
        projectName,
        setProjectName,
      );

      if (isProjectCreated) {
        uploadFailedFiles = [];
        // Add file upload logic here without showing the popup if the file size is under 20 MB
        if (geoCodes.length > 0) {
          uploadPromises.push(
            getFromGeoApi(geoCodes, projectName).then((response) => {
              if (response.status !== 200 && response.status !== 202) {
                throw new Error(
                  response.data?.detail ||
                    `Failed to upload GEO data for code: ${geoCodes}`,
                );
              }
            }),
          );
        } else if (SelectedURLfile.length > 0) {
          uploadPromises.push(UploadSelectedURLDetails(SelectedURLfile));
        } else if (selectedFiles.length > 0) {
          const filesToUpload = selectedFiles.map(({ file }) => file);
          const fileUploadPromise = uploadFiles(
            projectName,
            // experiment,
            filesToUpload,
            setUploading,
            setProgress,
          ).then((result) => {
            if (!result.success) {
              if (!result.success) {
                if (result.failedFiles) {
                  uploadFailedFiles = result.failedFiles;
                }

                throw new Error(
                  `${result.message}. Failed files: ${result.failedFiles}`,
                );
              }
            }
          });

          uploadPromises.push(fileUploadPromise);
        } else if (ftpDetails.length > 0) {
          uploadPromises.push(UploadFtpDetails(ftpDetails));
        }

        HandlePromisesResponse();
      } else {
        setLoading(false);
        setButtonText('Create');
        setUploading(false);
      }
    } catch (error) {
      setLoading(false);
      setButtonText('Create');
      setUploading(false);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the project creation process',
        width: '500px',
        padding: '1.5em',
      });
      setLoading(false);
      setButtonText('Create');
      setUploading(false);
    }
  };

  const HandlePromisesResponse = async () => {
    try {
      const results = await Promise.allSettled(uploadPromises);

      const failedUploads = results.filter(
        (result) => result.status === 'rejected',
      );

      if (
        failedUploads.length > 0 ||
        (uploadFailedFiles && uploadFailedFiles.length > 0)
      ) {
        const failedFilesHtml = `<div  style="text-align: left;">The following files failed to upload:<br><br><div><ul style="text-align: left;list-style-type: disc;">
            <li>${uploadFailedFiles?.map((file) => (typeof file === 'string' ? file : file.file)).join('</li><li>')} 
            </ul></div><br></div>`;

        Swal.fire({
          icon: 'info',
          title: `<span>Project is created successfully!</span>`,
          width: '450px',
          padding: '1em',
          position: 'center',
          html: failedFilesHtml,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {});

        //only implemented for selectedUrlFiles ....\
        console.log('Upload Failed Files', uploadFailedFiles);

        if (SelectedURLfile.length > 0) {
          const newSelectedFiles = SelectedURLfile.map((file) => {
            if (
              uploadFailedFiles?.some(
                (failedFile) => failedFile === file.filename,
              )
            ) {
              return { ...file, isUploadFailed: true };
            }
            return file;
          });
          //May need to add this implementation for amazon s3 bucket or onedrive files downloading case...
          // const resumeDownloadFiles = SelectedURLfile.map((file) => {
          //   if (
          //     uploadFailedFiles?.some(
          //       (failedFile) => failedFile.file === file.filename,
          //     )
          //   ) {
          //     return `${projectName}_drive.google.com_${file.filename}`;
          //   }
          //   return null;
          // }).filter((file): file is string => file !== null);

          // console.log('resumeable files list is :', resumeDownloadFiles);

          // setResumeFilesList(
          //   resumeDownloadFiles.length > 0 ? resumeDownloadFiles : [],
          // );
          setSelectedURLfile(newSelectedFiles);
          setButtonText('Go Configure');
          setFileUploadComplete(true);
        } else if (selectedFiles.length > 0) {
          const newSelectedFiles = selectedFiles.map((file) => {
            if (
              uploadFailedFiles?.some(
                (failedFile) => failedFile === file.file.name,
              )
            ) {
              return { ...file, isUploadFailed: true };
            }
            return file;
          });
          setSelectedFiles(newSelectedFiles);

          setButtonText('Go Configure');
          setFileUploadComplete(true);
        } else if (ftpDetails.length > 0) {
          const newFtpDetails = ftpDetails.map((file) => {
            if (
              uploadFailedFiles?.some(
                (failedFile) => failedFile.file === file.file_name,
              )
            ) {
              return { ...file, isUploadFailed: true };
            }
            return file;
          });
          const resumeDownloadFiles = ftpDetails
            .map((file) => {
              if (
                uploadFailedFiles?.some(
                  (failedFile) => failedFile.file === file.file_name,
                )
              ) {
                return `${projectName}_${file.hostname}_${file.file_name}`;
              }
              return null;
            })
            .filter((file): file is string => file !== null);

          setFtpDetails(newFtpDetails);
          console.log('resumeable files list is :', resumeDownloadFiles);
          setResumeFilesList(
            resumeDownloadFiles.length > 0 ? resumeDownloadFiles : [],
          );
        }
      } else {
        setProgress(100);
        Swal.fire({
          title: `<span>Your project is created successfully, and files are uploaded!</span>`,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            triggerRefresh();
            setButtonText('Go Configure');
            setSelectedURLfile([]);
            setSelectedFiles([]);
            setGeoCodes([]);
            setFtpDetails([]);
            setExperiment('');
            setGenome('');
            setFileUploadComplete(true);
            navigate(`/configure/${projectName}`);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the files data uploading process',
        width: '500px',
        padding: '1.5em',
      });
    } finally {
      setLoading(false);
      setUploading(false);
      setUrlUploading(false);
    }
  };
  const UploadFtpDetails = async (updatedFtpDetails: FtpDetails[]) => {
    const combinedFtpFileSizeMB = updatedFtpDetails.reduce(
      (acc, ftp) => acc + ftp.file_size,
      0,
    );
    setCombinedFileSizeMBs(combinedFtpFileSizeMB);

    return await uploadFtpFiles(
      updatedFtpDetails,
      projectName,
      combinedFtpFileSizeMB,
    ).then((result) => {
      if (!result.success) {
        if (
          result.message ==
          'Some files could not be downloaded, project data has been deleted.'
        ) {
          setResumeDownload(true);
          if (result.failedFiles) {
            uploadFailedFiles = result.failedFiles;
          }

          throw new Error(
            `${result.message}. Failed files: ${result.failedFiles}`,
          );
        }
      }
    });
  };

  const UploadSelectedURLDetails = async (
    updatedSelectedURLfile: SelectedURLfile[],
  ) => {
    const combinedURLFileSizeMB = updatedSelectedURLfile.reduce(
      (acc, ftp) => acc + ftp.filesize,
      0,
    );

    setCombinedFileSizeMBs(combinedURLFileSizeMB);
    setUrlUploading(true);

    if (selectedFolderPath && environmentName === 'local') {
      return await copyLocalFolder(selectedFolderPath, projectName)
        .then((response) => {
          console.log('response from folder copy api: ', response);

          if (response.status !== 200) {
            throw new Error(
              `Failed to upload files data from folder: ${selectedFolderPath}`,
            );
          }
        })
        .catch((error) => {
          console.error('Error uploading Folder:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error occurred during the Folder upload process',
            width: '500px',
            padding: '1.5em',
          });
        })
        .finally(() => {
          setUrlUploading(false);
        });
    } else {
      return await uploadUrls(projectName, updatedSelectedURLfile)
        .then((result) => {
          if (!result.success) {
            if ((result.failedFiles?.length ?? 0) > 0) {
              if (result.failedFiles) {
                result.failedFiles.length === (result.allFiles?.length ?? 0)
                  ? setAllFilesUploadFailed(true)
                  : setAllFilesUploadFailed(false);

                uploadFailedFiles = result.allFiles
                  ?.filter((file) =>
                    result.failedFiles?.some(
                      (failedFile) => failedFile.url === file.url,
                    ),
                  )
                  .map((file) => file.file);
              }

              throw new Error(
                `${result.message}. Failed files: ${result.failedFiles}. All files: ${result.allFiles}`,
              );
            }
          }
        })
        .catch((error) => {
          console.error('Error uploading URL:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error occurred during the URL upload process',
            width: '500px',
            padding: '1.5em',
          });
        })
        .finally(() => {
          setUrlUploading(false);
        });
    }
  };

  const ResumeDownload = async () => {
    let allResumedSuccessfull = true;
    for (const file of resumeFilesList) {
      file &&
        resumeSftpDownload(file)
          .then((response) => {
            const { message } = response.data;
            setResumeDownload(false);
          })
          .catch((error) => {
            allResumedSuccessfull = false;
            console.error('Error resuming download:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error occurred during the download resumption process',
              width: '500px',
              padding: '1.5em',
            });
          });
    }

    //calling get-from-sftp api to get the files again after resume...

    if (allResumedSuccessfull) {
      Swal.fire({
        icon: 'info',
        title: 'Download Resumed',
        width: '500px',
        padding: '1.5em',
      });
      if (ftpDetails.length > 0) {
        //logic to update the failed files in the UI
        const updatedFtpDetails = ftpDetails.filter((file) => {
          if (
            resumeFilesList?.includes(
              `${projectName}_${file.hostname}_${file.file_name}`,
            )
          ) {
            return true;
          }
          return false;
        });

        uploadPromises.push(UploadFtpDetails(updatedFtpDetails));
      }
    }
  };

  useEffect(() => {
    if (uploading || urlUploading || ftpDetails.length > 0) {
      if (
        data?.project === projectName &&
        (SelectedURLfile.length > 0 || ftpDetails.length > 0)
      ) {
        const totalBytes = combinedFileSizeMBs * 1024 * 1024;
        console.log('Total file size in Bytes:', totalBytes); //total filesize in bytes
        console.log('Downloaded Bytes:', data.bytes_downloaded); // Bytes downloaded
        const percernt_ = (data.bytes_downloaded / totalBytes) * 100;
        const progress_percent =
          percernt_ < 1 ? percernt_.toFixed(2) : percernt_.toFixed(0);
        setProgress(Number(progress_percent));
      }
    }
  }, [data]);

  useEffect(() => {
    progress === 100 && setButtonText('Processing');
  }, [progress]);

  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      const filesArray = event.dataTransfer
        ? Array.from(event.dataTransfer.files)
        : []; // Convert to array

      handleFileDrop(event, (files) => {
        const updatedFiles = filesArray.map((file: any) => ({
          file,
          isUploadFailed: false,
        }));
        setSelectedFiles(updatedFiles);
      });
    };

    if (dropZone) {
      dropZone.addEventListener('dragover', (event) => {
        event.preventDefault();
      });

      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', (event) => {
          event.preventDefault();
        });

        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  useEffect(() => {
    if (experiment === 'BRBseq') {
      setSelectedURLfile([]);
      setFtpDetails([]);
    }
  }, [experiment]);

  return (
    <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md max-w-7xl">
      <div className="flex items-center justify-between mb-6">
        <button
          className="flex items-center text-lg font-bold text-gray-700"
          onClick={() => {
            navigate('/');
          }}
        >
          <ArrowBackIcon className="mt-0.5 mr-2" /> Create Project
        </button>
        <button
          className="px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg hover:bg-blue-50"
          onClick={() =>
            handleDiscard(
              setExperiment,
              setGenome,
              setProjectName,
              (files) =>
                setSelectedFiles(
                  (files as any).map((file: any) => ({
                    ...file,
                    isUploadFailed: false,
                  })),
                ),
              setProgress,
              setGeoCodes,
              setFileUrl,
            )
          }
        >
          Reset
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <UploadForm
          projectName={projectName}
          experiment={experiment}
          genome={genome}
          handleProjectNameChange={(event) =>
            handleProjectNameChange(event, setProjectName)
          }
          handleExperimentChange={(event) =>
            handleExperimentChange(event, setExperiment)
          }
          handleGenomeChange={(event) => handleGenomeChange(event, setGenome)}
          clearError={clearError}
          errors={errors}
        />

        <p className="mb-6 text-gray-500">
          Other parameters (such as the data being single or paired-end or if
          the sequencing was stranded) will be automatically detected.
        </p>

        <h6 className="mb-2 text-xl font-semibold">Upload Data</h6>

        <div id="drop-zone">
          {loading && (
            <div className="flex items-center justify-center">
              <ClipLoader size={50} color={'#123abc'} loading={loading} />
            </div>
          )}
          {!loading && (
            <FileUploadSection
              uploading={uploading}
              handleFileSelect={handleFileSelect}
            />
          )}
        </div>

        <AdditionalInputs
          projectName={projectName}
          experimentType={experiment}
          uploading={uploading}
          fileUploadComplete={fileUploadComplete}
          geoCode={geoCode}
          setGeoCode={setGeoCode}
          geoCodes={geoCodes}
          setGeoCodes={setGeoCodes}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          selectedFiles={selectedFiles}
          setSelectedFolderPath={setSelectedFolderPath}
          setSelectedFiles={setSelectedFiles}
          SelectedURLfile={SelectedURLfile}
          setSelectedURLfile={setSelectedURLfile}
          ftpDetails={ftpDetails} // Pass ftpDetails
          setFtpDetails={setFtpDetails} // Pass setFtpDetails
          clearError={clearError}
          setErrors={setErrors}
        />
        {errors.selectedFilesOrURLs && (
          <p className="text-red-600">{errors.selectedFilesOrURLs}</p>
        )}
        {resumeDownload && (
          <button
            className="w-full px-4 py-2 mt-4 text-white bg-black rounded flex items-center justify-center"
            onClick={ResumeDownload}
          >
            <PlayCircleOutlineIcon className="mr-2" />
            Resume Download
          </button>
        )}
        <UploadProgress
          uploading={uploading || urlUploading || ftpDetails.length > 0} // Remove geoCodes.length > 0 if you don't want the progress bar for geo code submission
          progress={progress}
          fileType={
            urlUploading ? 'url' : ftpDetails.length > 0 ? 'ftp' : 'file'
          } // Remove 'geocode' as fileType if progress tracking is not needed for geo codes
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex justify-end w-full">
            <button
              type="submit"
              className={`px-4 py-2 text-white rounded ${uploading ? 'bg-gray-500 cursor-not-allowed' : 'bg-teal-700 hover:bg-teal-800'}`}
              disabled={uploading} // Disable button during upload
              onClick={() => {
                if (buttonText === 'Go Configure') {
                  navigate(`/configure/${projectName}`);
                }
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withAuthorization(Upload);
