import React, { useEffect, useState } from 'react';
import ProjectRow from './ProjectRow';
import { Project } from '../../interface/project';
import { Checkbox, Pagination } from '@mui/material';
interface ProjectTableProps {
  projects: Project[];
  selectedProjects: string[];
  handleDeleteProject: (name: string[]) => void; // Update the parameter type to string[]
  handleShareProject: (name: string) => void;
  handleSelectProject: (names: string[]) => void;
  handleNameChange: (oldName: string, newName: string) => void;
}



const ProjectTable: React.FC<ProjectTableProps> = ({
  projects,
  selectedProjects,
  handleDeleteProject,
  handleShareProject,
  handleSelectProject,
  handleNameChange, // Add this line
}) => {
  const [selected, setSelected] = useState<Set<string>>(
    new Set(selectedProjects),
  );
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setSelected(new Set(selectedProjects));
  }, [selectedProjects]);

  const handleSelectProjectRow = (name: string, isSelected: boolean, isShiftKey: boolean) => {
    const newSelected = new Set(selected);
    const projectIndex = projects.findIndex((project) => project.name === name);
  
    if (isShiftKey && lastSelectedIndex !== null) {
      const [start, end] =
        projectIndex > lastSelectedIndex
          ? [lastSelectedIndex, projectIndex]
          : [projectIndex, lastSelectedIndex];
  
      for (let i = start; i <= end; i++) {
        if (isSelected) {
          newSelected.add(projects[i].name);
        } else {
          newSelected.delete(projects[i].name);
        }
      }
    } else {
      if (isSelected) {
        newSelected.add(name);
      } else {
        newSelected.delete(name);
      }
      setLastSelectedIndex(projectIndex);
    }
  
    setSelected(newSelected);
    handleSelectProject(Array.from(newSelected)); // Update parent component's state
  };
  
  const handleSelectAll = () => {
    if (selected.size === projects.length) {
      setSelected(new Set());
      handleSelectProject([]); // Clear selection
    } else {
      const newSelected = new Set(projects.map((project) => project.name));
      setSelected(newSelected);
      handleSelectProject(Array.from(newSelected)); // Select all projects
    }
  };
  
  // Calculate pagination
  const indexOfLastProject = currentPage * rowsPerPage;
  const indexOfFirstProject = indexOfLastProject - rowsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(projects.length / rowsPerPage);

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-6 table-auto">
        <thead className="border-b border-black">
          <tr className="text-gray-700">
            <th className="w-1/4 py-3 text-left ">
              <Checkbox
                onChange={handleSelectAll}
                checked={selected.size === projects.length}
              />
              <span className="ml-2">Project Name</span>
            </th>
            <th className="w-1/5 px-6 py-3 text-left">Experiment</th>
            <th className="w-1/5 px-6 py-3 text-left">Genome</th>
            <th className="w-1/5 px-6 py-3 text-left">Date Added</th>
            <th className="w-1/5 px-6 py-3 text-left">Status</th>
            <th className="w-1/12 px-6 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project) => (
           <ProjectRow
           key={project.name}
           project={project}
           isSelected={selected.has(project.name)}
           handleSelectProject={handleSelectProjectRow}
           onNameChange={handleNameChange}
           handleDeleteProject={(name) => handleDeleteProject([name])} // Wrap single name in array
           handleShareProject={handleShareProject}
         />
         
          ))}
        </tbody>
      </table>
      <div className="flex justify-end">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        color="primary"
        variant="outlined"
        shape="rounded"
        size="large"
        showFirstButton 
        showLastButton 
      />
      </div>
    </div>
  );
};

export default ProjectTable;
