import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEuroSign,
  faDollarSign,
  faPoundSign,
  faYenSign,
} from '@fortawesome/free-solid-svg-icons';
import Details from './Details';
import pricingPlanDetails from './DetailsData';

interface Subscription {
  id: string;
  name: string;
  price: string;
  currency_code: string;
  features: { [key: string]: string };
}


interface PricingProps {
  subscriptions: Subscription[];
}

const LocalPricing: React.FC<PricingProps> = ({ subscriptions }) => {
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null); // State for tracking which plan is loading
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getCurrencyIcon = (currency_code: string) => {
    switch (currency_code) {
      case 'CHF':
      case 'EUR':
        return faEuroSign;
      case 'USD':
        return faDollarSign;
      case 'GBP':
        return faPoundSign;
      case 'JPY':
        return faYenSign;
      default:
        return faDollarSign; // default icon if currency is not matched
    }
  };

  const getTitleColor = (name: string) => {
    switch (name) {
      case 'Free':
        return 'text-gray-400';
      case 'Pro':
        return 'text-gray-700';
      case 'Pro+':
        return 'text-teal-500';
      case 'Premium':
        return 'text-orange-500';
      default:
        return 'text-gray-700';
    }
  };


  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">
        Subscription Plan
      </h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
        {subscriptions.map((plan) => {
          if (plan.name === 'Free') return null;
          const isCurrentPlan = plan.name === 'Pro';
          const showTrialButton = plan.name === 'Pro';
          const featuresOrder = [
            'Monthly projects',
            'Monthly data',
            'Computing speed',
            'Output',
            'Turnover time',
          ];
          const isPlanLoading = loadingPlanId === plan.id; // Check if this plan is the one being loaded
          return (
            <div
              key={plan.id}
              className="flex flex-col p-8 border border-gray-300 rounded-lg min-h-[380px]"
            >
              <div className="flex-grow">
                <h3
                  className={`mb-4 text-xl font-semibold ${getTitleColor(plan.name)}`}
                >
                  {plan.name}
                </h3>
                <p className="mb-4 text-4xl font-bold text-gray-700">
                  <FontAwesomeIcon
                    icon={getCurrencyIcon(plan.currency_code)}
                    className="mr-2"
                  />
                  {plan.price}{' '}
                  <span className="text-lg font-normal text-gray-500">
                    / month
                  </span>
                </p>
              </div>
              <div>
                <button
                  className="w-full px-6 py-3 mb-3 font-semibold text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
                  onClick={() => setIsModalOpen(true)}
                >
                  View Details
                </button>
                <button
                  className={`w-full px-6 py-3 font-semibold ${isCurrentPlan ? 'text-white bg-teal-700 rounded' : 'text-teal-700 bg-teal-700 rounded bg-opacity-10'}`}
                  disabled={true}
                >
                  {isCurrentPlan ? 'Current Plan' : `${plan.name} Plan`}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Details
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        services={pricingPlanDetails.services}
      />
    </div>
  );
};

export default LocalPricing;
