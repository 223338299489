import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteAccountApi } from '../../services/ProfileApi';
import { useAuth } from '../../auth/AuthContext';
import {useNavigate} from 'react-router-dom';

const MySwal = withReactContent(Swal);

const RemoveAccount: React.FC = () => {
    const navigate = useNavigate();
  const { logout } = useAuth();

  const redirect = (url: string) => {
    navigate(url);
  };

  const handleLogout = () => {
    logout();
    redirect('/login');
    window.location.reload();
  };

  const handleRemoveAccount = async () => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "This will remove all your existing projects and cannot be undone!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await deleteAccountApi();
        handleLogout()
      } catch (error) {
        console.error('Error deleting account:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an issue deleting your account. Please try again later.',
        });
      }
    }
  };

  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">
        Remove this Account
      </h2>
      <div className="flex items-center justify-between p-4 mb-8 border border-gray-300 rounded-lg">
        <div className="text-left text-gray-400">
          <div className="mb-2 text-xs font-semibold">
            Remove this Account
          </div>
          <p className="text-sm font-semibold text-gray-700">
            By removing this account, all associated data will be permanently
            deleted and cannot be restored.
          </p>
        </div>
        <button
          className="px-4 py-2 font-semibold text-white bg-orange-500 rounded"
          onClick={handleRemoveAccount}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default RemoveAccount;
