import { toast } from 'react-toastify';
import { IfilterData } from '../components/AnalysisPlots/Clustering';
import { apiClient } from './apiClient';
import { IfilterDataDifferential } from '../interface/vnexInterfaces';
import { IfilterDataListOverlap } from '../components/AnalysisPlots/BasicAnalysis/ListOverlap';
import { IfilterGeneList } from '../components/AnalysisPlots/BasicAnalysis/Gene';
import { IfilterGeneCorrelation } from '../components/AnalysisPlots/BasicAnalysis/GeneCorrelation';
import { IfilterGsea } from '../components/AnalysisPlots/BasicAnalysis/GSEA';

// Descriptive Screen API
export const GetSampleInfo = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return apiClient.get(`/project/${projectName}/descriptive-info`, {
      params: {
        current_user: email,
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Quality Control Data

export const QualityControlData = async (
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.get(`/quality-control/${project_name}`, {
      params: {
        current_user: email,
      },
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Clustering Screen Get Groups
export const ProjectGroups = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return await apiClient.get(`/project-groups`, {
      params: {
        project_name: projectName, // Include the project name
        current_user: email, // Include the current user email
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Clustering Screen API
export const Clustring = async (
  filterData: IfilterData,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/clustering/${project_name}`, {
      // plot: filterData.plot,
      current_user: email,
      // dimensions: filterData.dimensions,
      num_genes: filterData.num_genes,
      row_metric: filterData.row_metric,
      col_metric: filterData.col_metric,
      group_names: filterData.selectedGroups,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Basic Analysis > DifferentialExpression Screen

export const Comparison = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return await apiClient.get(`/meta-comparison`, {
      params: {
        project_name: projectName, // Include the project name
        current_user: email, // Include the current user email
      },
    });
  } catch (error: any) {
    console.error('Error fetching sample info:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetDifferentialExpression = async (
  filterData: IfilterDataDifferential,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/differencial-expression/${project_name}`, {
      comparison: filterData.comparison,
      current_user: email,
      plot: filterData.plot,
      genes_in_table: filterData.genes,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Get the PCA info for the DE genes
export const GetDiffExpPca = async (
  filterData: IfilterDataDifferential,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/differencial-expression-pca/${project_name}`, {
      comparison: filterData.comparison,
      current_user: email,
      fold_change_threshold: filterData.fold_change_threshold,
      statistical_significance_threshold:
        filterData.statistical_significance_threshold,
      use: filterData.use,
      plot: filterData.plot,
      genes_in_table: filterData.genes,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Get the PCA info for the DE genes
export const GetDiffExpHm = async (
  filterData: IfilterDataDifferential,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/differencial-expression-hm/${project_name}`, {
      comparison: filterData.comparison,
      current_user: email,
      fold_change_threshold: filterData.fold_change_threshold,
      statistical_significance_threshold:
        filterData.statistical_significance_threshold,
      use: filterData.use,
      plot: filterData.plot,
      genes_in_table: filterData.genes,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Basic Analysis > List Overlap Screen

export const ListOverlapAPI = async (
  filterData: IfilterDataListOverlap,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/prepare-upset-data/${project_name}`, {
      current_user: email,
      fold_change_threshold: filterData.fold_change_threshold,
      statistical_significance_threshold:
        filterData.statistical_significance_threshold,
      use: filterData.use,
      selected_comparisons: filterData.selectedGroups,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Basic Analysis > Gene Screen

export const GetGeneList = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return apiClient.get(`/get-genes/${projectName}`, {
      params: {
        current_user: email,
      },
    });
  } catch (error: any) {
    console.error('Error fetching Gene List:', error);
    const errorMessage =
      error.response?.data?.message || 'An error occurred while fetching data.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetGeneData = async (
  filterData: IfilterGeneList,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/gene-centric/${project_name}`, {
      current_user: email,
      gene: filterData.genes,
      scale: filterData.scale,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetGseaData = async (
  project_name: string,
  email: string,
  filterData: IfilterGsea,
): Promise<any> => {
  try {
    return apiClient.post(`/gsea/${project_name}`, {
      current_user: email,
      comparison: filterData.comparison,
      geneset: filterData.geneset,
      nset: filterData.nset,
      plot: filterData.plot,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const GetGeneCorrelationData = async (
  filterData: IfilterGeneCorrelation,
  email: string,
  project_name: string,
): Promise<any> => {
  try {
    return apiClient.post(`/gene-correlation/${project_name}`, {
      current_user: email,
      gene1: filterData.gene1,
      gene2: filterData.gene2,
      group: filterData.groups,
      scale: filterData.scale,
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const SharedWith = async (
  projectName: string,
  email: string,
): Promise<any> => {
  try {
    return await apiClient.get(`/get-shared-project`, {
      params: {
        project_name: projectName, // Include the project name
        second_owner: email, // Include the current user email
      },
    });
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
};
