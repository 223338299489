import React, { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
type SelectedURLfile = {
  filename: string;
  filesize: number;
  url: string;
};

type FtpMetadataRequest = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
};

type FtpDetails = {
  hostname: string;
  sftp_username: string;
  sftp_password: string;
  sftp_remote_path: string;
  file_name: string;
  file_size: number;
};

interface ModalAdditionalInputsProps {
  uploading: boolean;
  fileUrl: string;
  setFileUrl: (fileUrl: string) => void;
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  clearError: (field: string) => void;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const ModalAdditionalInputs: React.FC<ModalAdditionalInputsProps> = ({
  uploading,
  fileUrl,
  setFileUrl,
  selectedFiles,
  setSelectedFiles,
  clearError,
  setErrors,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div>
      {selectedFiles.length > 0 && (
        <div className="pb-4 my-5">
          <ul className="p-0 space-y-2 overflow-y-auto list-none max-h-56">
            {selectedFiles.map((file, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-2 mb-2 border border-gray-300 rounded-lg"
              >
                <div className="flex items-center">
                  <span className="p-2">
                    <i className="text-xl text-gray-800 fa-solid fi-sr-copy-alt"></i>
                  </span>
                  <div>
                    <div>
                      <span className="font-semibold text-gray-800">
                        {file.name}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-800">
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    const newFiles = [...selectedFiles];
                    newFiles.splice(index, 1);
                    setSelectedFiles(newFiles);
                  }}
                  className="p-0.5 text-xs ml-4 text-white bg-orange-500 rounded-full hover:bg-orange-700"
                >
                  <ClearIcon style={{ fontSize: '1.3rem' }} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <p className="mb-6 text-gray-500">
        Allowed file extensions: fastq.gz, fq.gz. Use a fast and stable internet
        connection.{' '}
      </p>
    </div>
  );
};

export default ModalAdditionalInputs;
