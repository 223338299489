// subscriptionLimits.ts
export const subscriptionLimits: { [key: string]: { maxDataGB: number; maxProjects: number } } = {
    'Free': {
      maxDataGB: 20,
      maxProjects: 3,
    },
    'Pro': {
      maxDataGB: 100,
      maxProjects: 10,
    },
    'Pro+': {
      maxDataGB: 500, 
      maxProjects: 30,
    },
    'Premium': {
      maxDataGB: 1000,
      maxProjects: 50,
    }
  };
  
  export const getSubscriptionLimits = (planName: string) => {
    return subscriptionLimits[planName] || subscriptionLimits['Free'];
  };
  