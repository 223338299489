import { useMemo, useState } from 'react';
import { TabButtons } from '../../../pages/ViewAnalysis/TabButtons';
import DifferentialExpression from './DifferentialExpression';
import Gene from './Gene';
import GeneCorrelation from './GeneCorrelation';
import GeneList from './GeneList';
import GSEA from './GSEA';
import ListOverlap from './ListOverlap';
import { useParams } from 'react-router-dom';

const basicAnalysisTabs = [
  'Differential Expression',
  'List overlap',
  'GSEA',
  'Gene',
  'Gene correlation',
  'GeneList',
];
type TabName = (typeof basicAnalysisTabs)[number];

const BasicAnalysis = () => {
  const { projectname } = useParams<{ projectname: string }>();

  const [selectedTab, setSelectedTab] = useState<TabName>(
    'Differential Expression',
  );
  const componentMap: Record<TabName, JSX.Element> = useMemo(
    () => ({
      'Differential Expression': (
        <DifferentialExpression projectName={projectname || ''} />
      ),
      'List overlap': <ListOverlap projectName={projectname || ''} />,
      GSEA: <GSEA projectName={projectname || ''} />,
      Gene: <Gene projectName={projectname || ''} />,
      'Gene correlation': <GeneCorrelation projectName={projectname || ''} />,
      GeneList: <GeneList />,
    }),
    [],
  );

  return (
    <div>
      <TabButtons
        analysisTabs={basicAnalysisTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isChild={true}
      />

      <div className="mt-10">{componentMap[selectedTab]}</div>
    </div>
  );
};

export default BasicAnalysis;
