import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLogApi } from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import { FaArrowLeft, FaRedo } from 'react-icons/fa';

interface Log {
  id: number;
  message: string;
  timestamp: string;
}

const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { projectname } = useParams<{ projectname: string }>();
  const navigate = useNavigate();

  const fetchLogs = async () => {
    try {
      if (projectname) {
        const logContent = await getLogApi(projectname);
        const parsedLogs = parseLogs(logContent.data);
        setLogs(parsedLogs);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs([]); // Ensure logs is an empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  const parseLogs = (logContent: string): Log[] => {
    // Split log content by new lines and map to Log objects
    return logContent.split('\n').map((log, index) => ({
      id: index,
      message: log,
      timestamp: new Date().toISOString(), // Example timestamp, replace with actual if available
    }));
  };

  useEffect(() => {
    fetchLogs();
  }, [projectname]);

  return (
    <div className="min-h-screen p-4 text-black bg-gray-200 rounded">
      <div className="flex items-center justify-between mb-4">
        {/* <button
          className="flex items-center mb-4 text-3xl font-bold text-gray-700 hover:text-gray-900"
          onClick={() => navigate('/')}
        >
          <FaArrowLeft className="mr-2" />
          {projectname || 'Logs'}
        </button> */}
        <h1 className= "flex items-center mb-4 text-3xl font-bold cursor-pointer" onClick={() => navigate('/')}> <FaArrowLeft className="mt-2 mr-2" /> {projectname || 'Logs'}</h1>
        <button
          className="flex items-center text-gray-800 hover:text-gray-950"
          onClick={() => fetchLogs()}
        >
          <FaRedo className="mr-2" />
          Reload
        </button>
      </div>
      {/* <h1 className="mb-4 text-3xl font-bold">{projectname || 'Logs'}</h1> */}
      <div className="p-4 bg-gray-300 rounded-lg shadow-lg">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-64">
            <ClipLoader size={50} color={"#123abc"} loading={isLoading} />
          </div>
        ) : logs.length > 0 ? (
          <ul>
            {logs.map((log) => (
              <li key={log.id} className="mb-2">
                <div className="flex items-center justify-between">
                  <span className="font-mono">{log.message}</span>
                  <span className="text-sm text-gray-500">{log.timestamp}</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No logs available.</p>
        )}
      </div>
    </div>
  );
};

export default LogsPage;
