// import { toast } from 'react-toastify';

// export const DownloadImage = async (plotRef: React.RefObject<any>) => {
//   const modebarButton = plotRef.current.querySelector(
//     '.modebar-btn[data-title="Download plot as a png"]',
//   );

//   if (modebarButton) {
//     modebarButton.click();
//     toast.success('Image downloaded successfully!', {
//       position: 'top-right',
//     });
//   } else {
//     const errorMessage =
//       'Before Downloading Plot Please Ensure Plotly is fully loaded.';
//     toast.error(errorMessage, {
//       position: 'top-right',
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//     });
//   }
// };

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';

export const DownloadBuiltImage = async (plotRef: React.RefObject<any>) => {
  try {
    if (plotRef.current) {
      // const plotNode = plotRef.current.querySelector('.heatMapBuiltInImage');
      // console.log('plotNode ', plotNode);
      if (plotRef.current) {
        const canvas = await html2canvas(plotRef.current, {
          scale: 2, // Increase the resolution
        });

        const imageData = canvas.toDataURL('image/jpeg');
        const pdf = new jsPDF({
          orientation: 'landscape', // Use 'portrait' if needed
          unit: 'px',
          format: [canvas.width, canvas.height],
        });

        pdf.addImage(imageData, 'JPEG', 0, 0, canvas.width, canvas.height);
        pdf.save('heatmap.pdf');

        toast.success('PDF downloaded successfully!', {
          position: 'top-right',
        });
      } else {
        console.error('Heatmap element not found.');
      }
    } else {
      console.error('Plot reference is not available.');
    }
  } catch (error) {
    console.error('Error downloading PDF:', error);
    toast.error('Failed to download PDF.', {
      position: 'top-right',
    });
  }
};

export const DownloadImage3D = async (plotRef: React.RefObject<any>) => {
  const modebarButton = plotRef.current.querySelector(
    '.modebar-btn[data-title="Download plot as a png"]',
  );

  if (modebarButton) {
    modebarButton.click();
    toast.success('Image downloaded successfully!', {
      position: 'top-right',
    });
  } else {
    const errorMessage =
      'Before Downloading Plot Please Ensure Plotly is fully loaded.';
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

export const DownloadImage = async (
  plotRef: React.RefObject<any>,
  filename: string,
) => {
  if (!plotRef || !plotRef.current) {
    toast.error('Plot reference is not available.', {
      position: 'top-right',
    });
    return;
  }

  // Find the Plotly SVG inside the container
  const svgElement = plotRef.current.querySelector('svg');

  if (!svgElement) {
    toast.error('Plotly chart is not fully loaded.', {
      position: 'top-right',
    });
    return;
  }

  // Convert the SVG element to Canvas using html2canvas
  html2canvas(plotRef.current, {
    scale: 2, // Increase resolution
    useCORS: true, // To handle cross-origin images
  })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save(`${filename}.pdf`);

      toast.success('PDF downloaded successfully!', {
        position: 'top-right',
      });
    })
    .catch((error) => {
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF.', {
        position: 'top-right',
      });
    });
};
