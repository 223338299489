import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SubmitComparisons, RunScript } from '../../services/ConfigApi';
import ComparisonForm from './ComparisonForm';
import ComparisonList from './ComparisonList';
import { ArrowBack } from '@mui/icons-material';
import withReactContent from 'sweetalert2-react-content';
interface Params extends Record<string, string | undefined> {
  projectName?: string;
}

interface Group {
  groupName: string;
  color: string;
}
const CompactSwal = withReactContent(Swal.mixin({
  customClass: {
    popup: 'p-1 text-sm', // Reduced padding for the popup
    title: 'text-base font-semibold', // Adjust the title font size and weight
    confirmButton: 'py-1 px-3 text-sm', // Adjust button padding and text size
    cancelButton: 'py-1 px-3 text-sm',
  },
  buttonsStyling: true,
  width: 'auto', // Adjust the width to be more compact
}));
const AddComparisons: React.FC = () => {
  const { projectname } = useParams<Params>();
  const [comparisons, setComparisons] = useState<string[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [error, setError] = useState<string>('');
  const [interest, setInterest] = useState('');
  const [reference, setReference] = useState('');
  useEffect(() => {
    const storedGroups = localStorage.getItem(`${projectname}-configured-groups`);
    if (storedGroups) {
      setGroups(JSON.parse(storedGroups));
    }
  }, [projectname]);

  useEffect(() => {
    const storedComparisons = localStorage.getItem(`${projectname}-group-comparisons`);
    if (storedComparisons) {
      setComparisons(JSON.parse(storedComparisons));
    }
    console.log("saved comparisons :", comparisons);
  }, []);

  const addComparison = (interest: string, reference: string) => {
    if (!interest || !reference) {
      setError('Please select both interest and reference.');
      return;
    }

    if (interest === reference) {
      setError('Cannot compare the same group.');
      return;
    }

    const newComparison = `${interest} vs ${reference}`;
    if (comparisons.includes(newComparison)) {
      setError('This comparison already exists.');
      return;
    }

    setComparisons([...comparisons, newComparison]);
    setError('');
  };

  const removeComparison = (index: number) => {
    setComparisons(comparisons.filter((_, i) => i !== index));
  };

  const handleSaveAndContinue = async () => {
    if (comparisons.length === 0) {
      CompactSwal.fire({
        title: 'No Comparisons',
        text: 'Please add at least one comparison before proceeding.',
        icon: 'error',
        confirmButtonText: 'Okay'
      });
      return;
    }

    const payload = comparisons.map(comparison => {
      const [interest, reference] = comparison.split(' vs ');
      return [`${interest}_vs_${reference}`, interest, reference];
    });

    const groupComparisons = comparisons.map(comparison => {
      const [interest, reference] = comparison.split(' vs ');
          const newComparison = `${interest} vs ${reference}`;

    });
    try {
      await SubmitComparisons(projectname!, JSON.stringify(payload));
      localStorage.setItem(`${projectname}-group-comparisons`, JSON.stringify(comparisons));
      handleRunScript();
    } catch (error) {
      CompactSwal.fire({
        title: 'Error',
        text: 'An error occurred while submitting comparisons.',
        icon: 'error'
      });
    }
  };

  const handleRunScript = async () => {
    try {
      await RunScript(projectname!);

      window.location.href = `/`;
    } catch (error) {
      CompactSwal.fire({
        title: 'Error',
        text: 'An error occurred while starting the script.',
        icon: 'error'
      });
    }
  };

  const handleDiscard = () => {
    if (projectname) {
      localStorage.removeItem(`${projectname}-group-comparisons`);
    }
    setComparisons([]);
    setInterest('');
    setReference('');
    setError('')
  };

  const handleGoBack = () => {
    window.location.href = `/configure/${projectname}`;
  };

  return (
    <div className="relative p-8 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-8 enable-click" onClick={handleGoBack}>
        <button className="mr-2 text-lg">
          <ArrowBack fontSize="large" />
        </button>
        <h1 className="text-xl font-bold">Add Comparisons</h1>
      </div>

      <ComparisonForm groups={groups} addComparison={addComparison} error={error} interest={interest} setInterest={setInterest} reference={reference} setReference={setReference} />

      <ComparisonList comparisons={comparisons} groups={groups} removeComparison={removeComparison} />

      <div className="flex justify-between">
        <button
          className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
          onClick={handleGoBack}
        >
          Go Back
        </button>
        <button
          className="px-4 py-2 text-white bg-teal-700 rounded-md hover:bg-teal-800"
          onClick={handleSaveAndContinue}
        >
          Save and Continue
        </button>
      </div>
      <button
        className="absolute px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg top-7 right-8 hover:bg-blue-50"
        onClick={handleDiscard}
      >
        Discard
      </button>
    </div>
  );
};

export default AddComparisons;
