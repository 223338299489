import React from 'react';

const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.3)', // Changed to a greyish background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
};

const spinnerStyle: React.CSSProperties = {
  border: '3px solid #123abc',
  borderTop: '3px solid transparent',
  borderRadius: '50%',
  width: '120px',
  height: '120px',
  animation: 'spin 1s linear infinite',
};

const LoadingOverlay: React.FC = () => {
  return (
    <div style={overlayStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default LoadingOverlay;