import { Form, Select, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  Comparison,
  GetDifferentialExpression,
  GetDiffExpPca,
  GetDiffExpHm,
  SharedWith,
} from '../../../services/vnexPlotApi';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import { DifferentialExpressionData } from '../../../data/CONSTANT';
import Modal from '../Modal';
import { DownloadImage } from '../../../utils/download';
import { BarLoader, ClipLoader } from 'react-spinners';
import {
  IBasicAnalysis,
  IHmData,
  IPcaData,
  ICompData,
  IfilterDataDifferential,
} from '../../../interface/vnexInterfaces';
// Import the plots
import VolcanoPlot from './plots/VolcanoPlot';
import MAPlot from './plots/MAPlot';
import DotPlot from './plots/DotPlot';
import PcaPlot from './plots/PCADePlot';
import HmPlot from './plots/HeatmapDePlot';
import { useQuery } from '@tanstack/react-query';

interface DifferentialExpressionProps {
  projectName: string;
}

const DifferentialExpression: React.FC<DifferentialExpressionProps> = ({
  projectName,
}) => {
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const plotRef = useRef<any>();
  const initialFilterData = {
    comparison: '',
    fold_change_threshold: 2.0,
    statistical_significance_threshold: 0.05,
    use: 'Pvalue',
    plot: 'Volcano',
    genes: 'All genes',
  };
  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? '';
  const [filterData, setFilterData] =
    useState<IfilterDataDifferential>(initialFilterData);
  const [searchResults, setSearchResults] = useState<IBasicAnalysis[]>([]);

  const handleChange = (
    //Hande the change of the filterData, the left menu
    value: string | number | string[] | null,
    name: string,
  ) => {
    if (
      (typeof value === 'string' ||
        Array.isArray(value) ||
        typeof value === 'number' ||
        value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { data: dataComparison, isLoading: comparisonLoading } = useQuery({
    queryKey: ['Comparison', filterData.comparison],
    queryFn: () => Comparison(projectName, email),
  });

  useEffect(() => {
    if (dataComparison?.data) {
      //const fetchedData = dataComparison?.data || [];
      setFilterData((prev) => ({
        ...prev,
        comparison:
          prev.comparison ||
          (dataComparison?.data.length ? dataComparison?.data[0] : ''),
      }));
    }
  }, [dataComparison]);
  const {
    data: dataDifferentialExpression,
    isLoading: DifferentialExpressionLoading,
    isSuccess: loadedDE,
  } = useQuery({
    queryKey: ['DataDiffExp', filterData],
    queryFn: () => GetDifferentialExpression(filterData, email, projectName),
  });

  const { data: dataDiffExpPca, isLoading: DiffExpPcaLoading } = useQuery({
    queryKey: ['DiffExpPCAData', filterData],
    queryFn: () => GetDiffExpPca(filterData, email, projectName),
  });

  const { data: dataDiffExpHm, isLoading: DiffExpHMLoading } = useQuery({
    queryKey: ['DiffExpHMData', filterData],
    queryFn: () => GetDiffExpHm(filterData, email, projectName),
  });

  const compData: ICompData | null =
    dataDifferentialExpression?.data?.comp_data ?? null;
  const tableData: IBasicAnalysis[] =
    dataDifferentialExpression?.data?.table_data ?? null;
  const pcaData: IPcaData | null = dataDiffExpPca?.data?.pca_data ?? null;
  const hmData: IHmData | null = dataDiffExpHm?.data?.heatmap_data ?? null;
  console.log(
    'dataDifferentialExpression?.data',
    dataDifferentialExpression?.data,
  );

  const meanGroup1Name = filterData.comparison.split('_vs_')[0];
  const meanGroup2Name = filterData.comparison.split('_vs_')[1];

  const columns: ColumnsType<IBasicAnalysis> = [
    {
      title: 'ensembl_gene_id',
      dataIndex: 'ensembl_gene_id',
      key: 'ensembl_gene_id',
      sorter: (a, b) => a.ensembl_gene_id.localeCompare(b.ensembl_gene_id),
    },
    {
      title: 'entrezgene_id',
      dataIndex: 'entrezgene_id',
      key: 'entrezgene_id',
      sorter: (a, b) => a.entrezgene_id - b.entrezgene_id,
      render: (text: number) => {
        return <span style={{ fontWeight: 'bold' }}>{text}</span>;
      },
    },
    {
      title: 'hgnc_symbol',
      dataIndex: 'hgnc_symbol',
      key: 'hgnc_symbol',
      sorter: (a, b) => a.hgnc_symbol.localeCompare(b.hgnc_symbol),
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    // {
    //   title: `mean_${meanGroup1Name}`,
    //   dataIndex: `mean`,
    //   key: `mean`,
    //   render: (meanArray: any) => {
    //     const meanItem = meanArray?.find(
    //       (item: any) => item.name === meanGroup1Name,
    //     );
    //     console.log('meanItem1', meanItem);
    //     return meanItem ? meanItem.value : '';
    //   },
    // },
    // {
    //   title: `mean_${meanGroup2Name}`,
    //   dataIndex: `mean`,
    //   key: `mean`,
    //   render: (meanArray: any) => {
    //     const meanItem = meanArray?.find(
    //       (item: any) => item.name === meanGroup2Name,
    //     );
    //     console.log('meanItem2', meanItem);
    //     return meanItem ? meanItem.value : '';
    //   },
    // },
    {
      title: `mean_${meanGroup1Name}`,
      dataIndex: `mean_${meanGroup1Name}`,
      key: `mean_${meanGroup1Name}`,
      // sorter: (a, b) => a.foldChange - b.foldChange,
    },
    {
      title: `mean_${meanGroup2Name}`,
      dataIndex: `mean_${meanGroup2Name}`,
      key: `mean_${meanGroup2Name}`,
      // sorter: (a, b) => a.mean_Test3 - b.mean_Test3,
    },
    {
      title: 'foldChange',
      dataIndex: 'foldChange',
      key: 'foldChange',
      sorter: (a, b) => a.foldChange - b.foldChange,
    },
    {
      title: 'Pval',
      dataIndex: 'Pval',
      key: 'Pval',
      sorter: (a, b) => a.Pval - b.Pval,
    },
    {
      title: 'FDR',
      dataIndex: 'FDR',
      key: 'FDR',
      sorter: (a, b) => a.FDR - b.FDR,
    },
    // {
    //   title: 'diffExp',
    //   dataIndex: 'diffExp',
    //   key: 'diffExp',
    //   sorter: (a, b) => a.diffExp.localeCompare(b.diffExp),
    // },
  ];
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const foldChangeThreshold = filterData.fold_change_threshold;
      const pvalue = filterData.statistical_significance_threshold;
      const genes = filterData.genes;
      console.log('tableData length', tableData.length);

      let filtereTableData: IBasicAnalysis[] = [];

      // if (foldChangeThreshold && pvalue) {
      //   filtereTableData = tableData.filter(
      //     (item) => item.foldChange > foldChangeThreshold && item.Pval > pvalue,
      //   );
      // }
      if (genes === 'All genes') {
        setSearchResults(tableData); // Ignore all filters if "All genes" is selected
      } else if (foldChangeThreshold && pvalue && genes !== 'All genes') {
        filtereTableData = tableData.filter(
          (item) => item.foldChange > foldChangeThreshold && item.Pval > pvalue,
        );
        setSearchResults(filtereTableData);
      }

      console.log('filtereTableData length', filtereTableData.length);

      // setSearchResults(filtereTableData); // Sync search results with table data
    }
  }, [tableData]);

  const handleSearch = (searchTerm: string) => {
    // Search button on the datatable
    if (!searchTerm) {
      setSearchResults(tableData);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filtered = tableData.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerCaseTerm),
        ),
      );
      setSearchResults(filtered);
    }
  };

  const downloadTableData = (data: IBasicAnalysis[], format: string) => {
    const meanGroup1Name = filterData.comparison.split('_vs_')[0];
    const meanGroup2Name = filterData.comparison.split('_vs_')[1];
    if (format === 'csv') {
      const csvContent = [
        [
          'ensembl_gene_id',
          'entrezgene_id',
          'hgnc_symbol',
          'description',
          [`mean_${meanGroup1Name}`],
          [`mean_${meanGroup2Name}`],
          'foldChange',
          'Pval',
          'FRD',
        ],
        ...data.map((row: any) => [
          row.ensembl_gene_id,
          row.entrezgene_id,
          row.hgnc_symbol,
          row.description,
          row[`mean_${meanGroup1Name}`],
          row[`mean_${meanGroup2Name}`],
          row.foldChange,
          row.Pval,
          row.FDR,
        ]),
      ]
        .map((e) => e.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute(
        'download',
        `Differential_Expression_${filterData.plot}.csv`,
      );
      link.click();
    } else if (format === 'json') {
    }
  };

  const modalTitle =
    filterData.plot === 'Volcano'
      ? DifferentialExpressionData.Volcano.title
      : filterData.plot === 'MA plot'
        ? DifferentialExpressionData.MAPlot.title
        : filterData.plot === 'Dotplot between groups'
          ? DifferentialExpressionData.DotPlot.title
          : filterData.plot === 'PCA of differentially expressed'
            ? DifferentialExpressionData.PCA.title
            : DifferentialExpressionData.HeatMap.title;
  const modalDescription =
    filterData.plot === 'Volcano'
      ? DifferentialExpressionData.Volcano.description
      : filterData.plot === 'MA plot'
        ? DifferentialExpressionData.MAPlot.description
        : filterData.plot === 'Dotplot between groups'
          ? DifferentialExpressionData.DotPlot.description
          : filterData.plot === 'PCA of differentially expressed'
            ? DifferentialExpressionData.PCA.description
            : DifferentialExpressionData.HeatMap.description;

  const DifferentialExpressionPlots = () => {
    if (filterData.plot === 'Volcano') {
      if (compData) {
        return (
          <>
            <div ref={plotRef}>
              <VolcanoPlot
                compData={compData}
                filterData={filterData}
                title=""
              />
            </div>
          </>
        );
      } else {
        return (
          <div className="flex items-center justify-center w-full h-64">
            <svg
              className="animate-spin h-10 w-10 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <span className="ml-2">Loading Volcano Plot...</span>
          </div>
        );
      }
    } else if (filterData.plot === 'MA plot') {
      if (compData) {
        return (
          <>
            <div ref={plotRef}>
              <MAPlot maData={compData} filterData={filterData} title="" />
            </div>
          </>
        );
      } else {
        <div>
          {' '}
          <p>wait</p>
        </div>;
      }
    } else if (filterData.plot === 'Dotplot between groups') {
      if (compData) {
        return (
          <>
            <div className="text-sm text-gray-500 mb-6 text-left block w-full">
              {/* <h3 className="mb-3 font-semibold text-black">
                {DifferentialExpressionData.DotPlot.title}
              </h3> */}
              {/* <p className="w-5/6">
                {DifferentialExpressionData.DotPlot.description}
              </p> */}
            </div>
            <div ref={plotRef}>
              <DotPlot compData={compData} filterData={filterData} title="" />
            </div>
          </>
        );
      } else {
        return (
          <div className="flex items-center justify-center w-full h-64">
            <svg
              className="animate-spin h-10 w-10 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <span className="ml-2">Loading DotPlot Plot...</span>
          </div>
        );
      }
    } else if (filterData.plot === 'PCA of differentially expressed') {
      if (pcaData) {
        return (
          <>
            <div ref={plotRef}>
              <PcaPlot pcaData={pcaData} />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div>
              <p>Not enough data to make the PCA of DE genes</p>
            </div>
          </>
        );
      }
    } else if (filterData.plot === 'Heatmap of differentially expressed') {
      if (hmData) {
        return (
          <>
            <div ref={plotRef}>
              <HmPlot hmData={hmData} />
            </div>
          </>
        );
      } else {
        return (
          <h1>
            {' '}
            No genes differentially expressed with the selected criteria{' '}
          </h1>
        );
      }
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };
  const SelectGenesTable = () => {
    return (
      <Form.Item label="" colon={false}>
        <Select
          value={filterData.genes}
          defaultValue={filterData.genes}
          style={{ width: '217px' }}
          onChange={(value) => handleChange(value, 'genes')}
        >
          <Option value="All genes">All genes</Option>
          <Option value="Differentially expressed">
            Differentially expressed
          </Option>
        </Select>
      </Form.Item>
    );
  };
  const HelpDeskMark = () => {
    return (
      <button onClick={() => setIsModalOpen(true)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
            stroke="#374548"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    );
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          {comparisonLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} /> {/* Loader component */}
            </div>
          ) : (
            <Form.Item label="Comparison" colon={false}>
              <Select
                value={filterData.comparison} // Directly use the string value
                style={{ width: '100%' }}
                onChange={(value) => handleChange(value, 'comparison')} // Pass selected value to the handler
                placeholder="Select an option"
              >
                {dataComparison?.data.map((item: any, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {/* <Title level={5}>Figure</Title> */}
          <Form.Item label="Plot" colon={false}>
            <Select
              value={filterData.plot}
              defaultValue={filterData.plot}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'plot')}
            >
              <Option value="Volcano">Volcano</Option>
              <Option value="MA plot">MA plot</Option>
              <Option value="Dotplot between groups">
                Dotplot between groups
              </Option>
              <Option value="PCA of differentially expressed">
                PCA of differentially expressed
              </Option>
              <Option value="Heatmap of differentially expressed">
                Heatmap of differentially expressed
              </Option>
            </Select>
          </Form.Item>

          <br></br>
          <Title level={5}>DE Criteria</Title>

          <Form.Item label="Fold change threshold" colon={false}>
            <Select
              defaultValue={filterData.fold_change_threshold}
              value={filterData.fold_change_threshold}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'fold_change_threshold')}
            >
              <Option value={1.5}>1.5</Option>
              <Option value={2.0}>2.0</Option>
              <Option value={4.0}>4.0</Option>
              <Option value={null}>None</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Statistical significance threshold" colon={false}>
            <Select
              defaultValue={filterData.statistical_significance_threshold}
              value={filterData.statistical_significance_threshold}
              style={{ width: '100%' }}
              //onChange={handleChange}
              onChange={(value) =>
                handleChange(value, 'statistical_significance_threshold')
              }
            >
              <Option value={0.1}>0.1</Option>
              <Option value={0.05}>0.05</Option>
              <Option value={0.01}>0.01</Option>
              <Option value={null}>None</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Use" colon={false}>
            <Select
              value={filterData.use}
              defaultValue={filterData.use}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'use')}
            >
              <Option value="Pvalue">Pvalue</Option>
              <Option value="FDR">FDR</Option>
            </Select>
          </Form.Item>
        </div>

        {/* Plot Section */}
        <div className="flex-1 flex flex-col items-center  p-4">
          <div className="flex justify-between w-full mb-4">
            <h1 className="text-2xl font-semibold mb-5"></h1>
            <div className="flex mr-8 relative">
              <button
                className="text-onex-purple-100 mr-2"
                onClick={() =>
                  DownloadImage(
                    plotRef,
                    `DifferentialExpression_${filterData.plot}`,
                  )
                }
              >
                {DifferentialExpressionLoading ? (
                  <div>
                    <BarLoader width={50} color="#39F2AE" />
                  </div>
                ) : (
                  'Download'
                )}
              </button>
              <button onClick={() => setIsModalOpen(true)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                    stroke="#374548"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <Modal
                open={isModalOpen}
                title={modalTitle}
                description={modalDescription}
                onClose={() => setIsModalOpen(false)}
              />
              <div id="modal-root"></div>
            </div>
          </div>
          {DifferentialExpressionLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} />
            </div>
          ) : (
            <div className="w-full text-center">
              {DifferentialExpressionPlots()}
            </div>
          )}
        </div>
      </div>
      {/* <h3 className="text-xl font-semibold mb-5">Sample metadata</h3>
      <p>Sample definitions</p> */}
      <ToastContainer />
      <DataTable
        columns={columns}
        data={searchResults}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
        geneTable={<SelectGenesTable />}
        dEHelpMark={<HelpDeskMark />}
      />
    </div>
  );
};
export default DifferentialExpression;
