export const DifferentialExpressionData = {
  Volcano: {
    title: 'Volcano',
    description:
      'Volcano plot depicting genes by log2 fold change (X-axis) and -log10 p-value (Y-axis). Upregulated and downregulated genes are positioned towards the plot extremes',
  },
  MAPlot: {
    title: 'MA Plot',
    description:
      'MA plot illustrating gene expression differences through the log-ratio of intensities (M) and the average intensity (A). Genes with altered expression levels are represented in the plot',
  },
  DotPlot: {
    title: 'Dotplot between groups',
    description: 'Dotplot with average expression of each group',
  },
  PCA: {
    title: 'PCA of differentially expressed',
    description: '',
  },
  HeatMap: {
    title: 'Heatmap of differentially expressed',
    description: '',
  },
};
export const GeneData = {
  Dotplot: {
    title: 'Dotplot',
    description:
      'In a dotplot, the x-axis represents distinct groups of interest, while the y-axis quantifies gene expression levels. Each dot on the plot corresponds to the expression level of a the selected gene within a specific group. The positioning of the dots vertically aligns with the group of interest and horizontally indicates the gene expression magnitude.',
  },
  Boxplot: {
    title: 'Boxplot',
    description:
      'A boxplot, also known as a box-and-whisker plot, is a standardized way of displaying the distribution of data based on a five-number summary: minimum, first quartile (Q1), median, third quartile (Q3), and maximum. In a boxplot, the central rectangle spans from Q1 to Q3, a segment inside the rectangle shows the median, and "whiskers" above and below the box show the locations of the minimum and maximum. Outliers may be plotted as individual points. Boxplots are particularly useful for indicating whether a distribution is skewed and whether there are potential unusual observations (outliers) in the data set. They also show the spread (range, interquartile range) and the center (median) of the data.',
  },
  Violin: {
    title: 'Violin ',
    description:
      'A violin plot is a method of plotting numeric data and can be seen as a combination of a boxplot and a kernel density plot. It provides a deeper understanding of the distribution of the data. The violin plot features a kernel density estimation of the underlying distribution of the data on both sides, mirrored to form a symmetrical, violin-like shape. The width of the plot at different values indicates the frequency or density of data points, with wider sections representing higher density (more data points).',
  },
  Barplot: {
    title: 'Barplot',
    description:
      'A bar plot depicting the mean and standard error (SE) of the mean is a graphical representation used to illustrate the central tendency and variability of a dataset. Each bar in the plot represents the mean value of a group or category, and error bars are placed on top of each bar to indicate the SE of the mean. This visual format allows for an immediate and clear understanding of the mean values across your groups of interest. The length of the error bars provides insight into the spread or variability of the data, with shorter bars indicating more precise estimates (less variability) and longer bars indicating less precise estimates (more variability).',
  },
};
export const GeneCorrelationData = {
  ToGether: {
    title: 'ToGether',
    description:
      'Correlation plot with all samples, without taking into account the groups of interest.',
  },
  Seperated: {
    title: 'Seperated',
    description: 'One correlation plot for each group of interest.',
  },
};
export const ClusteringStaticData = {
  PCA: {
    title: 'PCA',
    description:
      'Correlation plot with all samples, without taking into account the groups of interest.',
  },
  HeatMap: {
    title: 'HeatMap',
    description: 'One correlation plot for each group of interest.',
  },
};
