import Swal from 'sweetalert2';
import { AxiosProgressEvent } from 'axios';
import { getProfileApi } from '../services/ProfileApi';
import { GetUserSubscription } from '../services/subscriptionApi';
import { SelectedFile, FtpDetails, SelectedURLfile } from '../types/upload';
import { getSubscriptionLimits } from './subscriptionLimits';


export const handleProgress = (setProgress: (progress: number) => void) => (progressEvent: AxiosProgressEvent) => {
  const total = progressEvent.total ?? 1;
  const loaded = progressEvent.loaded;
  setProgress(Math.round((loaded / total) * 100));
};


export const CheckStorageLimit = async (
  selectedFiles: SelectedFile[],
  SelectedURLfile: SelectedURLfile[],
  ftpDetails: FtpDetails[],
  environmentName: string,
): Promise<{ isWithinLimit: boolean; exceededAmountGB?: string | null }> => {
  if (environmentName && environmentName !== 'local') {
    // setLoading(true);
    const totalSelectedFileSizeMB = Math.round(
      selectedFiles.reduce((acc, file) => acc + file.file.size, 0) /
        (1024 * 1024), // Converting to MB
    );
    const totalFileSizeMB = totalSelectedFileSizeMB;
    const totalURLFileSizeMB = SelectedURLfile.reduce(
      (acc, file) => acc + file.filesize,
      0,
    );
    const totalFtpFileSizeMB = ftpDetails.reduce(
      (acc, ftp) => acc + ftp.file_size,
      0,
    );
    const combinedTotalFileSizeMB =
      totalFileSizeMB + totalURLFileSizeMB + totalFtpFileSizeMB;

    const [subscriptionResponse, profileResponse] = await Promise.all([
      GetUserSubscription(),
      getProfileApi(),
    ]);
    const subscription = subscriptionResponse.data;
    const profile = profileResponse.data;

    const limits = getSubscriptionLimits(
      subscription?.item_price_name || 'Free',
    ); // Fetch limits from the utility
    const maxStorageMB = limits.maxDataGB * 1024; // Convert GB to MB

    const totalDataMB = profile.total_data;

    if (totalDataMB + combinedTotalFileSizeMB > maxStorageMB) {
      const exceededAmountMB =
        totalDataMB + combinedTotalFileSizeMB - maxStorageMB;

      const exceededAmountGB = (exceededAmountMB / 1024).toFixed(2);

     
      // setLoading(false);
      return { isWithinLimit: false, exceededAmountGB: exceededAmountGB };
    }
    // setLoading(false);
    return { isWithinLimit: true};
  } else {
    return { isWithinLimit: true};
  }
};