// import { Config, ModeBarDefaultButtons } from 'plotly.js';

// export const commonConfig = (filename: string): Partial<Config> => ({
//   responsive: true,
//   autosizable: true,
//   modeBarButtonsToRemove: [
//     'zoom3d',
//     'pan3d',
//     'zoom2d',
//     'pan2d',
//     'select2d',
//     'lasso2d',
//     'zoomIn2d',
//     'zoomOut2d',
//     'autoScale2d',
//     'resetScale2d',
//     'hoverClosestCartesian',
//     'hoverCompareCartesian',
//     'toggleSpikelines',
//     'resetCameraDefault3d',
//     'resetCameraLastSave3d',
//     'hoverClosest3d',
//     'orbitRotation',
//     'tableRotation',
//   ] as ModeBarDefaultButtons[], // Ensure the type matches exactly
//   displaylogo: false,
//   showTips: false,
//   toImageButtonOptions: {
//     filename: filename,
//
//   },
// });

import { Config, ModeBarDefaultButtons } from 'plotly.js';

export const commonConfig: Partial<Config> = {
  responsive: true,
  autosizable: true,
  modeBarButtonsToRemove: [
    'zoom3d',
    'pan3d',
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'resetScale2d',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
    'toggleSpikelines',
    'resetCameraDefault3d',
    'resetCameraLastSave3d',
    'hoverClosest3d',
    'orbitRotation',
    'tableRotation',
  ] as ModeBarDefaultButtons[], // Ensure the type matches exactly
  displaylogo: false,
  showTips: false,
};
