const pricingPlanDetails = {
  services: [
    {
      name: "Process raw data (fastq files)",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Import processed data (count tables)",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Import public datasets (GEO, EMBL, ...)",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Data normalisation",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Quality Control",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Batch correction",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "PCA",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Heatmap",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Differential Expression",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Differential Expression with covariates",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Survival analysis",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Volcano plots",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "GSEA",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Cell type deconvolution",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Export plots as pdfs",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Export tables as xlsx",
      limited: "✓",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Interactive results",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
    {
      name: "Integration of RNA-seq datasets",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Multiomics (merge with other seq data)",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Browse genome",
      limited: "×",
      standard: "×",
      advanced: "✓",
    },
    {
      name: "Basic report with methods",
      limited: "✓",
      standard: "×",
      advanced: "×",
    },
    {
      name: "Advanced report with methods",
      limited: "×",
      standard: "✓",
      advanced: "✓",
    },
  ],
};

export default pricingPlanDetails;
