import { apiClient } from './apiClient';

export const sendBugReport = (formData:any) => {
  return apiClient.post('/report-bug', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const SendContactUs = (formData:any) => {
  return apiClient.post('/contact-us', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const SendBooking = (formData:any) => {
  return apiClient.post('/book-session', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};