import { ShieldAlert } from 'lucide-react';

export default function NotAuthorized() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div className="text-center">
          <div className="flex justify-center">
            <ShieldAlert
              className="h-12 w-12 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
            Access Denied
          </h1>
        </div>
        <div className="mt-2">
          <p className="text-center text-gray-600">
            Sorry, you don't have permission to view this data as your license is invalid. 
            If you believe this is an error, please contact your administrator.
          </p>
        </div>
      </div>
    </div>
  );
}
