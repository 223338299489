import React from 'react';
import Plot from 'react-plotly.js';
import { IHmData } from '../../../../interface/vnexInterfaces';
import { commonConfig } from '../../../../utils/plotConfig';

interface HmPlotProps {
  hmData: IHmData;
}

interface MyHeatmapData {
  z: number[][];
  x?: string[];
  y?: string[];
  type: 'heatmap';
  colorscale: [number, string][];
  zmid?: number;
  zmin?: number;
  zmax?: number;
  colorbar?: any;
  hovertemplate?: string;
  [key: string]: any;
}

const HmPlot: React.FC<HmPlotProps> = ({ hmData }) => {
  const { data, row_labels, column_labels, groups, groupcol_intern } = hmData;
  const geneLabels = row_labels || [];
  const sampleLabels = column_labels || [];

  // Extract foldChange by casting hmData to any.
  const foldChange: number[] = (hmData as any).foldChange || [];

  // --- Reorder rows based on foldChange ---
  // Compute sorted indices (descending order: highest fold change first)
  const sortedIndices = geneLabels
    .map((_, i) => i)
    .sort((a, b) => (foldChange[b] || 0) - (foldChange[a] || 0));

  // Reorder the gene labels and data rows accordingly.
  const sortedGeneLabels = sortedIndices.map((i) => geneLabels[i]);
  const sortedData = sortedIndices.map((i) => data[i]);

  // Compute groupColors for the group annotation bar:
  const groupColors =
    groups && groupcol_intern && !Array.isArray(groupcol_intern)
      ? groups.map((g: string) => groupcol_intern[g.trim()] || '#000000')
      : Array.isArray(groupcol_intern)
        ? groupcol_intern
        : [];

  // Build a mapping from group name to color for the legend dummy traces.
  let legendMapping: Record<string, string> = {};
  if (groups) {
    if (groupcol_intern && !Array.isArray(groupcol_intern)) {
      groups.forEach((g: string) => {
        const key = g.trim();
        if (!legendMapping[key]) {
          legendMapping[key] = groupcol_intern[key] || '#000000';
        }
      });
    } else if (Array.isArray(groupcol_intern)) {
      groups.forEach((g: string, i: number) => {
        const key = g.trim();
        if (!legendMapping[key]) {
          legendMapping[key] = groupcol_intern[i] || '#000000';
        }
      });
    }
  }

  // --- Compute the threshold dynamically ---
  // Flatten the 2D data array into a 1D array.
  const flattenedData = sortedData.flat();
  // Get the absolute values, sort them, and compute the 95th percentile.
  const sortedAbs = flattenedData.map(Math.abs).sort((a, b) => a - b);
  const percentileIndex = Math.floor(0.95 * sortedAbs.length);
  const threshold = sortedAbs[percentileIndex] || 4; // fallback to 4 if data is empty

  // Main heatmap trace with dynamic thresholds.
  const heatmapData: MyHeatmapData = {
    z: sortedData,
    x: sampleLabels,
    y: sortedGeneLabels,
    type: 'heatmap',
    colorscale: [
      [0, '#6E04FE'],
      [0.375, 'lightgrey'],
      [0.625, 'lightgrey'],
      [1, '#40C37B'],
    ],
    zmid: 0,
    zmin: -threshold,
    zmax: threshold,
    hovertemplate: 'sample: %{x}<br>gene: %{y}<br>value: %{z}<extra></extra>',
    colorbar: {
      tickmode: 'array',
      tickvals: [-threshold, 0, threshold],
      ticktext: [`-${threshold.toFixed(2)}`, '0', threshold.toFixed(2)],
      thickness: 15,
      x: 1.02, // positions the colorbar to the right
      len: 0.7,
    },
  };

  // Group annotation bar trace using a dedicated y-axis.
  const groupTrace = {
    x: sampleLabels,
    y: groups ? groups.map(() => 1) : [],
    type: 'bar' as const,
    marker: { color: groupColors },
    hoverinfo: 'none',
    showlegend: false,
    yaxis: 'y2',
  };

  // Dummy legend traces for group colours.
  const uniqueGroups = groups
    ? Array.from(new Set(groups.map((g: string) => g.trim())))
    : [];
  const legendTraces = uniqueGroups.map((group: string) => ({
    x: [null],
    y: [null],
    type: 'scatter' as const,
    mode: 'markers' as const,
    marker: {
      color: legendMapping[group] || '#000000',
      size: 10,
    },
    name: group,
    showlegend: true,
  }));

  return (
    <Plot
      data={[heatmapData, groupTrace, ...legendTraces] as any}
      layout={{
        title: '',
        width: 1000,
        height: 700,
        margin: { l: 50, r: 50, t: 50, b: 150 },
        xaxis: { tickangle: -45 },
        // Main heatmap retains its original size.
        yaxis: {
          domain: [0, 0.9],
          showticklabels: false,
          ticks: '',
          ticklen: 0,
        },
        // The group annotation bar occupies a narrow band with a gap above the heatmap.
        yaxis2: {
          domain: [0.95, 1],
          showticklabels: false,
          ticks: '',
          ticklen: 0,
        },
        bargap: 0,
        bargroupgap: 0,
        // Legend positioning and interaction disabled.
        legend: {
          x: 1.05,
          y: 1,
          traceorder: 'normal',
          font: { size: 12 },
          itemclick: false,
          itemdoubleclick: false,
        },
      }}
      config={commonConfig}
    />
  );
};

export default HmPlot;