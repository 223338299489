import React, { useEffect } from 'react';
import { useEnvironment } from '../../utils/EnvContext';
import Swal from 'sweetalert2';
import {
  FtpDetails,
  GeoCode,
  SelectedFile,
  SelectedURLfile,
} from '../../types/upload';
import { handleFileDrop } from './uploadFunctions';
import { CheckStorageLimit } from '../../utils/upload';
import { useNavigate } from 'react-router-dom';

interface FileUploadSectionProps {
  uploading?: boolean;
  setLoading?: (loading: boolean) => void;
  selectedFiles?: SelectedFile[];
  setSelectedFiles?: (files: SelectedFile[]) => void;
  SelectedURLfile?: SelectedURLfile[];
  geoCodes?: GeoCode[];
  ftpDetails?: FtpDetails[];
  setErrors?: any;
  clearError?: (field: string) => void;
  isReport: boolean;
  handleFileSelectForReport?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const CloudUploadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
    <path d="M12 12v9" />
    <path d="m16 16-4-4-4 4" />
  </svg>
);

const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  uploading = false,
  setLoading,
  selectedFiles,
  setSelectedFiles,
  SelectedURLfile,
  geoCodes,
  ftpDetails,
  setErrors,
  clearError,
  isReport,
  handleFileSelectForReport,
}) => {
  const { environmentName } = useEnvironment();
  const navigate = useNavigate();

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLoading && setLoading(true);
    if (isReport) {
      handleFileSelectForReport?.(event);
      return;
    } else {
      if (
        (SelectedURLfile ?? []).length > 0 ||
        (geoCodes?.length ?? 0) > 0 ||
        (ftpDetails?.length ?? 0) > 0
      ) {
        setErrors({
          selectedFilesOrURLs:
            'You can only upload one type: File, URL, GEO code, or FTP.',
        });
        setLoading && setLoading(false);

        return; // Prevent submission
      }

      const files = event.target.files;
      if (files) {
        const newFiles = Array.from(files).map((file) => ({
          file,
          isUploadFailed: false,
        }));

        const pattern = /^[a-zA-Z0-9_.-]+$/;

        const invalidFiles = newFiles.filter(
          (file) => !pattern.test(file.file.name),
        );
        if (invalidFiles.length > 0) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid File Names',
            text: `The following files have invalid names: ${invalidFiles.map((file) => file.file.name).join(', ')}. Only alphanumerical characters, '-', '.' and '_' are allowed.`,
            width: '350px',
            padding: '1em',
            position: 'top-end',
          });
          setLoading && setLoading(false);

          return;
        }

        const newSelectedFiles = [
          ...(selectedFiles ?? []),
          ...newFiles.map((file) => ({ ...file, isUploadFailed: false })),
        ];
        if (environmentName && selectedFiles && SelectedURLfile && ftpDetails) {
          if (environmentName !== 'local') {
            const { isWithinLimit, exceededAmountGB } = await CheckStorageLimit(
              selectedFiles,
              SelectedURLfile,
              ftpDetails,
              environmentName,
            );
            if (!isWithinLimit) {
              Swal.fire({
                title: 'Storage Limit Exceeded',
                text: `You have exceeded your data usage by ${exceededAmountGB} GB. Please upgrade your plan to proceed.`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: 'Upgrade Plan',
                cancelButtonText: 'No',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/account');
                }
              });
              setLoading && setLoading(false);

              return;
            }
          }

          setSelectedFiles && setSelectedFiles(newSelectedFiles);
          clearError && clearError('selectedFilesOrURLs');
        }
      }
    }
    setLoading && setLoading(false);
  };

  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();

      const filesArray = event.dataTransfer
        ? Array.from(event.dataTransfer.files)
        : []; // Convert to array

      handleFileDrop(event, (files) => {
        const updatedFiles = filesArray.map((file: any) => ({
          file,
          isUploadFailed: false,
        }));
        setSelectedFiles && setSelectedFiles(updatedFiles);
      });
    };

    if (dropZone) {
      dropZone.addEventListener('dragover', (event) => {
        event.preventDefault();
      });

      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', (event) => {
          event.preventDefault();
        });

        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  return (
    <div
      className={`p-10 mb-1 text-center border-2 border-gray-300 border-dashed cursor-pointer ${uploading ? 'cursor-not-allowed' : ''}`}
      id="drop-zone"
      onClick={() =>
        !uploading && document.getElementById('file-input')?.click()
      } // Disable click if uploading
    >
      <CloudUploadIcon className="w-12 h-12 mx-auto mb-4 text-teal-600" />
      <p className="pb-2 mb-4 font-semibold text-teal-800">
        Drag & Drop your file(s) to start uploading
      </p>

      {/* Dotted line with "OR" centered */}
      <div className="flex items-center justify-center mb-4">
        <div className="flex-grow border border-gray-300 border-dashed"></div>
        <span className="px-4 font-semibold text-gray-500">OR</span>
        <div className="flex-grow border border-gray-300 border-dashed"></div>
      </div>

      <input
        id="file-input"
        type="file"
        name="file-input"
        multiple
        className="hidden"
        onChange={handleFileSelect}
        disabled={uploading} // Disable input if uploading
      />
      <button
        type="button"
        className="px-4 py-2 mt-4 font-semibold text-teal-800 bg-teal-800 rounded-lg bg-opacity-10 hover:bg-opacity-20"
        disabled={uploading} // Disable button if uploading
      >
        Choose File
      </button>
    </div>
  );
};

export default FileUploadSection;
