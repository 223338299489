import React, { useState, useEffect } from 'react';
import { Project } from '../../interface/project';
import { Checkbox } from '@mui/material';
import { format } from 'date-fns';
import { getProfileByEmailApi } from '../../services/ProfileApi';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import defaultImage from '../../assets/profile_pic.jpg'; // Import the default image
import { GetProjectResults } from '../../services/projectApi';
import { FiEye, FiSettings } from 'react-icons/fi'; // Consistent icons
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GrDocumentPerformance } from "react-icons/gr";
interface SharedProjectRowProps {
  project: Project;
}

const getStatus = (
  status: string,
  handleLogRedirect: () => void,
  handleViewRedirect: () => void,
) => {
  if (status === "Ready") {
    return (
      <div title='view processed data' className="flex items-center space-x-1 text-gray-500 cursor-pointer" onClick={handleViewRedirect}>
        <FiEye className='text-nexco-green' aria-label="View" />
        <span className="pl-0.5 pb-1 font-semibold text-nexco-green">View</span>
      </div>
    );
  } else if (status === "Configured" || status === "Failed") {
    return (
      <div title='view logs'
        className={`flex items-center space-x-1 cursor-pointer ${
          status === "Failed" ? "text-red-500" : "text-teal-600"
        }`}  
        onClick={handleLogRedirect}
      >
        <GrDocumentPerformance aria-label="View" />
        <span className="pl-0.5 font-semibold">
          {status === "Failed" ? "Failed" : "In Progress"}
        </span>
      </div>
    );
  } else if (status === "Uploaded") {
    return (
      <div title='configure project' className="flex items-center space-x-1 text-gray-500 " >
        <FiSettings aria-label="Configure" />
        <span className="pl-0.5 pb-1 font-semibold">Configure</span>
      </div>
    );
  } else {
    return (
      <div title='uploading files' className="flex items-center space-x-1 text-orange-600">
        <AiOutlineCloudUpload />
        <span className="pl-0.5 font-semibold">Uploading</span>
      </div>
    );
  }
};

const SharedProjectRow: React.FC<SharedProjectRowProps> = ({ project }) => {
  const [ownerProfile, setOwnerProfile] = useState<{ profile_pic: string; email: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleLogRedirect = () => {
    navigate(`/ProgressLog/${project.name}`);
  };

  const handleViewRedirect = async () => {
    if (project.id !== undefined) {
      try {
        const url = await GetProjectResults(project.id);
        if (url) {
          // Open in a new tab
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error("Error fetching project results:", error);
      }
    }
  };


  useEffect(() => {
    const fetchOwnerProfile = async () => {
      try {
        const response = await getProfileByEmailApi(project.owner);
  
        if (response && response.data instanceof Blob && response.data.size > 0) {
          const imageUrl = URL.createObjectURL(response.data);
          setOwnerProfile({ profile_pic: imageUrl, email: project.owner });
        } else {
          setOwnerProfile({ profile_pic: defaultImage, email: project.owner });
        }
      } catch (error) {
        console.error('Error fetching owner profile:', error);
        setOwnerProfile({ profile_pic: defaultImage, email: project.owner });
      } finally {
        setLoading(false);
      }
    };
  
    fetchOwnerProfile();
  }, [project.owner]);

  if (loading) {
    return (
      <tr className="hover:bg-gray-100">
        <td colSpan={7} className="py-6 text-center">
          <ClipLoader size={35} color={"#123abc"} loading={loading} />
        </td>
      </tr>
    );
  }

  const isConfigured = project.status === 'Configured';
  const canView = project.status === 'Ready';
  const isFailed = project.status === 'Failed';
  return (
    <tr className="hover:bg-gray-100">
      <td className="items-center w-1/5 py-4 text-gray-500 border-b">
        <Checkbox className="form-checkbox" />
        <span className="ml-3">{project.name}</span>
      </td>
      <td className="w-1/6 px-6 py-4 text-gray-500 border-b">
        {project.type}
      </td>
      <td className="w-1/6 px-6 py-4 text-gray-500 border-b">
        {project.genome}
      </td>
      <td className="w-1/6 px-6 py-4 text-gray-500 border-b">
        {getStatus(project.status, handleLogRedirect, handleViewRedirect)}
      </td>
      <td className="w-1/6 px-6 py-4 text-gray-500 border-b">
        {format(new Date(project.dateAdded), 'MMM dd, yyyy')}
      </td>
      <td className="flex items-center w-1/4 px-6 py-4 text-gray-500 border-b">
        <>
          <img
            src={ownerProfile?.profile_pic || defaultImage}
            alt="Owner Profile"
            className="w-12 h-12 mr-2 rounded-full max-w-none"
          />
          <span>{ownerProfile?.email}</span>
        </>
      </td>
      <td className="w-1/12 px-6 py-4 text-right border-b">
        <div className="flex justify-end space-x-2">
          <FiEye
            title='view processed data'
            className={`${canView ? 'cursor-pointer text-gray-500' : 'text-gray-300'}`}
            aria-label="View"
            onClick={canView ? handleViewRedirect : undefined}
          />
          <GrDocumentPerformance
            title='view logs'
            className={`${isConfigured ? 'cursor-pointer text-gray-500' : 'text-gray-300'}`}
            aria-label="Log"
            onClick={isConfigured || isFailed || canView ? handleLogRedirect : undefined}
          />
        </div>
      </td>
    </tr>
  );
};

export default SharedProjectRow;
