import React from 'react';
import Plot from 'react-plotly.js';
import { commonConfig } from '../../../../utils/plotConfig';
import {
  ICompData,
  IfilterDataDifferential,
} from '../../../../interface/vnexInterfaces';

interface DotPlotProps {
  compData: ICompData;
  filterData: IfilterDataDifferential;
  title?: string;
}

const DotPlot: React.FC<DotPlotProps> = ({
  compData,
  filterData,
  title = 'Scatter',
}) => {
  // Dynamically extract keys from compData that start with "mean."
  const meanKeys = Object.keys(compData).filter((key) =>
    key.startsWith('mean.'),
  );

  // Ensure there are at least two mean properties.
  if (meanKeys.length < 2) {
    return <div>Not enough mean values to plot.</div>;
  }

  // Use the first two mean keys (order based on insertion order)
  const group1Key = meanKeys[0];
  const group2Key = meanKeys[1];

  // Extract the corresponding arrays using bracket notation.
  // Casting compData as any lets us access properties with dots in their name.
  const group1 = (compData as any)[group1Key] as number[];
  const group2 = (compData as any)[group2Key] as number[];

  // Also extract the foldChange, p-values, and gene symbols
  const foldChange = compData.foldChange as number[];
  const Pval = compData.Pval as number[];
  const FDR = compData.FDR as number[];
  const symbols = compData.hgnc_symbol as string[];

  // Choose which p-value to use
  const selectedPval = filterData.use === 'FDR' ? FDR : Pval;
  const foldChangeThreshold = filterData.fold_change_threshold ?? 2.0;
  const pvalThreshold = filterData.statistical_significance_threshold ?? 0.05;

  // Prepare groups for up regulated, down regulated, and the rest
  // Here, we use group1 for x and group2 for y.
  const upRegulated = {
    x: [] as number[],
    y: [] as number[],
    text: [] as string[],
  };
  const downRegulated = {
    x: [] as number[],
    y: [] as number[],
    text: [] as string[],
  };
  const rest = { x: [] as number[], y: [] as number[], text: [] as string[] };

  // Loop over each gene and classify based on p-value and fold change.
  for (let i = 0; i < group1.length; i++) {
    const fc = foldChange[i];
    const p = selectedPval[i];
    const gene = symbols[i] || 'N/A';
    if (p < pvalThreshold) {
      if (fc > foldChangeThreshold) {
        upRegulated.x.push(group1[i]);
        upRegulated.y.push(group2[i]);
        upRegulated.text.push(gene);
      } else if (fc < -foldChangeThreshold) {
        downRegulated.x.push(group1[i]);
        downRegulated.y.push(group2[i]);
        downRegulated.text.push(gene);
      } else {
        rest.x.push(group1[i]);
        rest.y.push(group2[i]);
        rest.text.push(gene);
      }
    } else {
      rest.x.push(group1[i]);
      rest.y.push(group2[i]);
      rest.text.push(gene);
    }
  }

  // Remove the "mean." prefix to obtain clean group names for the axis labels.
  const group1Name = group1Key.replace('mean.', '');
  const group2Name = group2Key.replace('mean.', '');

  return (
    <div>
      <Plot
        data={[
          {
            x: upRegulated.x,
            y: upRegulated.y,
            mode: 'markers',
            marker: { size: 8, color: '#39F2AE' }, 
            text: upRegulated.text,
            hoverinfo: 'text',
            type: 'scatter',
            name: 'Up regulated',
          },
          {
            x: downRegulated.x,
            y: downRegulated.y,
            mode: 'markers',
            marker: { size: 8, color: '#6E04FE' }, 
            text: downRegulated.text,
            hoverinfo: 'text',
            type: 'scatter',
            name: 'Down regulated',
          },
          {
            x: rest.x,
            y: rest.y,
            mode: 'markers',
            marker: { size: 5, color: 'lightgrey' },
            text: rest.text,
            hoverinfo: 'text',
            type: 'scatter',
            name: 'Rest',
          },
        ]}
        layout={{
          width: 1000,
          height: 700,
          title: title,
          xaxis: {
            title: `${group1Name} mean expression (log₂)`,
          },
          yaxis: {
            title: `${group2Name} mean expression (log₂)`,
          },
          hovermode: 'closest',
        }}
        config={commonConfig}
      />
    </div>
  );
};

export default DotPlot;
