import React from 'react';
import ReactModal from 'react-modal';

interface Service {
  name: string;
  limited: string;
  standard: string;
  advanced: string;
}

interface DetailsProps {
  isOpen: boolean;
  onRequestClose: () => void;
  services: Service[];
}

const Details: React.FC<DetailsProps> = ({ isOpen, onRequestClose, services }) => {
  const renderMark = (mark: string) => {
    if (mark === "✓") {
      return <span className="text-teal-700">{mark}</span>;
    } else if (mark === "×") {
      return <span className="text-orange-600">{mark}</span>;
    } else {
      return mark;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Pricing Plan Details"
      className="absolute inset-0 flex items-center justify-center p-4 bg-gray-700 bg-opacity-50"
      overlayClassName="fixed inset-0 bg-gray-700 bg-opacity-75"
    >
      <div className="relative w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg">
        <button
          className="absolute text-2xl font-bold text-gray-700 top-4 right-6 focus:outline-none"
          onClick={onRequestClose}
        >
          ×
        </button>
        <h2 className="mb-4 text-2xl font-bold text-gray-700">Pricing Plan Details</h2>
        <div className="overflow-x-auto max-h-96">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border border-gray-300">Service</th>
                <th className="px-4 py-2 border border-gray-300">Limited</th>
                <th className="px-4 py-2 border border-gray-300">Standard</th>
                <th className="px-4 py-2 border border-gray-300">Advanced</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index} className="text-center">
                  <td className="px-4 py-2 border border-gray-300">{service.name}</td>
                  <td className="px-4 py-2 border border-gray-300">{renderMark(service.limited)}</td>
                  <td className="px-4 py-2 border border-gray-300">{renderMark(service.standard)}</td>
                  <td className="px-4 py-2 border border-gray-300">{renderMark(service.advanced)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          className="px-4 py-2 mt-4 text-white bg-teal-600 rounded hover:bg-teal-700"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </ReactModal>
  );
};

export default Details;
