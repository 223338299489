import React from 'react';

const GeneList: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="p-6 max-w-lg bg-white rounded-2xl shadow-lg text-center">
        <h1 className="text-2xl font-bold text-gray-800">GeneList</h1>
        <p className="mt-4 text-gray-600">
          This feature is not in your subscription plan.
        </p>
      </div>
    </div>
  );
};

export default GeneList;
