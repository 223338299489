import { Table, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface SampleDataTableProps<T> {
  columns: ColumnsType<T>;
  data: T[];
  onSearch: (value: string) => void;
  downloadTableData: (data: T[], format: string) => void;
  geneTable?: React.ReactNode; // Use React.ReactNode for a component
  dEHelpMark?: React.ReactNode; // Use React.ReactNode for a component
}

const DataTable = <T extends object>({
  columns,
  data,
  onSearch,
  downloadTableData,
  geneTable,
  dEHelpMark,
}: SampleDataTableProps<T>) => {
  return (
    <div className="mt-10">
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-bold"></h2>
        <div className="flex justify-between gap-4 items-center">
          {geneTable && <div className="h-11 customAntDesign">{geneTable}</div>}

          <Input.Search
            placeholder="Search keyword here"
            allowClear
            onSearch={onSearch}
            className="customAntDesign"
            // onChange={onSearch}
          />
          <button
            onClick={() => downloadTableData(data, 'csv')}
            className="text-onex-purple-100 "
          >
            Downloads
          </button>
          {dEHelpMark && <div>{dEHelpMark}</div>}
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: '100%',
        }}
        pagination={{
          pageSize: 20,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        }}
        rowKey="key"
      />
    </div>
  );
};

export default DataTable;
