// ProfileContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import { getProfileApi, getProfilePictureApi } from '../services/ProfileApi';
import defaultPic from '../assets/profile_pic.jpg';

interface ProfileContextType {
  profile: {
    email: string;
    first_name: string;
    last_name: string;
    pfp: string;
    phone_number?:string;
  } | null;
  refetchProfile: () => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profile, setProfile] = useState<ProfileContextType['profile']>(null);

  const fetchProfile = async () => {
    try {
      const profileResponse = await getProfileApi();
      const { first_name, last_name, email ,phone_number} = profileResponse.data;

      let pictureUrl = defaultPic;
      try {
        const pictureResponse = await getProfilePictureApi();
        pictureUrl = URL.createObjectURL(pictureResponse.data);
      } catch (error) {
        console.warn('Error fetching profile picture, using default:', error);
      }

      setProfile({
        email,
        first_name,
        last_name,
        pfp: pictureUrl,
        phone_number,
      });
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <ProfileContext.Provider value={{ profile, refetchProfile: fetchProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
