export const getStatus = (
  status: string,
) => {
  if (status="Ready") {
    return (
      <div className="flex items-center space-x-1 text-gray-500">
        <i className="cursor-pointer fi fi-rr-eye" aria-label="View" />
        <span className="px-2">View</span>
      </div>
    );
  } else if (status="Configured") {
    return (
      <div className="flex items-center space-x-1 text-onex-purple-80">
        <i
          className="cursor-pointer fi fi-rr-file-spreadsheet"
          aria-label="View"
        />
        <span className="px-2">In Progress</span>
      </div>
    );
  } else if (status="Uploaded") {
    return (
      <div className="flex items-center space-x-1 text-gray-500">
        <i className="cursor-pointer fi fi-rr-module" aria-label="Module" />
        <span className="px-2">Configure</span>
      </div>
    );
  } else {
    return (
      <div className="flex items-center space-x-1 text-orange-600">
        <i className="fi fi-rr-cloud-upload" />
        <span className="px-2">Uploading</span>
      </div>
    );
  }
};

export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '48px',
    height: '48px',
    boxShadow: 'none',
    borderRadius: '8px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0px 10px',
    color: '#374548', // Black color for the dropdown arrow
    '&:hover': {
      color: '#374548', // Ensure it stays black on hover
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#374548', // Black color for selected text
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#374548', // Black color for placeholder text
  }),
};


export const getDateRange = (filter: string) => {
  const now = new Date();
  let startDate: Date;
  switch (filter) {
    case 'Today':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      break;
    case 'Past 7 Days':
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
      );
      break;
    case 'Past Month':
      startDate = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
      );
      break;
    case 'Past Year':
      startDate = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate(),
      );
      break;
    default:
      startDate = new Date(0); // A very old date for no filter
  }
  return { startDate, endDate: now };
};
