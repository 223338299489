import React, { createContext, useContext, useState, useEffect } from 'react';
import { getEnvironment } from '../services/loginApi';
import { useLocation } from 'react-router-dom';

interface EnvContextType {
  environmentName: string;
}

const EnvContext = createContext<EnvContextType | undefined>(undefined);

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [environmentName, setEnvironmentName] = useState<string>('');
  const location = useLocation();

  const fetchEnvironmentName = async () => {
    try {
      const response = await getEnvironment();
      console.log('Environment:', response);
      setEnvironmentName(response);
    } catch (error) {
      console.error('Error fetching environment:', error);
      setEnvironmentName('');
    }
  };

  useEffect(() => {
    fetchEnvironmentName();
  }, []);

  useEffect(() => {
    fetchEnvironmentName();
  }, [location]);

  return (
    <EnvContext.Provider value={{ environmentName }}>
      {children}
    </EnvContext.Provider>
  );
};

export const useEnvironment = (): EnvContextType => {
  const context = useContext(EnvContext);
  if (context === undefined) {
    throw new Error(
      'useEnvironment must be used within an EnvironmentProvider',
    );
  }
  return context;
};
