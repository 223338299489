import { ColumnsType } from 'antd/es/table';
import { ISampleInfo } from '../../data/sampleInfo';
import DataTable from './DataTable';
import Plot from 'react-plotly.js';
import { useEffect, useState, useRef } from 'react';
import { GetSampleInfo, SharedWith } from '../../services/vnexPlotApi';
import { useProfile } from '../../contexts/ProfileContext';
import Plotly from 'plotly.js-basic-dist';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BarLoader, ClipLoader } from 'react-spinners';
import Modal from './Modal';
import { DownloadImage } from '../../utils/download';

import { commonConfig } from '../../utils/plotConfig';
import { useQuery } from '@tanstack/react-query';

interface SampleInfoProps {
  projectName: string;
}
const SampleInfo: React.FC<SampleInfoProps> = ({ projectName }) => {
  // Columns definition
  const columns: ColumnsType<ISampleInfo> = [
    // {
    //   title: 'Sr. No.',
    //   dataIndex: 'key',
    //   key: 'key',
    //   sorter: (a, b) => a.key - b.key,
    //   width: '10%',
    // },
    {
      title: 'Sample Name',
      dataIndex: 'sampleName',
      key: 'sampleName',
      sorter: (a, b) => a.sampleName.localeCompare(b.sampleName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => a.group.localeCompare(b.group),
      render: (text: string) => (
        <span
          style={{
            color: text === 'U' ? '#217867' : '#343A40',
            fontWeight: 'bold',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
      sorter: (a, b) => a.batch.localeCompare(b.batch),
    },
    {
      title: 'Outlier',
      dataIndex: 'outlier',
      key: 'outlier',
      sorter: (a, b) => a.outlier.localeCompare(b.outlier),
    },
  ];

  const [data, setData] = useState<ISampleInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<ISampleInfo[]>([]);
  const { profile } = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const groupCounts = data.reduce<Record<string, number>>((acc, item) => {
    acc[item.group] = (acc[item.group] || 0) + 1;
    return acc;
  }, {});
  const plotRef = useRef<any>(null);
  const groups = Object.keys(groupCounts); // ['U', 'P']
  const counts = Object.values(groupCounts); // [30, 24]
  const groupColors = data.reduce<Record<string, string>>((acc, item) => {
    // Only set the color once per group
    if (!acc[item.group] && item.groupcol_intern) {
      acc[item.group] = item.groupcol_intern;
    }
    return acc;
  }, {});
  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? ''; // Ensure email is always a string

  useEffect(() => {
    const fetchData = async () => {
      if (profile) {
        try {
          setLoading(true);
          const response = await GetSampleInfo(projectName, email);

          const formattedData: ISampleInfo[] = response.data.map(
            (item: any, index: number) => ({
              key: index,
              sampleName: item.sample_intern,
              group: item.group,
              batch: item.Batch || '',
              outlier: item.Outlier || '',
              groupcol_intern: item.groupcol_intern,
            }),
          );
          setData(formattedData);
          setFilteredData(formattedData);
          setLoading(false);
        } catch (error: any) {
          setData([]);
          setFilteredData([]);
          console.error('Error fetching meta sample info:', error);
          setLoading(false);
          const errorMessage =
            error?.response?.data?.detail ||
            'An error occurred while fetching data.';
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    };

    projectName && fetchData();
  }, [profile]);
  // Handle search functionality
  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      // If no search term, reset to original data
      setFilteredData(data);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();

      const filtered = data.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerCaseTerm),
        ),
      );

      setFilteredData(filtered);
    }
  };

  const downloadTableData = (
    data: any[] | undefined,
    format: string | undefined,
  ) => {
    if (!data || data.length === 0) {
      console.error('No data available to download');
      return;
    }

    if (format === 'csv') {
      // Generate CSV content
      const headers = Object.keys(data[0]);
      const csvContent = [
        // ['Sr. No.', 'Sample Name', 'Group', 'Batch', 'Outlier'],
        ['Sample Name', 'Group', 'Batch', 'Outlier'],
        // headers, // Add headers
        ...data.map((row: any) => headers.map((header) => row[header])),
      ]
        .map((e) => e.join(',')) // Convert each row to a comma-separated string
        .join('\n'); // Join all rows with a newline

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'Sample_Info.csv');
      link.click();
    } else if (format === 'json') {
      // Generate JSON content
      const jsonContent = JSON.stringify(data, null, 2); // Pretty print JSON
      const blob = new Blob([jsonContent], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'Sample_Info.json');
      link.click();
    } else {
      console.error('Unsupported format:', format);
    }
  };

  return (
    <div>
      <div className="flex justify-between ">
        <p>Number of samples inside each group of interest:</p>
        <div className="flex mr-8 relative">
          <button
            className="text-onex-purple-80 mr-2"
            onClick={() => DownloadImage(plotRef, 'sampleInfo')}
          >
            {loading ? (
              <div>
                <BarLoader width={50} color="#39F2AE" />
                {/* Loader component */}
              </div>
            ) : (
              'Download'
            )}
          </button>
          <button onClick={() => setIsModalOpen(true)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                stroke="#374548"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <Modal
            open={isModalOpen}
            title="Help"
            description={
              <>
                Barplot highlighting the number of samples in each group.
                <br />
                <br />
                <strong>x-axis:</strong> Groups of interest as defined in the
                configuration process.
                <br />
                <strong>y-axis:</strong> Number of samples.
              </>
            }
            onClose={() => setIsModalOpen(false)}
          />
          <div id="modal-root"></div>
        </div>
      </div>

      <div className="ml-5 my-20">
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <ClipLoader color="#39F2AE" size={50} />
          </div>
        ) : (
          <div className="my-10 ml-20" ref={plotRef}>
            <Plot
              data={groups.map((group) => ({
                x: [group],
                y: [groupCounts[group]],
                type: 'bar',
                marker: {
                  color: groupColors[group], // Use the dynamic color here
                },
                name: group,
                hovertemplate: '%{x}: %{y} samples<extra></extra>',
              }))}
              layout={{
                xaxis: { title: '' },
                yaxis: { title: 'Number of samples', dtick: 1 },
                showlegend: false,
                margin: { t: 40, l: 60, r: 20, b: 40 },
                height: 400,
                width: 600,
                colorway: Object.values(groupColors),
              }}
              config={commonConfig}
            />
            <ToastContainer />
          </div>
        )}
      </div>
      <p>Sample metadata:</p>
      <DataTable
        columns={columns}
        data={filteredData}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default SampleInfo;
