import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

interface Group {
  groupName: string;
  color: string;
}

interface ComparisonFormProps {
  groups: Group[];
  addComparison: (interest: string, reference: string) => void;
  error: string;
  interest: string;
  setInterest: React.Dispatch<React.SetStateAction<string>>;
  reference: string;
  setReference: React.Dispatch<React.SetStateAction<string>>;
}

const ComparisonForm: React.FC<ComparisonFormProps> = ({ interest, setInterest ,reference , setReference ,groups, addComparison, error }) => {
  

  const handleAddComparison = () => {
    addComparison(interest, reference);
    setInterest('');
    setReference('');
  };

  const swapValues = () => {
    const temp = interest;
    setInterest(reference);
    setReference(temp);
  };

  return (
    <div className="mb-8 mr-64">
      <label className="block mb-2 text-sm font-medium text-gray-700">Comparisons</label>
      <div className="flex items-center space-x-4">
        <Box display="flex" flexGrow={1} alignItems="center">
          <FormControl fullWidth variant="outlined" className="peer" sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: 'orange',
              },
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            }
          }}>
            <InputLabel>Interest</InputLabel>
            <Select
              value={interest}
              onChange={(e) => setInterest(e.target.value)}
              label="Interest"
            >
              <MenuItem value="" disabled>Select interest</MenuItem>
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.groupName} sx={{ color: group.color }}>
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <button style={{ margin: '0 -0.5rem' }} className="z-20 text-4xl text-onex-purple-80" onClick={swapValues}>⇆</button>

          <FormControl fullWidth variant="outlined" className="peer" sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: 'orange',
              },
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            }
          }}>
            <InputLabel>Reference</InputLabel>
            <Select
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              label="Reference"
            >
              <MenuItem value="" disabled>Select reference</MenuItem>
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.groupName} sx={{ color: group.color }}>
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddComparison}
          sx={{
            paddingTop: '0.9rem',
            paddingBottom: '0.9rem',
            paddingRight: '0.9rem',
            paddingLeft: '0.9rem',
            fontSize: '1rem',
            backgroundColor: '#2f3b52',
            '&:hover': { backgroundColor: '#3f4b62' }
          }}
        >
          Add Comparison
        </Button>
      </div>
      {error && <Typography color="error" mt={2}>{error}</Typography>}
    </div>
  );
};

export default ComparisonForm;
