import { useState } from 'react';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { getProfileApi } from '../services/ProfileApi';
import { GetUserSubscription, GetLocalSubscription } from '../services/subscriptionApi';
import { SelectedFile, SelectedURLfile, FtpDetails } from '../types/upload';
import { useEnvironment } from '../utils/EnvContext';
import { getSubscriptionLimits } from '../utils/subscriptionLimits';

export const useUploadValidation = () => {
  const [isLocalLicenseValid, setIsLocalLicenseValid] = useState<boolean>();
  const [isProjectsLimitValid, setIsProjectsLimitValid] = useState<boolean>(true);
  const [isStorageLimitValid, setIsStorageLimitValid] = useState<{ isWithinLimit: boolean, exceededAmount: any }>({ isWithinLimit: true, exceededAmount: 0 });
  const { environmentName } = useEnvironment();
  const navigate = useNavigate();

  const checkProjectAndStorageLimits = async (
    selectedFiles: SelectedFile[],
    selectedUrlFiles: SelectedURLfile[],
    ftpDetails: FtpDetails[]
  ) => {
    if (environmentName && environmentName !== 'local') {
      const [subscriptionResponse, profileResponse] = await Promise.all([
        GetUserSubscription(),
        getProfileApi()
      ]);

      const subscription = subscriptionResponse.data;
      const profile = profileResponse.data;
      const limits = getSubscriptionLimits(subscription?.item_price_name || 'Free');

      // Project limit check
      if (profile.num_projects >= limits.maxProjects) {
        setIsProjectsLimitValid(false);
      }
      else {
        setIsProjectsLimitValid(true);
      }

      // Storage limit check
      const calculateTotalSize = (files: Array<SelectedFile | SelectedURLfile | FtpDetails>) =>
        files.reduce((acc, file) => acc + (
          'file' in file ? file.file.size :
            'filesize' in file ? file.filesize :
              file.file_size
        ), 0) / (1024 * 1024);

      const totalFileSizeMB = calculateTotalSize([
        ...selectedFiles,
        ...selectedUrlFiles,
        ...ftpDetails
      ]);

      const maxStorageMB = limits.maxDataGB * 1024;
      const totalDataMB = profile.total_data;

      if (totalDataMB + totalFileSizeMB > maxStorageMB) {
        const exceededAmountGB = ((totalDataMB + totalFileSizeMB - maxStorageMB) / 1024).toFixed(2);

        await Swal.fire({
          title: 'Storage Limit Exceeded',
          text: `You have exceeded your data usage by ${exceededAmountGB} GB. Please upgrade your plan to proceed.`,
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Upgrade Plan',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/account');
          }
        });
        setIsStorageLimitValid({ isWithinLimit: false, exceededAmount: exceededAmountGB });

      }
      else {
        setIsStorageLimitValid({ isWithinLimit: true, exceededAmount: 0 });
      }
    }

    return true;
  };

  const checkLocalLicense = async () => {
    if (environmentName === 'local') {
      try {
        const response = await GetLocalSubscription();
        setIsLocalLicenseValid(response.data.message === 'License is valid.');
      } catch (err) {
        setIsLocalLicenseValid(false);
      }
    }
  };

  return {
    checkProjectAndStorageLimits,
    checkLocalLicense,
    isLocalLicenseValid,
    isProjectsLimitValid,
    isStorageLimitValid
  };
};