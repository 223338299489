import React from 'react';
import { Profile } from '../../types/profile';

interface PersonalInfoProps {
  profile: Profile;
  onOpenDialog: () => void;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ profile, onOpenDialog }) => {
  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold text-gray-700">
        Personal Information
      </h2>
      <div className="p-4 border border-gray-300 rounded-lg">
        <div className="flex flex-wrap items-center justify-between space-x-10">
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">First Name</label>
            <span className="block font-semibold text-gray-700">
              {profile.first_name}
            </span>
          </div>
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">Last Name</label>
            <span className="block font-semibold text-gray-700">
              {profile.last_name}
            </span>
          </div>
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">Position</label>
            <span className="block font-semibold text-gray-700">
              {profile.position}
            </span>
          </div>
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">Institution</label>
            <span className="block font-semibold text-gray-700">
              {profile.institution}
            </span>
          </div>
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">Location</label>
            <span className="block font-semibold text-gray-700">
              {profile.location}
            </span>
          </div>
          <div className="flex-shrink text-xs">
            <label className="block mb-2 text-gray-500">Email</label>
            <span className="block font-semibold text-gray-700">
              {profile.email}
            </span>
          </div>
          <div className="flex flex-shrink space-x-10 ">
            <div>
              <label className="block mb-2 text-xs text-gray-500">Phone Number</label>
              <span className="block text-xs font-semibold text-gray-700">
                {profile.phone_number}
              </span>
            </div>
            <button
              className="px-4 py-2 font-semibold text-white bg-teal-700 rounded "
              onClick={onOpenDialog}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
