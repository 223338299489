import React, { useState, useEffect } from 'react';

interface ShareProjectProps {
  isOpen: boolean;
  onClose: () => void;
  onShare: (projectNames: string[], emails: string[]) => void;
  projectNames: string[];
}

const ShareProject: React.FC<ShareProjectProps> = ({
  isOpen,
  onClose,
  onShare,
  projectNames,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && emailInput) {
      if (validateEmail(emailInput)) {
        setEmails([...emails, emailInput]);
        setEmailInput('');
        setError(null);
      } else {
        setError('Please enter a valid email address.');
      }
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleShare = () => {
    if (emailInput) {
      setError('Please press Enter to add the email before sharing.');
      return;
    }

    if (emails.length === 0) {
      setError('Please enter at least one email address.');
      return;
    }

    onShare(projectNames, emails);
    onClose();
  };

  // Reset values when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setEmails([]);
      setEmailInput('');
      setError(null);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-xl p-6 bg-white rounded-lg shadow-lg">
        <button
          className="absolute text-4xl text-gray-500 top-2 right-4 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="mb-6 font-sans text-5xl font-bold text-left text-gray-700 ">
          Share Project
        </h2>
        <p className="mb-6 text-left text-gray-700">
          Enter the email addresses you would like to share the projects with.
        </p>
        <div className="mb-4">
          {emails.map((email) => (
            <span
              key={email}
              className="inline-block px-2 py-1 mb-2 mr-2 text-gray-800 bg-gray-100 border border-gray-300 rounded"
            >
              {email}
              <button
                onClick={() => handleRemoveEmail(email)}
                className="ml-2 text-red-600 hover:text-red-800"
              >
                &times;
              </button>
            </span>
          ))}

          <input
            type="email"
            placeholder="Enter email and press Enter"
            className="w-full px-4 py-2 mt-2 text-lg border border-gray-300 rounded"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyDown={handleAddEmail}
          />
          {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        </div>
        <button
          onClick={handleShare}
          className="w-full px-4 py-3 text-lg text-white bg-teal-700 rounded hover:bg-teal-800"
        >
          Send Invite
        </button>
      </div>
    </div>
  );
};

export default ShareProject;
