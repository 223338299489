import { Form, Select, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import {
  GetGeneCorrelationData,
  GetGeneList,
  SharedWith,
} from '../../../services/vnexPlotApi';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import { GeneCorrelationData } from '../../../data/CONSTANT';
import Modal from '../Modal';
import { DownloadImage } from '../../../utils/download';
import { commonConfig } from '../../../utils/plotConfig';
import { ClipLoader, BarLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

const { Option } = Select;
const { Title } = Typography;

export interface IfilterGeneCorrelation {
  genes: string[];
  gene1: string;
  gene2: string;
  groups: string;
  scale: string;
}

interface GeneCorrelationProps {
  projectName: string;
}

// Updated interface: now includes properties required to group and plot the data.
interface PlotDataItem {
  group: string;
  gene1: number | string;
  gene2: number | string;
  group_color: string;
  sample_name: string;
}

/* ─── GeneCorrelationPlotComp ──────────────────────────────────────────────
   This component receives:
     - gene1: an array of numbers (x-axis values)
     - gene2: an array of numbers (y-axis values)
     - geneName1: the name of the gene used for the x-axis
     - geneName2: the name of the gene used for the y-axis
     - plot_data: additional info for markers (colors, sample names, group info)
     
   It computes the Pearson correlation coefficient and best-fit line and
   renders the Plotly chart with axis titles that display the gene names.
   In the "together" plot, a separate trace is created for each group so that
   the legend displays one dot per group with the proper color.
----------------------------------------------------------------------------- */
const GeneCorrelationPlotComp: React.FC<{
  gene1: number[];
  gene2: number[];
  geneName1: string;
  geneName2: string;
  plot_data: PlotDataItem[];
}> = ({ gene1, gene2, geneName1, geneName2, plot_data }) => {
  // Helper: Calculate Pearson correlation coefficient.
  const calculatePearsonCorrelation = (x: number[], y: number[]): number => {
    const n = x.length;
    const sumX = x.reduce((acc, cur) => acc + cur, 0);
    const sumY = y.reduce((acc, cur) => acc + cur, 0);
    const sumXY = x.reduce((acc, cur, i) => acc + cur * y[i], 0);
    const sumX2 = x.reduce((acc, cur) => acc + cur * cur, 0);
    const sumY2 = y.reduce((acc, cur) => acc + cur * cur, 0);
    const numerator = n * sumXY - sumX * sumY;
    const denominator = Math.sqrt(
      (n * sumX2 - sumX * sumX) * (n * sumY2 - sumY * sumY),
    );
    return denominator === 0 ? 0 : numerator / denominator;
  };

  // Helper: Compute best-fit (linear regression) line data.
  const getBestFitLine = (x: number[], y: number[]) => {
    const n = x.length;
    const sumX = x.reduce((acc, cur) => acc + cur, 0);
    const sumY = y.reduce((acc, cur) => acc + cur, 0);
    const sumXY = x.reduce((acc, cur, i) => acc + cur * y[i], 0);
    const sumX2 = x.reduce((acc, cur) => acc + cur * cur, 0);
    const denominator = n * sumX2 - sumX * sumX;
    if (denominator === 0) return null;
    const slope = (n * sumXY - sumX * sumY) / denominator;
    const intercept = (sumY - slope * sumX) / n;
    const minX = Math.min(...x);
    const maxX = Math.max(...x);
    return {
      x: [minX, maxX],
      y: [slope * minX + intercept, slope * maxX + intercept],
    };
  };

  const correlation = calculatePearsonCorrelation(gene1, gene2);
  const bestFit = getBestFitLine(gene1, gene2);

  // Build one scatter trace per group for correct legend display.
  const uniqueGroups = Array.from(
    new Set(plot_data.map((item) => item.group)),
  ) as string[];
  const traces: Partial<Plotly.PlotData>[] = uniqueGroups.map((group) => {
    const groupData = plot_data.filter((item) => item.group === group);
    return {
      x: groupData.map((item) => Number(item.gene1)),
      y: groupData.map((item) => Number(item.gene2)),
      type: 'scatter',
      mode: 'markers',
      marker: {
        color: groupData[0].group_color, // assume all points in a group share the same color
        size: 15,
      },
      text: groupData.map((item) => item.sample_name),
      hovertemplate: 'Sample: %{text}<extra></extra>',
      name: group,
    };
  });

  // Add best-fit line trace (computed over all points).
  if (bestFit) {
    traces.push({
      x: bestFit.x,
      y: bestFit.y,
      type: 'scatter',
      mode: 'lines',
      line: { color: 'red', width: 5 },
      name: 'Best Fit Line',
    });
  }

  return (
    <div>
      <Plot
        data={traces}
        layout={{
          xaxis: { title: `${geneName1} Expression` },
          yaxis: { title: `${geneName2} Expression` },
          width: 1000,
          height: 700,
          showlegend: true,
        }}
        config={commonConfig}
      />
    </div>
  );
};

/* ─── Main Page Component ─────────────────────────────────────────────────────
   This component fetches the gene list and correlation data, handles filters,
   and conditionally renders a correlation plot (together or separated).
----------------------------------------------------------------------------- */
const GeneCorrelation: React.FC<GeneCorrelationProps> = ({ projectName }) => {
  const [genes, setGenes] = useState<string[]>([]);
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const plotRef = useRef<any>();

  const initialFilterData: IfilterGeneCorrelation = {
    genes: genes,
    gene1: genes[0],
    gene2: genes[1],
    groups: 'together',
    scale: 'log2',
  };
  const [filterData, setFilterData] =
    useState<IfilterGeneCorrelation>(initialFilterData);

  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? '';
  const handleChange = (
    value: string | number | string[] | null,
    name: string,
  ) => {
    if (
      (typeof value === 'string' ||
        Array.isArray(value) ||
        typeof value === 'number' ||
        value === null) &&
      name
    ) {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const { data: dataGetGeneList, isLoading: GetGeneListLoading } = useQuery({
    queryKey: ['GetGeneList'],
    queryFn: () => GetGeneList(projectName, email),
  });

  useEffect(() => {
    if (dataGetGeneList?.data) {
      const fetchedData = dataGetGeneList?.data || [];
      setGenes(dataGetGeneList?.data);
      setFilterData((prev) => ({
        ...prev,
        genes: fetchedData.length ? fetchedData[0] : '',
        gene1: fetchedData.length ? fetchedData[0] : '',
        gene2: fetchedData.length ? fetchedData[1] : '',
      }));
    }
  }, [dataGetGeneList]);

  const { data: dataGeneCorrelation, isLoading: GeneCorrelationLoading } =
    useQuery({
      queryKey: ['GeneCorrelation', filterData, projectName, email],
      queryFn: () => GetGeneCorrelationData(filterData, email, projectName),
      enabled: filterData.genes?.length > 0,
    });

  const table_data = dataGeneCorrelation?.data?.table_data;
  const plot_data = dataGeneCorrelation?.data?.plot_data || [];
  // Convert gene values to numbers (if they are strings)
  const gene1Raw = plot_data?.map((item: any) => item?.gene1);
  const gene2Raw = plot_data?.map((item: any) => item?.gene2);
  const gene1 = gene1Raw ? gene1Raw.map((g: any) => Number(g)) : [];
  const gene2 = gene2Raw ? gene2Raw.map((g: any) => Number(g)) : [];

  useEffect(() => {
    if (table_data && table_data.length > 0) {
      setSearchResults(table_data); // Sync search results with table data
    }
  }, [table_data]);

  const columns: ColumnsType<IfilterGeneCorrelation> = [
    { title: 'Gene1', dataIndex: 'Gene1', key: 'Gene1' },
    { title: 'Gene2', dataIndex: 'Gene2', key: 'Gene2' },
    { title: 'Method', dataIndex: 'Method', key: 'Method' },
    { title: 'Estimate', dataIndex: 'Estimate', key: 'Estimate' },
    { title: 'Pvalue', dataIndex: 'Pvalue', key: 'Pvalue' },
  ];

  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      setSearchResults(table_data);
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filtered = table_data.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerCaseTerm),
        ),
      );
      setSearchResults(filtered);
    }
  };

  const downloadTableData = (data: any, format: string) => {
    if (format === 'csv') {
      const csvContent = [
        ['Gene1', 'Gene2', 'Method', 'Estimate', 'Pvalue'],
        ...data.map((row: any) => [
          row.Gene1,
          row.Gene2,
          row.Method,
          row.Estimate,
          row.Pvalue,
        ]),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `Gene_Correlation${filterData.groups}.csv`);
      link.click();
    }
  };

  const modalTitle =
    filterData.groups === 'together'
      ? GeneCorrelationData.ToGether.title
      : GeneCorrelationData.Seperated.title;
  const modalDescription =
    filterData.groups === 'together'
      ? GeneCorrelationData.ToGether.description
      : GeneCorrelationData.Seperated.description;
  const GenePlots = () => {
    if (filterData.groups === 'together') {
      return (
        <div ref={plotRef}>
          <GeneCorrelationPlotComp
            gene1={gene1}
            gene2={gene2}
            plot_data={plot_data}
            geneName1={filterData.gene1}
            geneName2={filterData.gene2}
          />
        </div>
      );
    } else if (filterData.groups === 'separated') {
      const uniqueGroups = Array.from(
        new Set(plot_data.map((item: any) => item.group)),
      ) as string[];
      return (
        <>
          {uniqueGroups.map((group: string) => {
            // Filter the plot_data for the current group.
            const groupData = plot_data.filter(
              (item: any) => item.group === group,
            );
            // Convert gene values to numbers.
            const gene1Group = groupData.map((item: any) => Number(item.gene1));
            const gene2Group = groupData.map((item: any) => Number(item.gene2));
            return (
              <div ref={plotRef} key={group} style={{ marginBottom: '40px' }}>
                {/* Display the group name above the plot */}
                {/* <h2 style={{ textAlign: 'center' }}>{group}</h2> */}
                <GeneCorrelationPlotComp
                  gene1={gene1Group}
                  gene2={gene2Group}
                  geneName1={filterData.gene1}
                  geneName2={filterData.gene2}
                  plot_data={groupData}
                />
              </div>
            );
          })}
        </>
      );
    } else {
      return <h1>Plot type not recognized</h1>;
    }
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div
          style={{ padding: '16px', maxWidth: '300px' }}
          className="border-r border-b border-dashed w-1/4"
        >
          {GetGeneListLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} />
            </div>
          ) : (
            <div>
              <Form.Item label="Select First Gene" colon={false}>
                <Select
                  showSearch
                  value={filterData.gene1}
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'gene1')}
                  placeholder="Select an option"
                >
                  {genes.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Select Second Gene" colon={false}>
                <Select
                  showSearch
                  value={filterData.gene2}
                  style={{ width: '100%' }}
                  onChange={(value) => handleChange(value, 'gene2')}
                  placeholder="Select an option"
                >
                  {genes.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <Form.Item label="Groups" colon={false}>
            <Select
              value={filterData.groups}
              defaultValue={filterData.groups}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'groups')}
            >
              <Option value="together">Together</Option>
              <Option value="separated">Separated</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Scale" colon={false}>
            <Select
              defaultValue={filterData.scale}
              value={filterData.scale}
              style={{ width: '100%' }}
              onChange={(value) => handleChange(value, 'scale')}
            >
              <Option value="log2">log2</Option>
              <Option value="linear">linear</Option>
            </Select>
          </Form.Item>
        </div>

        <div className="flex-1 flex flex-col items-center p-4">
          <div className="flex justify-between w-full mb-4">
            <h3 className="font-semibold text-black">{filterData.scale}</h3>
            <div className="flex mr-8 relative ">
              <button
                className="text-onex-purple-80 mr-2"
                onClick={() =>
                  DownloadImage(plotRef, `GeneCorrelation_${filterData.scale}`)
                }
              >
                {GeneCorrelationLoading ? (
                  <div>
                    <BarLoader width={50} color="#39F2AE" />
                  </div>
                ) : (
                  'Download'
                )}
              </button>
              <button onClick={() => setIsModalOpen(true)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                    stroke="#374548"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <Modal
                open={isModalOpen}
                title="Help"
                description={
                  <>
                    Gene correlation plot highlighting the correlation between
                    the two selected genes.
                    <br />
                    <br />
                    <strong>x-axis:</strong> First selected gene.
                    <br />
                    <strong>y-axis:</strong> Second selected gene.
                    <br />
                    <br />
                    <strong>Explanation:</strong> Regression estimate is plotted
                    as a red line. If the option Groups is set to 'Separated',
                    one plot for each group of interest is shown
                  </>
                }
                onClose={() => setIsModalOpen(false)}
              />
              <div id="modal-root"></div>
            </div>
          </div>
          {GeneCorrelationLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <ClipLoader color="#39F2AE" size={50} />
            </div>
          ) : (
            <div className="w-full text-center">{GenePlots()}</div>
          )}
        </div>
      </div>
      <ToastContainer />
      <DataTable
        columns={columns}
        data={searchResults}
        onSearch={handleSearch}
        downloadTableData={downloadTableData}
      />
    </div>
  );
};

export default GeneCorrelation;
