import React, { useState, useEffect } from 'react';
import NotAuthorized from './NotAuthorized';
import { GetLocalSubscription } from '../services/subscriptionApi';
import { useEnvironment } from '../utils/EnvContext';
import { ClipLoader } from 'react-spinners';

const withAuthorization = (WrappedComponent: React.ComponentType) => {
  return (props: any) => {
    const { environmentName } = useEnvironment();
    const [isLocalLisenceValid, setIsLocalLisenceValid] = useState<
      boolean | undefined
    >(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchLocalLicenseValidity = async () => {
        if (environmentName === 'local') {
          try {
            // Replace this with your actual API call or logic to check license validity
            const isValid = await GetLocalSubscription();
            setIsLocalLisenceValid(
              isValid.data.message === 'License is valid.',
            );
          } catch (error) {
            console.error('Error checking local license validity:', error);
            setIsLocalLisenceValid(false);
          }
        } else {
          environmentName && setIsLocalLisenceValid(true); // Reset when environment is not local
        }
        setIsLoading(false);
      };

      fetchLocalLicenseValidity();
    }, [environmentName]);

    if (isLocalLisenceValid === undefined) {
      return (
        <div className="flex items-center justify-center w-full h-64">
          <ClipLoader size={50} color={'#123abc'} loading={isLoading} />
        </div>
      );
    }
    if (environmentName === 'local' && isLocalLisenceValid === false) {
      return <NotAuthorized />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthorization;
