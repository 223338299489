import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from '../../contexts/ProfileContext';
import Plot from 'react-plotly.js';
import { toast, ToastContainer } from 'react-toastify';
import { commonConfig } from '../../utils/plotConfig';
import { DownloadImage } from '../../utils/download';
import { QualityControlData, SharedWith } from '../../services/vnexPlotApi';
import Modal from './Modal';
import { BarLoader } from 'react-spinners';
import { useQuery } from '@tanstack/react-query';

interface DifferentialExpressionProps {
  projectName: string;
}

const QualityControl: React.FC<DifferentialExpressionProps> = ({
  projectName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { profile } = useProfile();
  // const email = profile ? profile.email : '';
  const [data, setData] = useState<any>();
  const plotRefReadPerSample = useRef<any>();
  const plotRefPerBaseQuality = useRef<any>();
  const plotRefSequenceDuplication = useRef<any>();
  const plotRefDetectedGenes = useRef<any>(); // Ref for detected genes plot
  const plotRefNormAft = useRef<any>(); // Ref for "Expression before normalisation" plot
  const plotRefNormAfter = useRef<any>(); // New ref for "Expression after normalisation" plot

  interface SharedWithResponse {
    data?: {
      shared_by_user_email?: string;
    };
  }
  const {
    data: dataSharedWith,
    isLoading: dataSharedWithLoading,
    isSuccess: dataSharedWithIsSuccess,
  } = useQuery<SharedWithResponse>({
    queryKey: ['dataSharedWith', profile?.email],
    queryFn: () => SharedWith(projectName, profile?.email ?? ''),
  });
  const ownerEmail = dataSharedWith?.data?.shared_by_user_email;
  const email: string = ownerEmail ?? profile?.email ?? ''; // Ensure email is always a string

  useEffect(() => {
    const QualityControlDataRequest = async () => {
      try {
        const response = (await QualityControlData(email, projectName)) || [];
        setData(response.data);
        console.log(response.data);
      } catch (error: any) {
        setData([]);
        const errorMessage =
          error?.response?.data?.detail ||
          'An error occurred while fetching data.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
    QualityControlDataRequest();
  }, [email, projectName]);

  // Prepare data for the bar plot in "Number of sequencing reads per sample"
  const perSequenceGroupSamples = data?.seq_reads?.samples || [];
  const perSequencenRead = data?.seq_reads?.nReads_per_million || [];
  const groupsList = data?.seq_reads?.group || [];
  const colors = data?.seq_reads?.group_color || [];

  const QualityControlPlots = () => {
    // Group data by group name for the "Number of sequencing reads per sample" section
    const groupedData: {
      [key: string]: { samples: any[]; reads: any[]; color: string };
    } = {};

    perSequenceGroupSamples.forEach((sample: any, i: number) => {
      const group = groupsList[i];
      if (!groupedData[group]) {
        groupedData[group] = { samples: [], reads: [], color: colors[i] };
      }
      groupedData[group].samples.push(sample);
      groupedData[group].reads.push(perSequencenRead[i]);
    });

    const barTraces = Object.entries(groupedData).map(([group, groupData]) => ({
      x: groupData.samples,
      y: groupData.reads,
      type: 'bar' as const,
      name: group,
      marker: { color: groupData.color },
    }));

    // Prepare traces for "Per base quality"
    const perBaseQualityData = data?.per_base_quality;
    const perBaseQualityTraces: any[] = [];
    if (perBaseQualityData) {
      Object.entries(perBaseQualityData).forEach(
        ([sampleName, qualityArray]) => {
          if (Array.isArray(qualityArray)) {
            perBaseQualityTraces.push({
              x: qualityArray.map((item: any) => Number(item.Base)),
              y: qualityArray.map((item: any) => Number(item.Mean)),
              mode: 'lines',
              name: sampleName,
            });
          }
        },
      );
    }

    // Prepare traces for "Sequence Duplication"
    const sequenceDuplicationData = data?.sequence_duplication;
    const sequenceDuplicationTraces: any[] = [];
    // Extract sample names from per base quality so the names match and remain in the same order.
    const sampleNames = data?.per_base_quality
      ? Object.keys(data.per_base_quality)
      : [];

    if (sequenceDuplicationData && Array.isArray(sequenceDuplicationData)) {
      sequenceDuplicationData.forEach(
        (sampleData: any, sampleIndex: number) => {
          if (Array.isArray(sampleData) && sampleData.length > 1) {
            const dataRows = sampleData.slice(1);
            const x = dataRows.map((row: any) =>
              Number(row['#Total Deduplicated Percentage']),
            );
            const y = dataRows.map((row: any) =>
              Number(row['#Total Deduplicated Percentage.1']),
            );
            // Use the sample name from per_base_quality if available.
            const sampleName =
              sampleNames[sampleIndex] || `Sample ${sampleIndex + 1}`;
            sequenceDuplicationTraces.push({
              x,
              y,
              mode: 'lines+markers',
              name: sampleName,
            });
          }
        },
      );
    }

    // Prepare data for "Number of detected genes" section
    let detectedGenesTraces: any[] = [];
    const detectedGenes = data?.detect_genes;
    if (detectedGenes) {
      const samples = detectedGenes.samples || [];
      const counts = detectedGenes.cnts || [];
      const groups = detectedGenes.group || [];
      const groupColors = detectedGenes.groupcol_intern || [];

      // Group data by group name to add a legend with the corresponding colors
      const detectedGenesGrouped: {
        [key: string]: { samples: string[]; counts: number[]; color: string };
      } = {};

      samples.forEach((s: string, i: number) => {
        const group = groups[i];
        if (!detectedGenesGrouped[group]) {
          detectedGenesGrouped[group] = {
            samples: [],
            counts: [],
            color: groupColors[i],
          };
        }
        detectedGenesGrouped[group].samples.push(s);
        detectedGenesGrouped[group].counts.push(counts[i]);
      });

      detectedGenesTraces = Object.entries(detectedGenesGrouped).map(
        ([group, groupData]) => ({
          x: groupData.samples,
          y: groupData.counts,
          type: 'bar' as const,
          name: group,
          marker: { color: groupData.color },
        }),
      );
    }

    // Prepare traces for "Expression before normalisation" section using norm_bef data
    let normAftTraces: any[] = [];
    const normAft = data?.norm_bef;
    if (normAft && normAft.samples && normAft.samples.length > 0) {
      // Group the samples by their group label
      const uniqueGroups = Array.from(new Set(normAft.group));
      uniqueGroups.forEach((group) => {
        // Get indices of samples belonging to the current group
        const indices = normAft.group
          .map((g: string, i: number) => (g === group ? i : -1))
          .filter((i: number) => i !== -1);
        // Collect data for each sample in the group
        const samples = indices.map((i: number) => normAft.samples[i]);
        const low = indices.map((i: number) => normAft.values[0][i]); // minimum
        const open = indices.map((i: number) => normAft.values[1][i]); // lower hinge
        const median = indices.map((i: number) => normAft.values[2][i]); // median
        const close = indices.map((i: number) => normAft.values[3][i]); // upper hinge
        const high = indices.map((i: number) => normAft.values[4][i]); // maximum
        const color = normAft.groupcol_intern[indices[0]]; // use group color

        // Create a candlestick trace for the box
        normAftTraces.push({
          x: samples,
          low: low,
          open: open,
          close: close,
          high: high,
          type: 'candlestick',
          increasing: { line: { color: color } },
          decreasing: { line: { color: color } },
          name: group,
          showlegend: true,
        });
        // Overlay a scatter trace for the median points
        normAftTraces.push({
          x: samples,
          y: median,
          mode: 'markers',
          marker: { color: color, size: 8 },
          name: `${group} median`,
          showlegend: false,
          type: 'scatter',
        });
      });
    }

    // Prepare traces for "Expression after normalisation" section using norm_aft data
    let normAftAfterTraces: any[] = [];
    const normAftAfter = data?.norm_aft;
    if (
      normAftAfter &&
      normAftAfter.samples &&
      normAftAfter.samples.length > 0
    ) {
      // Group the samples by their group label
      const uniqueGroupsAfter = Array.from(new Set(normAftAfter.group));
      uniqueGroupsAfter.forEach((group) => {
        // Get indices of samples belonging to the current group
        const indices = normAftAfter.group
          .map((g: string, i: number) => (g === group ? i : -1))
          .filter((i: number) => i !== -1);
        // Collect data for each sample in the group
        const samples = indices.map((i: number) => normAftAfter.samples[i]);
        const low = indices.map((i: number) => normAftAfter.values[0][i]); // minimum
        const open = indices.map((i: number) => normAftAfter.values[1][i]); // lower hinge
        const median = indices.map((i: number) => normAftAfter.values[2][i]); // median
        const close = indices.map((i: number) => normAftAfter.values[3][i]); // upper hinge
        const high = indices.map((i: number) => normAftAfter.values[4][i]); // maximum
        const color = normAftAfter.groupcol_intern[indices[0]]; // use group color

        // Create a candlestick trace for the box
        normAftAfterTraces.push({
          x: samples,
          low: low,
          open: open,
          close: close,
          high: high,
          type: 'candlestick',
          increasing: { line: { color: color } },
          decreasing: { line: { color: color } },
          name: group,
          showlegend: true,
        });
        // Overlay a scatter trace for the median points
        normAftAfterTraces.push({
          x: samples,
          y: median,
          mode: 'markers',
          marker: { color: color, size: 8 },
          name: `${group} median`,
          showlegend: false,
          type: 'scatter',
        });
      });
    }

    return (
      <>
        {/* "Number of sequencing reads per sample" section */}
        {data?.seq_reads && perSequenceGroupSamples.length > 0 && (
          <>
            <div className="flex justify-between">
              <h3 className="text-xl font-semibold">
                Number of sequencing reads per sample
              </h3>
              <div className="flex mr-8 relative">
                <button
                  className="text-onex-purple-80 mr-2"
                  onClick={() =>
                    DownloadImage(
                      plotRefReadPerSample,
                      'Quality_Control_Sequence_Read_Per_Sample',
                    )
                  }
                >
                  Download
                </button>
                <button onClick={() => setIsModalOpen(true)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                      stroke="#374548"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <Modal
                  open={isModalOpen}
                  title="Read Per Sample"
                  description="Read Per Sample"
                  onClose={() => setIsModalOpen(false)}
                />
                <div id="modal-root"></div>
              </div>
            </div>
            <div ref={plotRefReadPerSample} className="w-full text-center">
              <Plot
                data={barTraces}
                layout={{
                  width: 1000,
                  height: 500,
                  showlegend: true,
                  yaxis: {
                    title: 'Num sequencing reads (million)',
                  },
                }}
                config={commonConfig}
              />
            </div>
          </>
        )}

        {/* "Per base quality" section */}
        {data?.per_base_quality &&
          Object.keys(data.per_base_quality).length > 0 && (
            <>
              <div className="flex justify-between">
                <h3 className="text-xl font-semibold">Per base quality</h3>

                <div className="flex mr-8 relative">
                  <button
                    className="text-onex-purple-80 mr-2"
                    onClick={() =>
                      DownloadImage(
                        plotRefPerBaseQuality,
                        'Quality_Control_Per_Base_Quality',
                      )
                    }
                  >
                    Download
                  </button>
                  <button onClick={() => setIsModalOpen(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                        stroke="#374548"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    open={isModalOpen}
                    title="Per Base Quality"
                    description="Per Base Quality"
                    onClose={() => setIsModalOpen(false)}
                  />
                  <div id="modal-root"></div>
                </div>
              </div>
              <div ref={plotRefPerBaseQuality} className="w-full text-center">
                <Plot
                  data={perBaseQualityTraces}
                  layout={{
                    width: 1000,
                    height: 500,
                    showlegend: true,
                    xaxis: {
                      title: 'Position in read (bp)',
                    },
                    yaxis: {
                      title: 'Mean Quality',
                    },
                  }}
                  config={commonConfig}
                />
              </div>
            </>
          )}

        {/* "Sequence Duplication" section */}
        {data?.sequence_duplication &&
          Array.isArray(data.sequence_duplication) &&
          data.sequence_duplication.length > 0 && (
            <>
              <div className="flex justify-between">
                <h3 className="text-xl font-semibold">Sequence Duplication</h3>

                <div className="flex mr-8 relative">
                  <button
                    className="text-onex-purple-80 mr-2"
                    onClick={() =>
                      DownloadImage(
                        plotRefSequenceDuplication,
                        'Quality_Control_Sequence_Duplication',
                      )
                    }
                  >
                    Download
                  </button>
                  <button onClick={() => setIsModalOpen(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                        stroke="#374548"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    open={isModalOpen}
                    title="Sequence Duplication"
                    description="Sequence Duplication"
                    onClose={() => setIsModalOpen(false)}
                  />
                  <div id="modal-root"></div>
                </div>
              </div>
              <div
                ref={plotRefSequenceDuplication}
                className="w-full text-center"
              >
                <Plot
                  data={sequenceDuplicationTraces}
                  layout={{
                    width: 1000,
                    height: 500,
                    showlegend: true,
                    xaxis: {
                      title: 'Sequence duplication level',
                    },
                    yaxis: {
                      title: '% of library',
                    },
                  }}
                  config={commonConfig}
                />
              </div>
            </>
          )}

        {/* "Number of detected genes" section */}
        {data?.detect_genes &&
          data.detect_genes.samples &&
          data.detect_genes.samples.length > 0 && (
            <>
              <div className="flex justify-between">
                <h3 className="text-xl font-semibold">
                  Number of detected genes
                </h3>
                <div className="flex mr-8 relative">
                  <button
                    className="text-onex-purple-80 mr-2"
                    onClick={() =>
                      DownloadImage(
                        plotRefDetectedGenes,
                        'Quality_Control_Detected_Genes',
                      )
                    }
                  >
                    Download
                  </button>
                  <button onClick={() => setIsModalOpen(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                        stroke="#374548"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    open={isModalOpen}
                    title="Number of detected genes"
                    description="Number of detected genes"
                    onClose={() => setIsModalOpen(false)}
                  />
                  <div id="modal-root"></div>
                </div>
              </div>
              <div ref={plotRefDetectedGenes} className="w-full text-center">
                <Plot
                  data={detectedGenesTraces}
                  layout={{
                    width: 1000,
                    height: 500,
                    showlegend: true,
                    xaxis: { title: 'Samples' },
                    yaxis: { title: 'Number of detected genes' },
                  }}
                  config={commonConfig}
                />
              </div>
            </>
          )}

        {/* "Expression before normalisation" section */}
        {data?.norm_bef &&
          data.norm_bef.samples &&
          data.norm_bef.samples.length > 0 && (
            <>
              <div className="flex justify-between">
                <h3 className="text-xl font-semibold">
                  Expression before normalisation
                </h3>
                <div className="flex mr-8 relative">
                  <button
                    className="text-onex-purple-80 mr-2"
                    onClick={() =>
                      DownloadImage(
                        plotRefNormAft,
                        'Quality_Control_Expression_Before_Normalization',
                      )
                    }
                  >
                    Download
                  </button>
                  <button onClick={() => setIsModalOpen(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                        stroke="#374548"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    open={isModalOpen}
                    title="Expression before normalisation"
                    description="Per Base Quality"
                    onClose={() => setIsModalOpen(false)}
                  />
                  <div id="modal-root"></div>
                </div>
              </div>
              <div ref={plotRefNormAft} className="w-full text-center">
                <Plot
                  data={normAftTraces}
                  layout={{
                    width: 1000,
                    height: 500,
                    showlegend: true,
                    xaxis: { title: '', rangeslider: { visible: false } },
                    yaxis: { title: 'Expression level (log2)' },
                  }}
                  config={commonConfig}
                />
              </div>
            </>
          )}

        {/* "Expression after normalisation" section */}
        {data?.norm_aft &&
          data.norm_aft.samples &&
          data.norm_aft.samples.length > 0 && (
            <>
              <div className="flex justify-between">
                <h3 className="text-xl font-semibold">
                  Expression after normalisation
                </h3>
                <div className="flex mr-8 relative">
                  <button
                    className="text-onex-purple-80 mr-2"
                    onClick={() =>
                      DownloadImage(
                        plotRefNormAft,
                        'Quality_Control_Expression_After_Normalization',
                      )
                    }
                  >
                    Download
                  </button>
                  <button onClick={() => setIsModalOpen(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z"
                        stroke="#374548"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    open={isModalOpen}
                    title="Number of detected genes per sample"
                    description="This bar plot shows the number of genes detected in each sample. The samples are grouped by the group label and the legend shows the corresponding colors."
                    onClose={() => setIsModalOpen(false)}
                  />
                  <div id="modal-root"></div>
                </div>
              </div>
              <div ref={plotRefNormAfter} className="w-full text-center">
                <Plot
                  data={normAftAfterTraces}
                  layout={{
                    width: 1000,
                    height: 500,
                    showlegend: true,
                    xaxis: { title: '', rangeslider: { visible: false } },
                    yaxis: { title: 'Expression level (log2)' },
                  }}
                  config={commonConfig}
                />
              </div>
            </>
          )}
      </>
    );
  };

  return (
    <div>
      <div className="flex items-start gap-6 mb-8">
        <div className="flex-1 flex flex-col items-center p-4">
          {/* "General Parameters" section */}
          {data &&
            (data.sequencing ||
              data.sequence_length ||
              typeof data.is_paired !== 'undefined') && (
              <div className="w-full mb-4">
                <h3 className="text-xl font-semibold mb-5">
                  General Parameters
                </h3>
                <h4 className="text-md mb-5">
                  Sequencing: {data.sequencing || 'Loading...'}
                </h4>
                <h4 className="text-md mb-5">
                  Sequence length: {data.sequence_length || 'Loading...'}
                </h4>
                <h4 className="text-md mb-5">
                  Paired-end experiment: {data.is_paired ? 'Yes' : 'No'}
                </h4>
              </div>
            )}

          {/* Render plots if available */}
          <div className="w-full">{QualityControlPlots()}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default QualityControl;
