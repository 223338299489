import React from 'react';

interface DeleteProjectProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  projectCount: number;
}

const DeleteProject: React.FC<DeleteProjectProps> = ({ isOpen, onClose, onDelete, projectCount }) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  if (!isOpen) return null;

  const message =
    projectCount === 1
      ? "Are you sure you want to remove this project?"
      : `Are you sure you want to remove these ${projectCount} projects?`;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-xl p-6 bg-white rounded-lg shadow-lg">
        <button
          className="absolute text-4xl text-gray-500 top-2 right-4 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>

        <div className="flex flex-col items-center">
          <i className="text-4xl text-orange-600 cursor-pointer fi fi-rr-trash" aria-label="Delete" />
          <p className="mt-2 mb-4 text-center text-gray-800">
            <span className="text-xl font-semibold">{message}</span>
            <br /> This action cannot be undone. This will permanently delete the data.
          </p>
        </div>

        <div className="flex justify-between mt-6 space-x-4">
          <button
            onClick={handleDelete}
            className="w-1/2 px-4 py-3 text-lg text-white bg-orange-600 rounded-lg hover:bg-orange-700"
          >
            Yes, Remove
          </button>
          <button
            onClick={onClose}
            className="w-1/2 px-4 py-3 text-lg text-black bg-white border border-gray-400 rounded-lg hover:bg-gray-200"
          >
            No, Keep
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProject;
