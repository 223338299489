import React from 'react';
import Plot from 'react-plotly.js';
import { IPcaData } from '../../../../interface/vnexInterfaces';
import { commonConfig } from '../../../../utils/plotConfig';
import { ScatterData } from 'plotly.js';

interface PcaPlotProps {
  pcaData: IPcaData;
}

const PcaPlot: React.FC<PcaPlotProps> = ({ pcaData }) => {
  // Initialize a map to store traces for each group
  const groupTraces = new Map<
    string,
    { x: number[]; y: number[]; text: string[]; marker: { color: string } }
  >();

  // Iterate over data points
  pcaData.PC1.forEach((pc1, index) => {
    const pc2 = pcaData.PC2[index];
    const group = pcaData.group[index];
    const color = pcaData.group_color[index];
    const sample = pcaData.sample[index];

    if (!groupTraces.has(group)) {
      groupTraces.set(group, { x: [], y: [], text: [], marker: { color } });
    }

    const trace = groupTraces.get(group)!; // non-null assertion
    trace.x.push(pc1);
    trace.y.push(pc2);
    trace.text.push(sample);
  });

  // Convert map to an array of scatter traces, casting each item as ScatterData
  const plotData: ScatterData[] = Array.from(groupTraces.entries()).map(
    ([group, trace]) =>
      ({
        x: trace.x,
        y: trace.y,
        type: 'scatter',
        mode: 'markers',
        name: group,
        text: trace.text,
        marker: { color: trace.marker.color, size: 10 },
      }) as ScatterData,
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot
        data={plotData}
        layout={{
          width: 1000,
          height: 700,
          autosize: true,
          title: '',
          xaxis: { title: 'PC1', scaleanchor: 'y', scaleratio: 1.9 },
          yaxis: { title: 'PC2' },
        }}
        config={commonConfig}
      />
    </div>
  );
};

export default PcaPlot;
