import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadForm from './UploadForm';
import FileUploadSection from './FileUploadSection';
import AdditionalInputs from './AdditionalInputs';
import Swal from 'sweetalert2';
import { getFromGeoApi, copyLocalFolder } from '../../services/projectApi';
import { ClipLoader } from 'react-spinners';
import {
  handleExperimentChange,
  handleGenomeChange,
  handleProjectNameChange,
  checkName,
  uploadFiles,
  handleDiscard,
  uploadUrls,
  uploadFtpFiles,
} from './uploadFunctions';
import { useNavigate } from 'react-router-dom';
import UploadProgress from './UploadProgress';
import { useProjectRefresh } from '../../contexts/ProjectRefreshContext';
import { useWebSocket } from '../../hooks/useWebSocket';
import { useEnvironment } from '../../utils/EnvContext';
import withAuthorization from '../../components/WithAuthorization';
import {
  SelectedFile,
  FtpDetails,
  SelectedURLfile,
  GeoCode,
} from '../../types/upload';
import { useUploadValidation } from '../../hooks/useUploadValidation';
import ResumeDownload from './ResumeDownload';
import { CheckStorageLimit } from '../../utils/upload';
import LoadingOverlay from '../../components/LoadingOverlay';

const Upload: React.FC = () => {
  const [experiment, setExperiment] = useState<string>('');
  const [genome, setGenome] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [geoCode, setGeoCode] = useState<GeoCode>({ geocode: '' });
  const [geoCodes, setGeoCodes] = useState<GeoCode[]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploadComplete, setFileUploadComplete] = useState<boolean>(false);
  const [combinedFileSizeMBs, setCombinedFileSizeMBs] = useState<number>(0);
  const [resumeFilesList, setResumeFilesList] = useState<string[]>([]);
  const [selectedFolderPath, setSelectedFolderPath] = useState<string>('');
  const [createButtonEnabled, setCreateButtonEnabled] = useState<boolean>(true);
  const [isProjectConfigurable, setIsProjectConfigurable] =
    useState<boolean>(false);
  const { environmentName } = useEnvironment();
  const {
    checkProjectAndStorageLimits,
    checkLocalLicense,
    isLocalLicenseValid,
    isProjectsLimitValid,
    isStorageLimitValid,
  } = useUploadValidation();

  const [showResumeButton, setShowResumeButton] = useState<boolean>(false);
  let resumeDownload = false;

  const [errors, setErrors] = useState<{
    projectName?: string;
    experiment?: string;
    genome?: string;
    selectedFilesOrURLs?: string;
  }>({});
  const [SelectedURLfile, setSelectedURLfile] = useState<SelectedURLfile[]>([]);
  const [ftpDetails, setFtpDetails] = useState<FtpDetails[]>([]); // New state for SFTP details
  const navigate = useNavigate();
  const [urlUploading, setUrlUploading] = useState<boolean>(false);

  const [ftpDetailsUploading, setFtpDetailsUploading] =
    useState<boolean>(false);
  const { data, isConnected } = useWebSocket(
    'ws://core:8000/ws/status',
    uploading || urlUploading || ftpDetails.length > 0,
  );
  const uploadPromises: any[] = [];

  let uploadFailedFiles: any[] | undefined;
  let uploadSuccessFiles: any[] | undefined;
  let fileExtensioError = false;
  let geocodeError = false;
  const [buttonText, setButtonText] = useState<string>('Create');
  const isLoading = uploading || urlUploading || buttonText === 'Uploading';

  const triggerRefresh = useProjectRefresh();
  const clearError = (field: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  useEffect(() => {
    if (environmentName && environmentName !== 'local') {
      checkProjectAndStorageLimits(selectedFiles, SelectedURLfile, ftpDetails);
    } else {
      checkLocalLicense();
    }
  }, [navigate, environmentName]);

  const HandleFormErrors = () => {
    const newErrors: {
      projectName?: string;
      experiment?: string;
      genome?: string;
      selectedFilesOrURLs?: string;
    } = {};
    if (!projectName) newErrors.projectName = 'Project name is required';
    if (!experiment) newErrors.experiment = 'Experiment is required';
    if (!genome) newErrors.genome = 'Genome is required';
    if (
      selectedFiles.length === 0 &&
      SelectedURLfile.length === 0 &&
      geoCodes.length === 0 &&
      ftpDetails.length === 0
    ) {
      newErrors.selectedFilesOrURLs =
        'Either rawdata file, GEO code or Link must be uploaded before proceeding';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (HandleFormErrors()) return; //verifies form has all the required data filled properly...
    setLoading(true);

    if (environmentName && environmentName !== 'local') {
      //checks storage limit if environment is not local
      const { isWithinLimit, exceededAmountGB } = await CheckStorageLimit(
        selectedFiles,
        SelectedURLfile,
        ftpDetails,
        environmentName,
      );

      if (!isWithinLimit) {
        Swal.fire({
          title: 'Storage Limit Exceeded',
          text: `You have exceeded your data usage by ${exceededAmountGB} GB. Please upgrade your plan to proceed.`,
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Upgrade Plan',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/account');
          }
        });
        setLoading(false);
        return; // Exit if storage limit is exceeded
      }
    }

    try {
      setButtonText('Uploading');
      setUploading(true);

      if (!isProjectConfigurable) {
        const isProjectCreated = await checkName(
          experiment,
          genome,
          projectName,
          setProjectName,
        );

        if (!isProjectCreated) {
          setLoading(false);
          setButtonText('Create');
          setUploading(false);
          return;
        }
      }
    } catch (error) {
      setButtonText('Create');
      setUploading(false);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the project creation process',
        width: '500px',
        padding: '1.5em',
      });
      setLoading(false);
      setButtonText('Create');
      setUploading(false);
    }

    try {
      uploadFailedFiles = [];
      uploadSuccessFiles = [];
      fileExtensioError = false;
      geocodeError = false;
      // Add file upload logic here without showing the popup if the file size is under 20 MB
      if (geoCodes.length > 0) {
        const codesList = geoCodes.map((code) => code.geocode);
        uploadPromises.push(
          getFromGeoApi(codesList, projectName, isProjectConfigurable)
            .then((response) => {
              if (response) {
                if (response.status !== 200 && response.status !== 202) {
                  geocodeError = true;
                  throw new Error(
                    response.data?.detail ||
                      `Failed to upload GEO data for code: ${geoCodes}`,
                  );
                } else {
                  console.log('response from geo api:', response);
                }
              }
            })
            .catch((error) => {
              geocodeError = true;
              uploadFailedFiles = error.response.data.errors.map(
                (err: any) => err.geo_code,
              );
              uploadSuccessFiles = geoCodes.filter(
                (code) => !uploadFailedFiles?.includes(code.geocode),
              );

              throw new Error(
                `${error.message}. Failed files: ${uploadFailedFiles}`,
              );
            }),
        );
      } else if (SelectedURLfile.length > 0) {
        uploadPromises.push(UploadSelectedURLDetails(SelectedURLfile));
      } else if (selectedFiles.length > 0) {
        const filesToUpload = selectedFiles.map(({ file }) => file);
        const fileUploadPromise = uploadFiles(
          projectName,
          filesToUpload,
          experiment,
          isProjectConfigurable,
          setUploading,
          setProgress,
        ).then((result) => {
          if (!result.success) {
            if (result.successFiles) {
              uploadSuccessFiles = result.successFiles;
            }
            if (result.failedFiles) {
              uploadFailedFiles = result.failedFiles;
            }
            if (
              result.message ===
              `cannot access local variable 'extension' where it is not associated with a value`
            ) {
              fileExtensioError = true;
            }
            throw new Error(
              `${result.message}. Failed files: ${result.failedFiles}`,
            );
          } else {
            if (result.successFiles) {
              uploadSuccessFiles = result.successFiles;
            }
          }
        });

        console.log('selectedFiles:', selectedFiles);

        uploadPromises.push(fileUploadPromise);
      } else if (ftpDetails.length > 0) {
        uploadPromises.push(UploadFtpDetails(ftpDetails));
      }

      HandlePromisesResponse();
    } catch (error) {
      setButtonText('Create');
      setUploading(false);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the files uploading process',
        width: '500px',
        padding: '1.5em',
      });
      setLoading(false);
      setButtonText('Create');
      setUploading(false);
    }
  };
  const HandlePromisesResponse = async () => {
    try {
      // Collect settled promise results
      const results = await Promise.allSettled(uploadPromises);

      // Identify failed uploads
      const failedUploads = results.filter(
        (result) => result.status === 'rejected',
      );

      const failedFilesHtml = generateFailedFilesHTML();

      // Scenario 1: Upload completely failed
      if (
        fileExtensioError ||
        (uploadSuccessFiles?.length === 0 &&
          failedUploads.length > 0 &&
          !resumeDownload)
      ) {
        isProjectConfigurable
          ? setButtonText('Go Configure')
          : setButtonText('Create');
        HandleFailedFilesForUIChanges();
        Swal.fire({
          icon: 'error',
          title: isProjectConfigurable
            ? '<span>Error</span>'
            : '<span>Project creation process failed.</span>',
          width: '550px',
          padding: '1em',
          position: 'center',
          html: fileExtensioError
            ? '<span>Files with the selected extension are not supported.</span>'
            : failedFilesHtml,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });
      }

      // Scenario 2: Partial upload success
      else if (
        (uploadFailedFiles && uploadFailedFiles.length > 0) ||
        resumeDownload
      ) {
        HandleFailedFilesForUIChanges();

        if (geoCodes.length > 0) {
          // since failed files scenario for geocodes is not supported
          setButtonText('Create');
          setIsProjectConfigurable(false);
        } else if (!resumeDownload) {
          setButtonText('Go Configure');
          setIsProjectConfigurable(true);
        } else {
          setButtonText('Uploading');
          setFtpDetailsUploading(true);
          setUploading(true);
          setIsProjectConfigurable(false);
        }

        Swal.fire({
          icon: geoCodes.length > 0 ? 'error' : 'info',
          title:
            geoCodes.length > 0
              ? '<span>Error</span>'
              : '<span>Project is created successfully!</span>',
          width: '550px',
          padding: '1em',
          position: 'center',
          html: failedFilesHtml,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });
      } else {
        // Scenario 3: Complete upload success
        setProgress(100);
        setButtonText('Go Configure');
        Swal.fire({
          title:
            '<span>Your project is created successfully, and files are uploaded!</span>',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            triggerRefresh();
            setSelectedURLfile([]);
            setSelectedFiles([]);
            setGeoCodes([]);
            setFtpDetails([]);
            setExperiment('');
            setGenome('');
            setFileUploadComplete(true);
            navigate(`/configure/${projectName}`);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error occurred during the files data uploading process',
        width: '500px',
        padding: '1.5em',
      });
    } finally {
      // Reset loading states
      setLoading(false);
      setUploading(false);
      setUrlUploading(false);
      setFtpDetailsUploading(false);
    }
  };

  const HandleFailedFilesForUIChanges = () => {
    if (geoCodes.length > 0) {
      console.log('geoCodes:', geoCodes);

      const newGeoCodes = geoCodes.map((code) => {
        const codeExists = uploadFailedFiles?.some(
          (failedCode) => failedCode === code.geocode,
        );
        return { ...code, isUploadFailed: codeExists };
      });

      console.log('newGeoCodes:', newGeoCodes);
      setGeoCodes(newGeoCodes);
      setFileUploadComplete(true);
    } else if (SelectedURLfile.length > 0) {
      const newSelectedFiles = SelectedURLfile.map((file) => {
        const fileExists = uploadFailedFiles?.some((failedFile) => {
          if (typeof failedFile === 'object' && failedFile !== null) {
            return Object.keys(failedFile).includes(file.filename);
          }
          return file;
        });

        return { ...file, isUploadFailed: fileExists };
      });
      setSelectedURLfile(newSelectedFiles);
      // setButtonText('Go Configure');
      setFileUploadComplete(true);
    } else if (selectedFiles.length > 0) {
      const newSelectedFiles = selectedFiles.map((file) => {
        const fileExists = uploadFailedFiles?.some((failedFile) => {
          if (typeof failedFile === 'object' && failedFile !== null) {
            return Object.keys(failedFile).includes(file.file.name);
          }
          return file;
        });

        return { ...file, isUploadFailed: fileExists };
      });

      setSelectedFiles(newSelectedFiles);

      // setButtonText('Go Configure');
      setFileUploadComplete(true);
    } else if (ftpDetails.length > 0) {
      console.log('ftpDetails:', ftpDetails);
      console.log('uploadFailedFiles:', uploadFailedFiles);

      const newFtpDetails = ftpDetails.map((file) => {
        const fileExists = uploadFailedFiles?.some((failedFile) => {
          if (typeof failedFile === 'object' && failedFile !== null) {
            return Object.keys(failedFile).includes(file.file_name);
          }
          return false;
        });

        return { ...file, isUploadFailed: fileExists };
      });

      const resumeDownloadFiles = ftpDetails
        .map((file) => {
          const isUploadFailed = uploadFailedFiles?.some((failedFile) => {
            if (typeof failedFile === 'object' && failedFile !== null) {
              return Object.keys(failedFile).includes(file.file_name);
            }
            return false;
          });

          if (isUploadFailed) {
            return `${projectName}_${file.hostname}_${file.file_name}`;
          }
          return null;
        })
        .filter((file): file is string => file !== null);

      setFtpDetails(newFtpDetails);
      console.log('resumeable files list is :', resumeDownloadFiles);
      setResumeFilesList(
        resumeDownloadFiles.length > 0 ? resumeDownloadFiles : [],
      );
      setFileUploadComplete(true);
    }
  };

  // Generate HTML for failed files
  const generateFailedFilesHTML = () => {
    if (!uploadFailedFiles || uploadFailedFiles.length === 0) return '';

    const failedFilesList = uploadFailedFiles
      .map((file) => {
        if (typeof file === 'string') {
          return `<li>${file}</li>`;
        }
        if (typeof file === 'object' && file !== null) {
          return Object.entries(file)
            .map(
              ([key, value]) => `<li><strong>${key}</strong> (${value})</li>`,
            )
            .join('');
        }
        return '';
      })
      .join('');

    return `<div style="text-align: left;">
      The following files failed to upload:
      <br><br>
      <div>
        <ul style="text-align: left;list-style-type: disc;">
          ${failedFilesList}
        </ul>
      </div>
      <br
    </div>`;
  };

  const UploadFtpDetails = async (updatedFtpDetails: FtpDetails[]) => {
    const combinedFtpFileSizeMB = updatedFtpDetails.reduce(
      (acc, ftp) => acc + ftp.file_size,
      0,
    );
    setCombinedFileSizeMBs(combinedFtpFileSizeMB);
    setFtpDetailsUploading(true);
    setLoading(true);
    return await uploadFtpFiles(
      updatedFtpDetails,
      projectName,
      combinedFtpFileSizeMB,
      experiment,
      isProjectConfigurable,
    ).then((result) => {
      if (!result.success) {
        if (
          result.message ==
          'Some files could not be downloaded, project data has been deleted.'
        ) {
          setShowResumeButton(true);
          resumeDownload = true;
          if (result.failedFiles) {
            uploadFailedFiles = result.failedFiles;
          }

          throw new Error(
            `${result.message}. Failed files: ${result.failedFiles}`,
          );
        } else if (result.message == 'Upload process completed.') {
          if (result.failedFiles) {
            uploadFailedFiles = result.failedFiles;
            throw new Error(
              `${result.message}. Failed files: ${result.failedFiles}`,
            );
          }
        }
      } else {
        setFtpDetailsUploading(false);
      }
    });
  };

  const UploadSelectedURLDetails = async (
    updatedSelectedURLfile: SelectedURLfile[],
  ) => {
    const combinedURLFileSizeMB = updatedSelectedURLfile.reduce(
      (acc, ftp) => acc + ftp.filesize,
      0,
    );

    setCombinedFileSizeMBs(combinedURLFileSizeMB);
    setUrlUploading(true);

    if (selectedFolderPath && environmentName === 'local') {
      //if the environment is local and the selected Folder Path is not empty
      return await copyLocalFolder(
        selectedFolderPath,
        projectName,
        experiment,
        isProjectConfigurable,
      )
        .then((response) => {
          console.log('response from folder copy api: ', response);

          if (response.failed_files?.length > 0) {
            uploadFailedFiles = response.failed_files;
            uploadSuccessFiles = response.success_files;

            const allFiles = response.success_files.concat(
              response.failed_files,
            );

            throw new Error(
              `${response.message}. Failed files: ${response.failed_files}. All files: ${allFiles}`,
            );
          }
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          setUrlUploading(false);
        });
    } else {
      return await uploadUrls(projectName, experiment, updatedSelectedURLfile)
        .then((result) => {
          if (!result.success) {
            if ((result.failedFiles?.length ?? 0) > 0) {
              if (result.failedFiles) {
                uploadFailedFiles = result.allFiles
                  ?.filter((file) =>
                    result.failedFiles?.some(
                      (failedFile) => failedFile.url === file.url,
                    ),
                  )
                  .map((file) => file.file);
              }

              throw new Error(
                `${result.message}. Failed files: ${result.failedFiles}. All files: ${result.allFiles}`,
              );
            }
          }
        })
        .catch((error) => {
          console.error('Error uploading URL:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error occurred during the URL upload process',
            width: '500px',
            padding: '1.5em',
          });
        })
        .finally(() => {
          setUrlUploading(false);
        });
    }
  };

  const RetryUpload = (event: React.FormEvent) => {
    let showRetryConfirmationPrompt = false;

    if (geoCodes.length > 0) {
      geoCodes.forEach((code) => {
        if (code.isUploadFailed) {
          showRetryConfirmationPrompt = true;
        }
      });
    } else if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        if (file.isUploadFailed) {
          showRetryConfirmationPrompt = true;
        }
      });
    } else if (SelectedURLfile.length > 0) {
      SelectedURLfile.forEach((file) => {
        if (file.isUploadFailed) {
          showRetryConfirmationPrompt = true;
        }
      });
    } else if (ftpDetails.length > 0) {
      ftpDetails.forEach((file) => {
        if (file.isUploadFailed) {
          showRetryConfirmationPrompt = true;
        }
      });
    }

    if (showRetryConfirmationPrompt) {
      Swal.fire({
        title: 'Retry Upload',
        text: 'Some files failed to upload in the previous attempt. Do you still want to retry uploading these files?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Retry',
        cancelButtonText: 'No, Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit(event);
        }
      });
    } else {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    if (uploading || urlUploading || ftpDetails.length > 0) {
      if (
        data?.project === projectName &&
        (SelectedURLfile.length > 0 || ftpDetails.length > 0)
      ) {
        const totalBytes = combinedFileSizeMBs * 1024 * 1024;
        console.log('Total file size in Bytes:', totalBytes); //total filesize in bytes
        console.log('Downloaded Bytes:', data.bytes_downloaded); // Bytes downloaded
        const percernt_ = (data.bytes_downloaded / totalBytes) * 100;
        const progress_percent =
          percernt_ < 1 ? percernt_.toFixed(2) : percernt_.toFixed(0);
        setProgress(Number(progress_percent));
      }
    }
  }, [data]);
  useEffect(() => {
    progress === 100 && setButtonText('Processing');
  }, [progress]);
  useEffect(() => {
    if (experiment === 'BRBseq') {
      setSelectedURLfile([]);
      setFtpDetails([]);
    }
  }, [experiment]);
  useEffect(() => {
    if (!isProjectsLimitValid) {
      Swal.fire({
        title: 'Project Limit Reached',
        text: 'You have reached your monthly project limit. Would you like to upgrade your plan?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Upgrade',
        cancelButtonText: 'No, Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
    }
    if (!isStorageLimitValid.isWithinLimit) {
      Swal.fire({
        title: 'Storage Limit Exceeded',
        text: `You have exceeded your data usage by ${isStorageLimitValid.exceededAmount} GB. Please upgrade your plan to proceed.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Upgrade Plan',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/account');
        }
      });
    }
  }, [isProjectsLimitValid, isStorageLimitValid]);

  useEffect(() => {
    if (
      uploading ||
      urlUploading ||
      buttonText === 'Uploading' ||
      (!isProjectConfigurable && buttonText === 'Go Configure')
    ) {
      setCreateButtonEnabled(false);
    } else {
      setCreateButtonEnabled(true);
    }
  }, [uploading, urlUploading, buttonText, isProjectConfigurable]);

  return (
    <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md max-w-7xl">
      {loading && <LoadingOverlay />}

      <div className="flex items-center justify-between mb-6">
        <button
          className="flex items-center text-lg font-bold text-gray-700"
          onClick={() => {
            navigate('/');
          }}
        >
          <ArrowBackIcon className="mt-0.5 mr-2" /> Create Project
        </button>
        <button
          className="px-4 py-2 text-sm font-semibold text-blue-700 transition-all bg-white border border-blue-500 rounded-lg hover:bg-blue-50"
          onClick={() => {
            handleDiscard(
              setExperiment,
              setGenome,
              setProjectName,
              (files) =>
                setSelectedFiles(
                  (files as any).map((file: any) => ({
                    ...file,
                    isUploadFailed: false,
                  })),
                ),
              setProgress,
              setGeoCodes,
              setFileUrl,
            );
            setIsProjectConfigurable(false);
            setButtonText('Create');
          }}
        >
          Reset
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <UploadForm
          projectName={projectName}
          experiment={experiment}
          genome={genome}
          handleProjectNameChange={(event) =>
            handleProjectNameChange(event, setProjectName)
          }
          handleExperimentChange={(event) =>
            handleExperimentChange(event, setExperiment)
          }
          handleGenomeChange={(event) => handleGenomeChange(event, setGenome)}
          clearError={clearError}
          errors={errors}
        />

        <p className="mb-6 text-gray-500">
          Other parameters (such as the data being single or paired-end or if
          the sequencing was stranded) will be automatically detected.
        </p>

        <h6 className="mb-2 text-xl font-semibold">Upload Data</h6>

        <div id="drop-zone">
          {/* {loading && (
            <div className="flex items-center justify-center">
              <ClipLoader size={50} color={'#123abc'} loading={loading} />
            </div>
          )} */}

          {!loading && (
            <FileUploadSection
              uploading={uploading}
              setLoading={setLoading}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              SelectedURLfile={SelectedURLfile}
              geoCodes={geoCodes}
              ftpDetails={ftpDetails}
              clearError={clearError}
              setErrors={setErrors}
              isReport={false}
            />
          )}
        </div>

        <AdditionalInputs
          projectName={projectName}
          experimentType={experiment}
          uploading={uploading}
          fileUploadComplete={fileUploadComplete}
          geoCode={geoCode}
          setGeoCode={setGeoCode}
          geoCodes={geoCodes}
          setGeoCodes={setGeoCodes}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          selectedFiles={selectedFiles}
          setSelectedFolderPath={setSelectedFolderPath}
          setSelectedFiles={setSelectedFiles}
          SelectedURLfile={SelectedURLfile}
          setSelectedURLfile={setSelectedURLfile}
          ftpDetails={ftpDetails} // Pass ftpDetails
          setFtpDetails={setFtpDetails} // Pass setFtpDetails
          clearError={clearError}
          setErrors={setErrors}
        />
        {errors.selectedFilesOrURLs && (
          <p className="text-red-600">{errors.selectedFilesOrURLs}</p>
        )}
        {showResumeButton && (
          <ResumeDownload
            projectName={projectName}
            ftpDetails={ftpDetails}
            resumeFilesList={resumeFilesList}
            setShowResumeButton={setShowResumeButton}
            UploadFtpDetails={UploadFtpDetails}
            ResumeUploadPromise={(updatedFtpDetails: FtpDetails[]) => {
              uploadPromises.push(UploadFtpDetails(updatedFtpDetails));
              HandlePromisesResponse();
            }}
          />
        )}
        <UploadProgress
          uploading={uploading || urlUploading || ftpDetailsUploading} // Remove geoCodes.length > 0 if you don't want the progress bar for geo code submission
          progress={progress}
          fileType={
            urlUploading ? 'url' : ftpDetails.length > 0 ? 'ftp' : 'file'
          } // Remove 'geocode' as fileType if progress tracking is not needed for geo codes
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex justify-end w-full gap-5">
            {((uploadFailedFiles && uploadFailedFiles.length > 0) ||
              (isProjectConfigurable && !resumeDownload)) && (
              <button
                type="button"
                className="px-4 py-2 ml-4 text-white bg-orange-500 rounded hover:bg-orange-500 cursor-pointer"
                onClick={(e) => {
                  RetryUpload(e);
                }}
              >
                Retry Upload
              </button>
            )}
            <button
              type="submit"
              className={`px-4 py-2 text-white rounded ${!createButtonEnabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-teal-700 hover:bg-teal-800'}`}
              disabled={!createButtonEnabled}
              onClick={() => {
                if (buttonText === 'Go Configure') {
                  navigate(`/configure/${projectName}`);
                }
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withAuthorization(Upload);
